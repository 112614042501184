#recipe-post-main {
	max-width:		@maxWidth;
	margin:			0 auto;
	padding-bottom:	(@recipePostGutter * 2);

	> div, > aside {
		width:		@recipePostColWidth;
		float:		right;
		display:	inline-block;
	}

	> aside {
		margin-right: @recipePostGutter;

		.img {
			display:		block;
			overflow:		hidden;
			margin-bottom:	@recipePostGutter;
			max-width:		@recipePostColWidth;
			max-height:		@recipePostColWidth;

			img {
				display: block;
			}
		}

		> div {
			background-color: @tanLt;
			padding: @recipePostGutter;
			#font > #futura > .medium(14px,28px);
			.kerning(15);
			color: @grey3F;

			> h3 {
				#font > #futura > .heavy(12px,normal);
				text-transform: uppercase;
				.kerning(100);
				color: @green;
				margin-bottom: (@recipePostGutter / 2) !important;
			}

			li {
				list-style-type: none;
				margin: 0;
			}
			> *:last-child {
				margin-bottom: 0;
			}
		}
	}

	heading {
		display:		block;
		margin-bottom:	@recipePostGutter;
		margin-top:		20px;

		h1 {
			#font > #playfair > .regular(30px,34px);
			padding-right: @recipePostGutter;
			margin-bottom: 20px;
			color: @grey3F;
		}
	}

	@media screen and (min-width: @phoneWidthP) and (max-width: @tabletWidthP)  {
		> div, > aside {
			width:			100%;
			padding:		0 2%;
			margin-right:	0;

			#recipe-post-product img {
				width: 45%;
			}

			.ui-share-load {
				width:		153px;
				height:		auto;
				line-height:42px;
			}
		}
	}

	@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthP) and (orientation: portrait) {
		> div, > aside {
			width:			96%;
			padding:		0 2%;
			margin-right:	0;
		}
	}

	@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthP)  {
		> div, > aside {
			width:			96%;
			padding:		0 2%;
			margin-right:	0;
		}
	}

	@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: landscape) {
		> div, > aside {
			width:		46%;
			float:		right;
			display:	inline-block;
		}

		> div {
			margin: 0 3%;
		}

		> aside {
			margin-right: 5px;
		}
	}

	@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: portrait) {
		> div, > aside {
			width:		46%;
			float:		right;
			display:	inline-block;
		}

		> div {
			margin: 0 15px 0 35px;
		}

		> aside {
			margin-right: 0;
		}
	}
}

#recipe-post-content {
	color:			@grey3F;
	margin-bottom:	@recipePostGutter;

	#font > #futura > .book(16px,21px);
	.kerning(15);

	> *:last-child {
		margin-bottom: 0;
	}

	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		& {
			#font > #futura > .book(13px,21px);
		}
	}
}

#recipe-post-product {
	padding-top:	@recipePostGutter;
	margin-bottom:	@recipePostGutter;
	border-top:		solid 1px @greyCC;
	border-bottom:	solid 1px @greyCC;

	h3 {
		#font > #playfair > .regular(24px,25px);
		.kerning(25);
		margin-bottom: 20px;
		small {
			display: block;
			#font > #futura > .heavy(12px,normal);
			text-transform: uppercase;
			.kerning(100);
			color: @green;
			margin-bottom: (@recipePostGutter / 4);
		}
		.h3_a{
			#font > #playfair > .regular(24px,25px);
			color: #282828;
			text-decoration: none;
			text-transform: none;
		}
	}
	img {
		float: right;
	}
	a {
		#font > #futura > .book(14px,14px);
		text-transform: uppercase;
		color: @greenLt;
		text-decoration: underline;
		.transition('color 0.25s ease');
		&:hover {
			color: @green;
		}
	}
}
#recipe-post-prep {
	#font > #futura > .book(16px,21px);
	.kerning(15);
	color: @grey3F;
	margin-bottom: @recipePostGutter;
	> h2 {
		#font > #futura > .heavy(12px,normal);
		text-transform: uppercase;
		.kerning(100);
		color: @green;
		margin-bottom: (@recipePostGutter / 2) !important;
	}
	> *:last-child {
		margin-bottom: 0;
	}
}