@media screen and (max-width: @secondTabletWidth) {

    .laboratory-page {

        #cms-page  h2 {
            font-size: 	30px;
        }

        #cms-page .laboratory-list .second h4,
        #cms-page .laboratory-list h4,
        .laboratory-list > ul > li.second *,
        .laboratory-list > ul > li.aside-li p {
            font-size: 20px;
            line-height: normal;
        }

        .laboratory-list > ul > li.aside-li {
           margin: 0;
        }

        #cms-page .laboratory-list h4,
        #cms-page .laboratory-list .second h4 {
            margin: 5px 0;
        }

    }

}

@media screen and (max-width: @tabletWidthP) {

    .laboratory-page {

        #cms-page {

            h2 {
                font-size: 	21px;
            }

            .our-experts-block .text-block p {
                font-size: 		14px;
                line-height: 	normal;
                padding:        0;
            }

        }

        #cms-page .laboratory-list .second h4,
        .laboratory-list > ul > li.second *,
        #cms-page .laboratory-list h4,
        .laboratory-list > ul > li.aside-li p {
            font-size: 17px;
        }

    }

}

@media screen and (max-width: @mobileWidth) {

    .laboratory-page {

        #cms-page h2 {
            font-size: 		22px;
            margin-bottom: 	10px;
        }

        #cms-page .laboratory-list .second h4,
        .laboratory-list > ul > li.second *,
        #cms-page .laboratory-list h4,
        .laboratory-list > ul > li.aside-li p {
            font-size: 15px;
        }

    }

}

@media screen and (max-width: @secondMobileWidth) {

    .laboratory-page {

        #cms-page  .our-experts-block .text-block p {
            font-size: 		12px;
        }

        #cms-page .laboratory-list .second h4,
        .laboratory-list > ul > li.second *,
        #cms-page .laboratory-list h4,
        .laboratory-list > ul > li.aside-li p {
            font-size: 13px;
        }

    }

}