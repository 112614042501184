.mixedbag-error {
  padding:	10px;
  background:	#9c5b64;
  color:		#fff;
  margin-bottom: 20px;
  border:1px solid #bd6069;
}
#product-options-wrapper{
  #font > #futura > .book(15px, 18px);

  dl p { #font > #futura > .book(15px, 18px);}
  p { #font > #futura > .book(15px, 18px);}
  div {
    #font > #futura > .book(15px, 18px);
    .text_left{float:left;}
    .case-quantity{width:120px; float:left; margin-left:10px; margin-bottom:10px;}
  }

}
