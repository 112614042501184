#ahmad-404 {
	max-width:		1024px;
	margin:			0 auto;
	padding-top:	100px;
	text-align:		center;
	color:			@grey3F;

	#font > #futura > .book(16px,normal);

	a {
		color: @greenLt;
		.transition('color 0.25s ease');

		&:hover {
			color: @green;
		}
	}
	h1 {
		#font > #trajan > .regular(30px,normal);
		margin-bottom: @gutter;
	}

	#page-not_found-categories {
		h3 {
			color:		#3f4442;
			text-align:	center;

			#font#playfair.regular(13px);
		}

		.categories {
			margin:20px 20px 40px;

			div {
				height:			200px;
				width:			19%;
				float:			left;
				border-right:	1px solid #e9e9e9;
				margin-top:		40px;

				h4 {
					margin:	0 20px;
					height:	48px;
					#font#playfair.regular(14px);

					a {
						color: #282828;
					}
				}

				img {
					width:	auto;
					height:	100%;
				}
			}
		}

		@media screen and(min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
			.categories {
				div {
					width:			100%;
					border-right:	none;
				}
			}
		}
	}
}