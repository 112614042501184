.checkout-cart-index {
	background-color: @tanLt;

	#shopping-cart {
		padding-top: 50px;
	}

	#shopping-cart-summary {
		max-width:	@maxWidth;
		margin:		0 auto;
		background:	#fff;
		color:		@grey3F;

		h1 {
			#font > #playfair > .regular(16px,16px);
			padding: 40px @gutter 45px @gutter;
		}
		.bottom-messages-container {
		  background:	@grey89;
		  text-align:	center;
		  color:		#fff;
		  .holder {
			display: inline-block;
			overflow: hidden;
			text-align: left;
			padding:0 10px;
		  }
		}
		.free-shipping,
		.product-promotion {
			min-height:		@btnHeight;
			margin:		0;
			#font > #futura > .book(14px,@btnHeight);
			.kerning(100);
		}

		.row {
			background:	@tanLt;
			padding:	@gutter 0;
			position:	relative;

			> div {
				display:	inline-block;
				float:		right;
				background:	#fff;

				> * {
					position: relative;
					z-index: 1;
				}
			}
		}

		#discount-coupon-form {
			.clearfix;
			clear:both;
		}
	}

	#shopping-cart-items {
		width:	100%;
		padding:0 30px;

		.box-sizing(border-box);

		.cart-item, .cart-title {
			display:		table;
			vertical-align:	middle;
			border-top:		solid 1px @grey3F;
			padding:		@gutter 0;

			div {
				width:			25%;
				vertical-align:	middle;
				display:		table-cell;


				&.item-image {
					width:	15%;
					float:	left;

					a {
						padding: 0 11px 0 0;
					}
				}

				&.item-name{
					width: 35%;
				}

				&.item-price {
					width: 16%;
				}

				&.item-qty {
					width: 16%;
				}

				&.item-total {
					width:6%;
				}

				&.item-action {
					width: 1%;
				}
			}

			#font > #futura > .book(14px,24px);
			.kerning(25);

			a {
				color:			@grey3F;
				display:		block;
				text-decoration:none;

				.square(100%);
				#font > #futura > .heavy(15px,20px);
				.kerning(30);

				small {
					#font > #futura > .book(14px,24px);
					.kerning(25);
				}
			}

			img {
				vertical-align: middle;
			}
		}

		.cart-title {
			border-top:	0;
			padding:	10px 0;

			div {
				#font > #futura > .heavy(14px,14px);
				.kerning(25);

				text-transform:	uppercase;
				padding-bottom:	10px;
				vertical-align:	top;
				text-align:		left;
				width:			16%;
			}
		}

		.ssg-delete {
			font-size:		24px;
			margin-bottom:	-8px;
			color:			@greyD7;

			.transition('color 0.25s ease');

			&:hover {
				color: @grey3F;
			}
		}
	}

	#shopping-cart-action {
		width:		@maxWidth - @halfWidth - (@gutter * 3);
		padding:	@gutter;

		.box-sizing(border-box);

		&:after {
			right: 0;
			width: @maxWidth - @halfWidth - (@gutter * 3);
		}

		> a.ui-btn {
			padding: 0 24px;
			float: right;
		}

		> .ui-btn {
			&[name="update_cart_action"] {
				float: left;
				padding: 0 24px;
			}

			&.orange {
				float: right;
			}
		}

		.inline-form {
			margin: @gutter 0 (@gutter * 2) 0;

			&.has-code {
				.ssg-delete {
					display: block;

					input {
						padding-right: 33px;
					}
				}
			}

			input {
				width: 235px;
				border: solid 1px @grey63;
				border-right: none;
			}

			.ssg-delete {
				left: 203px;
				display: none;
			}
		}
	}

	#shopping-cart-crosssell {
		width:			@halfWidth;
		padding:		@gutter @gutter;
		margin-right:	@gutter;

		&:after {
			left:	0;
			width:	@halfWidth + (@gutter * 2);
		}

		.product-list > li {
			margin-bottom: 0;
		}

		h2 {
			#font > #futura > .heavy(12px,normal);
			.kerning(100);

			color:			@green;
			text-transform:	uppercase;
			margin-bottom:	50px;
		}
	}

	#cart-order-totals {
		border-top: solid 1px @greyC1;
		padding: (@gutter * 2) 0 @gutter 0;

		li {
			color:			@grey3F;
			text-transform:	uppercase;
			margin-bottom:	15px;

			#font > #futura > .heavy(14px,18px);
			.kerning(30);

			&.grand-total {
				padding-top:	45px;
				margin-bottom:	35px;

				.price {
					#font > #futura > .heavy(28px,18px);
					.kerning(35);

					position:	relative;
					top:		-5px;
				}
			}


			.price {
				float: right;
				#font > #futura > .book(14px,18px);
			}
		}
	}

	#shopping-cart-footer {
		max-width:	@maxWidth;
		margin:		0 auto @gutter auto;
		background:	#fff;
		color:		@grey3F;
		padding:	@gutter;

		#font > #futura > .medium(14px,22px);
		.kerning(50);
		.box-sizing(border-box);

		> strong, > p, > aside {
			display: inline-block;
			float: left;
		}

		> strong {
			.kerning(100);
			#font > #futura > .heavy(12px,normal);

			text-transform:	uppercase;
			color:			@green;
			width:			100px;
			margin-right:	(@gutter * 2)
		}

		> p {
			width:			@halfWidth - 100 - (@gutter * 2);
			margin-right:	(@gutter * 2);
			margin-bottom:	0;
		}

		> aside {
			width: @maxWidth - @halfWidth - (@gutter * 4);
			padding-left: @gutter;
			.box-sizing(border-box);

			ul li {
				padding-top: (@gutter / 2);
			}

			a {
				text-decoration: none;
				color: @greenLt;

				.transition('color 0.25s ease');

				&:hover {
					color: @green;
				}

				&:before {
					font-size:		19px;
					position:		relative;
					top:			3px;
					margin-right:	5px;
				}
			}
		}

		em {
			font-style: normal;
		}
	}

	#shopping-cart-empty {
		background:	#fff;
		color:		@grey3F;
		text-align:	center;

		section {
			max-width:	@maxWidth;
			margin:		0 auto;
			background:	#fff;
			padding:	40px 0;
		}

		h1 {
			#font > #playfair > .regular(25px,25px);
			.kerning(10);

			margin-bottom: 15px;
		}

		p {
			#font > #futura > .book(16px,24px);
			.kerning(25);
		}

		a {
			color:			@greenLt;
			text-decoration:none;

			#font > #futura > .heavy(16px,24px);
			.transition('color 0.25s ease');

			&:hover {
				color: @green;
			}
		}
	}

	.firefox {
		#shopping-cart-action {
			> a.ui-btn {
				padding: 0 23px;
			}

			> .ui-btn {
				&[name="update_cart_action"] {
					padding: 0 23px;
					margin-right: 10px;
				}
			}

			.inline-form input {
				width: 229px;
			}
		}
	}
}


@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthP) {
	.checkout-cart-index {
		#shopping-cart-footer {
			> strong {
				width: 344px;
			}

			> p {
				width:	344px;
				margin:	16px 0 0;
			}

			> aside {
				width:		310px;
				float:		right;
				margin-top:	-20px;
			}
		}
	}
}

@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
	.checkout-cart-index {
		#shopping-cart-summary {
			width: 100%;

			h1  {
				padding:	40px 30px;
				text-align:	center;
			}

			h2 {
				padding-bottom: 38px;
			}
		}

		#shopping-cart-footer {
			width: 100%;

			> strong {
				width:		100%;
				text-align:	center;
			}

			> p {
				width:	100%;
				margin:	0;
			}

			> aside {
				width:			100%;
				padding-left:	0;
			}
		}

		#cart-order-totals {
			padding: 0;
		}

		#shopping-cart-action {
			width: 100%;
			float: none;

			.inline-form  {
				margin-bottom: 18px;

				input {
					width: 163px;
				}
			}

			> a.ui-btn, .ui-btn[name="update_cart_action"] {
				padding:		0 15px;
				line-height:	32px;
			}
		}

		#shopping-cart-items {
			padding: 0;

			.cart-title {
				display: none;
			}

			.cart-item {
				display: block;

				div {
					display:block;
					float:	left;

					&.item-image {
						width: 35%;
					}

					&.item-name{
						width: 55%;
					}

					&.item-price {
						width:	 16%;
						padding: 20px 33px 0 58px;
					}

					&.item-qty {
						width:			16%;
						padding:		14px 10px 0 20px;
						margin-left:	-113px;
					}

					&.item-total {
						width:			6%;
						padding:		20px;
						margin-left:	4px;
					}

					&.item-action {
						width:		8%;
						float:		right;
						margin-top:	-38px;
					}
				}
			}
		}
	}
}