.cms-select-country {
	text-align:			center;
	margin:				0;
	padding:			0;
	background-color:	#dee6da;
	font-family:		"Times New Roman", serif;
	color:				#0b3e2d;
	letter-spacing:		1px;

	.background-img('select-country-bg.jpg',no-repeat,center,top);

	.container {
		max-width: 1024px;
		margin:		100px auto;
	}

	.wrapper {
		overflow:	hidden;
	}

	a {
		text-decoration: none;
	}

	h1 a {
		font-family:		"Times New Roman", serif;
		font-weight:	normal;
		font-size:		24px;
		margin:			20px auto 30px auto;
		padding:		13px 0;
		border-top:		1px solid #0b3e2d;
		border-bottom:	1px solid #0b3e2d;
		display:		inline-block;
		letter-spacing:	5px;
		color:			#0b3e2d;

		span {
			font-family:"Times New Roman", serif;
			font-size:	34px;
			color:		#0b3e2d;
		}
	}

	p, p a {
		font-family:"Times New Roman", serif;
		font-size:	19px;
		margin:		0;
		color:		#0b3e2d;
	}

	.col-3 {
		width:	33.3%;
		float:	left;

		ul {
			list-style:	none;
			margin:		0;
			padding:	0;

			li {
				margin: 12px 0;

				a {
					font-family:	"Times New Roman", serif;
					display:		block;
					padding:		4px;
					color:			#0b3e2d;
				}
			}
		}
	}

	.clearfix {
		.clearfix;

		margin-bottom: 40px;
	}

	footer {
		width:		100%;
		position:	fixed;
		bottom:		0;
		background:	#dfe7da;

		p {
			padding:		20px 0;
			text-align:		center;
			text-transform:	uppercase;
			font-size:		12px;
		}
	}

	@media only screen and(min-width: 320px) and(max-width: 760px) {
		h1 a {
			font-size: 18px;

			span {
				font-size: 22px;
			}
		}

		.col-3 {
			float: none;
			width: 100%;

		}
	}
}