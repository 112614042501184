#category {
	.space {
		padding-top: 45px;
	}
}

#categoryHero {
	position: relative;

	> img {
		display:	block;
		position:	relative;
		z-index:	0;
		width:		100% !important;
		height:		auto !important;
	}

	.cattitle {
		margin:			 0 auto;
		color:			 #faf3e1;
		width:			 100%;
		height:		 	 415px;
		background-position: center 20%;
		background-repeat:	no-repeat;
		background-size: 	cover;
		text-align:		 	center;

		.content {
			width:		300px;
			margin:		0 auto 0;
			padding:	50px 0 0 102px;
		}

		h2 {
			#font > #trajan > .regular(48px,58px);
			position:		relative;
			font-weight:	lighter;
			text-shadow:	2px 1px 2px #8d8487;
			text-align:		center;
			padding:		0 0 20px;

			&.green {
				color: #093e2c;
			}

			&.small-text {
				#font > #trajan > .regular(35px,24px);
			}
		}

		p {
			position:		relative;
			font-family:	futura-pt;
			color:			#fff;
			font-size:		17px;
			text-align:		center;
			font-weight:	normal;
			text-shadow:	2px 1px 2px #8d8487;

			&.green {
				color: #093e2c;
			}
		}
	}

	@media screen and (max-width: 1800px) {
		.cattitle {
			.content {
				padding: 50px 0 0 20px;
			}
		}
	}
}

#categoryProducts {
	.categoryEmpty {
		max-width:		@maxWidth;
		margin:		50px auto;
		text-align:	center;
		color:		@green;

		#font > #trajan > .regular(25px,24px);
		.kerning(-25);
	}

	.inline-nav {
		ul.unstyled {
			li {   margin: 0 4px; }
		}

		ul.teasbar-gifts.unstyled {
			li {
				margin: 15px 8px 0;

				&:first-child {
					margin-left: 14px;
				}

				a{
					&:before{
						font-weight:bold;
					}
					&:after{
						text-decoration: underline;
					}
				}
			}
		}

		.productFilterNav {
			margin-top: 0;
		}

		// add margin for gifts section
		.teasbar-gifts+.productFilterNav {
			margin-top: 30px;
		}
	}

	@media screen and (min-device-width: @tabletWidthP) and (max-device-width: @tabletWidthL)
	and (orientation: portrait) {
		.inline-nav {
			ul.unstyled {
				li {
					margin: 0 7px;
				}
			}
		}
	}
}

#categoryList {
	max-width:	@maxWidth;
	margin:		80px auto 0;
	position:	relative;

	.heading {
		text-align: left;
		color:		@grey28;

		#font > #futura > .book(18px,24px);
		.box-sizing(border-box);

		h1 {
			#font > #trajan > .regular(42px,49px);
			.kerning(-25);

			word-break:			break-word;
			color:						@green;
			margin-bottom:	30px;

			&.long {
				#font > #trajan > .regular(34px,39px);
			}

			&.too-long {
				#font > #trajan > .regular(31px,39px);
			}

			&.medium {
				#font > #trajan > .regular(40px,49px);
			}

			// Category Heading Tweaks
			&.category-12 {
				font-size: 36px;
			}

			&.category-6 {
				font-size: 46px;
			}

			&.category-24 {
				font-size: 27px;
				word-break: normal;
			}

			&.category-23 {
				font-size: 39px;
			}

			&.category-84 {
				font-size: 30px;
			}

			&.category-20, &.category-18 {
				font-size: 35px;
				word-break: normal;
			}

			&.category-19 {
				font-size: 40px;
				word-break: normal;
			}

			&.category-21 {
				font-size: 42px;
			}
		}

		p {
			margin-bottom:	0;
			color:			@grey3F;

			#font > #futura > .book(14px,24px);
		}
	}

	.titles {
		div {
			width:			48%;
			float:			left;
			text-align:	center;
			margin:		0 1% 30px;
			.background-img("border_green.jpg", repeat-x, 30%, 49%);


			span {
				background:		#fff;
				text-transform:	uppercase;
				padding:		0 10px;
				letter-spacing:	1px;

				#font > #futura > .book(18px,24px);
				font-style: italic;
			}
		}
	}
}

.product-list {

	.product {
		display:		block;
		float:			left;
		min-height:		1px;
		width:			25%;
		margin-bottom:	50px;
		overflow:		hidden;
		position:		relative;
		border-right:	1px solid @greyE9;
		padding:		0 20px 0;
		-webkit-opacity:0;
		-moz-opacity:	0;
		opacity:		0;

		.transition('opacity 0.1s ease');

		.box-sizing(border-box);
		//.transform-origin(center,center);
		//.transform('scale3d(1,1,1)');
		a {
			text-decoration: none;
			text-align: left;
		}

		&.featured-product {
			width:			25%;
			margin-bottom:	0;
			text-align:		center;
			#breadcrumbs{
				padding: 0 0 10px;
			}

			.description {
				padding:		0 60px;
				text-align:		left;
				line-height:	19px;
				color:			#222;

				#font > #futura > .book(13px,11px);
			}

			.price {
				line-height: 30px;
			}

			.buy-it {
				width:	100%;
				margin:	10px auto;

				.background-img("border_green.jpg", repeat-x, 30%, 32%);


				.clearfix;

				a {
					display:		inline-block;

					&:first-child {
						padding:	0 10px;
						background:	#fff;
					}

					&.ui-btn {
						position: relative;

						// line padding ;(

						&:after {
							display:		block;
							content:		" ";
							position:		absolute;
							left: 			100%;
							top:			0;
							background:		#fff;
							padding:		10px 0 10px 15px;
						}
					}
				}
			}
		}

		&.ready {
			-webkit-opacity:1;
			-moz-opacity:	1;
			opacity:		1;

			.product-image {
				display:table-cell;
				vertical-align: middle;
			}

			&.featured-product {
				.product-image {
					width:		100%;
					bottom:		auto;
					position:	relative;

					img {
						width: 195px;
					}
				}

				.title {
					padding-right: 0;
				}

				a {
					text-align: center;
				}
			}

			.product-info {
				width:		100%;
				height:	150px;
				//position:	absolute;
				bottom:	0;
			}

			a {
				text-decoration:none;
				text-align:		left;

				&:hover {
					.quickBuy {
						//opacity: 1;
						left: 65px;
					}
					.title {
						color: @greenLt;
					}
				}
			}
		}

		&.hide {
			opacity:0;
			width:	0;
			height:	0;
			border:	0;
		}

		img {
			width:	200px;
			height:	auto;
			display:block;
			margin:	0 auto;
		}

		.title {
			display:		block;
			margin-bottom:	10px;
			padding-right:	20px;
			color:			@grey28;

			#font > #playfair > .regular(24px,22px);
			.transition('color 0.5s ease');
		}

		span {
			&.special-price,
			&.old-price{

				display: block;
				.price-label{
					color:			@grey28;
				}
				span{
					display:inline-block;
					color:			@grey7B;
					&.type, &.price, &.nostock {
						margin-bottom: 0;
					}
				}
			}

			&.type, &.price, &.nostock {
				color:			@grey7B;
				display:		block;
				margin-bottom:	20px;

				#font > #futura > .book(14px,18px);
			}

			&.nostock {
				margin-bottom: 0;
			}

			&.iced {
				text-transform:	uppercase;
				text-align:		center;
				display:		block;
				color:			#fff;
				position:		absolute;
				bottom:			0;
				left:			25px;


				#font > #futura > .book(10px,11px);
				.square(45px);

				&:after {
					content:	'';
					background:	@orangeFF4;
					position:	absolute;
					top:		-13px;
					left:		0;
					z-index:	1;

					.rotate(45deg);
					.square(100%);
				}

				&:before {
					content: '';
					.size(50%,30%);
					position: absolute;
					bottom: -4px;
					left: 25%;
					z-index: 0;
					opacity: 0.35;
					.gradient(ellipse, center center farthest side, #000 0%, #fff 100%);
					.border-radius(100%);
					.transform('rotate3d(-0.1,0,0,115deg)');
				}

				span {
					position: relative;
					z-index: 2;
				}
			}
		}

		.quickBuy {
			position: absolute;
			bottom: 200px;
			left: -150px;
			//left: 50%;
			//margin-left: ((87px / -2) - 45px + 15px);
			//opacity: 0;
			z-index: 1;
			.box-shadow('0 0 6px rgba(0,0,0,0.4)');
			.backface-visibility(hidden);
			//.transition('opacity 0.5s ease');
			.transition('left 0.25s ease');
		}
	}
}

body.category-boutique{
	.product-list .product.ready .product-info{
		height:120px;
	}
}

body.ipad {
	.product-list {
		.product {
			.quickBuy {
				left: 50%;
				.transform('translate3d(-50%,0,0)');
				margin-left: -20px;
			}
		}
	}
}

@media screen and(min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
	#category {
		.space {
			padding-top: 0;
		}
	}

	#categoryList {
		width:	100%;
		margin:	40px auto 0;

		.titles {
			.featured-product {
				width:	90%;
				margin:	0 5% 5px;
			}

			.classic-product {
				display: none;
			}
		}
	}

	// fix for inline nav
	.inline-nav {
		height:		47px;
		position:	relative;
		background:	#f9f5ee;
		overflow:	hidden;
		display: none;
		&.open {
			height: auto;
		}

		&:after{
			position:	absolute;
			content:	"";
			width:		11px;
			height:		7px;
			left:		93%;
			z-index:	5;
			top:		20px;

			.background-size(11px, 7px);
			.background-img("global/navigatedown.png", no-repeat, 95%, center);
		}

		.teanav { display: none; }

		> div {
			width:	 100%;
			padding: 0;

			ul.unstyled {
				display: block;
				float: none;

				&.teanav {
					display: none;
				}

				&.right {
					float: none;
				}
			}

			.productFilterNav li {
				float:	 none;
				display: block;

				a {
					width:				100%;
					padding:			15px 15px 15px 25px;
					color:				#a69782;
					text-align:			left;
					border-top:			1px solid #e5dac6;
					-moz-box-shadow:	0 0 0 1px #fff;
					-webkit-box-shadow:	0 0 0 1px #fff;
					box-shadow:			0 0 0 1px #fff;

					#font#playfair.regular(12px);

					&:after { display: none; }

					&:before { display: none; }

					&.active { display: block; }
				}
			}
		}
	}

	.product-list {
		max-width:	100%;
		padding:	0 2%;

		.product {
			position:		relative;
			float:			left;
			height:			240px;
			margin:			20px 0;
			width:			50%;
			border-right:	1px solid #e9e9e9;

			&.featured-product {
				.description {
					padding: 0;
				}

				.buy-it a.ui-btn {
					background: #fff;

					&:after {
						left: 105%;
					}
				}
			}

			&.ready {
				&.featured-product {
					width:		100%;
					max-height: 350px;

					.product-image {
						width:	auto;
						left:	0;

						img {
							width: 168px;
						}
					}
				}

				.title {
					padding-right: 0;

					#font > #playfair > .regular(13px, 17px);
				}


				span.old-price,
				span.special-price{
					display: block;
					margin: 0 15%;
					span{
						display: inline;
						width: inherit;
						margin: 0;
					}
				}
				span.price {
					font-size: 12px;
				}

				a {
					margin-right: 0;

					.type {
						display: none;
					}
				}

				.product-image {
					width:65%;
					left: 19%;

					img {
						width:	80%;
					}
				}
			}
		}
		.product.ready,
		.product {
			height:auto !important;
			.product-image{
				display:inherit;
				height:150px !important;
				text-align:center;
				overflow:hidden;
				float:left;
				width:100%;
				img{
					width:auto !important;
					max-height:100%;
					height:auto;
				}
			}
			.product-info {
				height:80px;
				position:inherit;
				margin-top:20px;
				float:left;
				strong.title{
					min-height:40px;
				}
			}
		}
	}
}

@media screen and(min-width: @phoneWidthP) and (max-width: @phoneWidthL) and (orientation: landscape) {
	.product-list {
		.product {
			min-height: 320px;
			img {
				width: 58%;
			}
		}
	}
}

@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: portrait) {
	#categoryHero .cattitle {
		background-size: cover;

		.content {
			font-size: 19px;
		}
	}

	#categoryList {
		.titles {
			.featured-product {
				width: 100%;
			}

			.classic-product {
				display: none;
			}
		}
	}

	.product-list {
		.product {
			width: 33%;

			&.featured-product {
				width:	100%;
				height:	auto !important;
				margin:	0 0 50px 0;
			}
		}
	}
}
#categoryProducts {
	.inline-nav {
		.featured-products,
		.teaware-item {
			display: none;
		}
	}
}
.category-contemporary #categoryList .heading h1.medium {
	font-size: 25px;
}
.inline-nav > div a {
	font-weight: bold!important;
}
@media screen and (max-width: 1024px) and (min-width: 751px){
	#nav > ul.unstyled > li > a {
		padding: 0 10px!important;
	}

}
@media screen and (max-width: 750px) and (min-width: 469px){
	#categoryHero .cattitle {
		background-position: 85% 0;
	}
}
@media screen and (max-width: 550px) and (min-width: 468px){
	.cms-uae-facility #cms-page .content-wrapper .h1 {
		width: 200px!important;
	}
}
@media screen and (max-width: 468px) and (min-width: 300px){
	.categorypath-teas-gifts-fine-tea-collection-html #categoryHero .cattitle {
		height: 140px;
		background-size: contain;
	}
	.cms-uae-facility #cms-page .content-wrapper .h1 {
		width: 200px!important;
		background-color: rgba(255, 255, 255, 0.3);
		padding: 10px;
	}
}
