@font-face {
  font-family: 'Garamond Premier Pro It';
  src: url('../fonts/garamond/GaramondPremrPro-It.eot');
  src: url('../fonts/garamond/GaramondPremrPro-It.eot?#iefix') format('embedded-opentype'),
  url('../fonts/garamond/GaramondPremrPro-It.woff') format('woff'),
  url('../fonts/garamond/GaramondPremrPro-It.ttf') format('truetype'),
  url('../fonts/garamond/GaramondPremrPro-It.svg#GaramondPremrPro-It') format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Garamond Premiere Pro';
  src: url('../fonts/garamond/GaramondPremrPro.eot');
  src: url('../fonts/garamond/GaramondPremrPro.eot?#iefix') format('embedded-opentype'),
  url('../fonts/garamond/GaramondPremrPro.woff') format('woff'),
  url('../fonts/garamond/GaramondPremrPro.ttf') format('truetype'),
  url('../fonts/garamond/GaramondPremrPro.svg#GaramondPremrPro') format('svg');
  font-weight: normal;
  font-style: normal;
}