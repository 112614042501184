.cms-contact-us {

	.block-contact {
    	padding: 30px 30px 0 70px;
    	p {
		    font-size: 18px;
	        line-height: 25px;
		}
	}
	
	ul.form-list {
    	max-width: 624px;
    	width: 100%;

    	li.fields {
		    width: 48%;
		    float: left;
		    margin-left: 4%;
		}
    	li.fields:first-child {
    		margin-left: 0;
		}
		li.wide {
		    width: 100%;
		    margin: 4px 0 0;
	        clear: both;
		}

		input, textarea, select{
			background-color: #f4f4f4;
		}

	}

	.contact-form {
		margin: 62px 0;

		.form-list {
			max-width:	624px;
			width:		100%;
			list-style:	none;

			.clearfix;

			li {
				&.fields {

					.field {
						width:		100%;
						margin:		30px 0;
						position:	relative;

						label {
							position:	absolute;
							top:		5px;
							left:		10px;
						}


						&.hovered {
							label {
								top:	-25px;
								left:	0;
							}

							.input-text {
								background: #f4f4f4;
							}
						}

						.input-text {
							width:		100%;
							border:		0;
							padding:	10px;
							cursor:		pointer;
							box-sizing: border-box;

						}
						.select-box{
							select{
								width: 100%;
								border: 0;
								padding: 10px;
							}
						}


					}
				}

				&.wide {
					width:		100%;
					float:		left;
					margin:		4px 0 0;

					.field {
						position:	relative;

						label {
							position:	absolute;
							top:		5px;
							left:		10px;
						}

						.input-text {
							background: #f4f4f4;
						}

						&.hovered {
							label {
								top:	-25px;
								left:	0;
							}

							.input-text {
								background: #f1f1f1;
							}
						}

						&:hover {
							.input-text {
								background: #f4f4f4;
							}
						}

						.input-text {
							width:		100%;
							border:		0;
							padding:	10px;
							cursor:		pointer;
							box-sizing: border-box;
						}

						textarea {
							width:	100%;
							height:	100%;
						}
					}
				}
			}
		}

		button {
			.ui-btn;
			.ui-btn.green;
		}
	}

	.contact-header {
		padding:		0 0 48px;
		border-bottom:	1px solid grey;

		img {
			width: 100%;
		}

		.text {
			padding: 40px 0 0;
		}
	}

	.contact-office {
		margin:	60px 0 30px;
	}

	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		.contact-form {
			margin-top: 48px;

			.form-list li.fields, .form-list li.wide {
				width:		100%;
				float:		none;
				margin-left:0;
			}
		}

	}

	#cms-page{
		padding-top: 0;

		h2 {
			margin-bottom: 8px;
			#font > #trajan > .regular(23px,30px);
		}
		.slide-text-style{
			.content-wrapper{
				div#box-contact{
				    color: #000;
				    padding: 30px !important;
				    background: transparent;
				    box-shadow: none;
				    float: right;
				    &:before{
				    	content: '';
				    	background: #fff;
				    	opacity: 0.65;
				    	border-radius: 80px;
				    	width: 100%;
				    	height: 100%;
				    	position: absolute;
				    	top: 0;
				    	left: 0;
				    }
				    .office-box {
					    position: relative;
					    a{
					    	color: #0000FF;
					    }
					}
				    .small-size{
				    	width: inherit;
				    }
				    .color-green, h3{
				    	color: #004538;
				    }
				    .color-green{
					 	font-size: 20px;
				    }
				    h3{
				    	font: normal normal 400 50px/29px 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif;
				    }
				}
			}
		}
	    
	}
	
	@media screen and (min-width: @fullPcWidth) {

		#cms-page{

			.slide-text-style {

				.content-wrapper{
				    .description-slide {
					    bottom: auto !important;
					}
				}	
			}
		}

	}

	.block-form{
	    margin-bottom: 25px;
		clear: both;
	    #contactForm{
		    padding: 0px 30px 0 70px;
		    ul{
				li{
					// margin-left: 0;
					label{
					    position: absolute;
    					top: 5px;
    					left: 10px;
					}
				}
				.field {
				    position: relative;
				    margin: 30px 0;
				}

			}
			.field.hovered label {
			    top: -25px;
			    left: 0;
			}
			button{
				display: inline-block;
			    height: 35px;
			    font: normal normal 400 14px/36px 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif;
			    letter-spacing: 0.1em;
			    text-transform: uppercase;
			    text-decoration: none;
			    border: none;
			    padding: 0 20px;
			    -moz-border-radius: 2px;
			    -khtml-border-radius: 2px;
			    -webkit-border-radius: 2px;
			    border-radius: 2px;
			    cursor: pointer;
			    opacity: 1;
			    white-space: nowrap;
			    color: #fff;
			    -webkit-transition: opacity 0.15s ease, background-color 0.25s ease;
			    -moz-transition: opacity 0.15s ease, background-color 0.25s ease;
			    -ms-transition: opacity 0.15s ease, background-color 0.25s ease;
			    -o-transition: opacity 0.15s ease, background-color 0.25s ease;
			    transition: opacity 0.15s ease, background-color 0.25s ease;
		        background-color: #093e2c;
		        &:hover:not([disabled]) {
				    background-color: #005e20;
				}
			}
			input{
				height: 35px;
			    padding: 0 15px;
			    border: none;
			    color: #3f4442;
			    font: normal normal 500 14px/normal 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif;
			    letter-spacing: 0.015em;
			    -moz-border-radius: 2px 0 0 2px;
			    -khtml-border-radius: 2px 0 0 2px;
			    -webkit-border-radius: 2px 0 0 2px;
			    border-radius: 2px 0 0 2px;
			    -webkit-box-sizing: border-box;
			    -moz-box-sizing: border-box;
			    box-sizing: border-box;
			    width: 100%;
			}
			textarea, select{
			    width: 100%;
			    border: none;
			}
			textarea{
			    padding: 10px;
			    box-sizing: border-box;
			}
			select{
				height: 35px;
			}
	    	
	    }

	}
}