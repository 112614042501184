.cms-people-and-career{

	.block-commom{

		.block-line {

		    background: #005847;
		    color: #fff;
		    text-align: center;
		    padding: 10px 0;
		    font-size: 20px;
		}

		ul{
			margin-bottom: 0;
			background: #005847;
			li{
				margin-left: 0;
			    width: 50%;
			    display: inline-block;
			    text-align: center;
			    vertical-align: middle;
			    color: #fff;
			    font-size: 20px;
			    span {
				    padding: 0 40px;
				    display: inline-block;
				}
			}
		}

		ul.text-img{
			li{
				font-size: 80px;
    			color: #093e2c;
    			line-height: 80px;
    			img{
    				max-height: 365px;
    				height: 100%;
    				width: 100%;
    			}
			}
		}

		.block-teams{
			background: transparent;
		}
		
		.block-button{
			.content-wrapper {
		    	text-align: right;
		    	padding: 15px 0;
		    	a{
				   	display: inline-block;
				    padding: 10px 20px;
				    background: #cc1919;
				    color: #fff !important;
				    font-size: 21px;
				    font-weight: 100 !important;	
		    	}
			}
		}
		.footer-img{
			position: relative;
			.content-wrapper {
			    position: absolute;
			    left: 0;
			    right: 0;
			    top: 0;
			    bottom: 30px;
			    .footer-text {
					position: absolute;
					bottom: 0;
					right: 0;
					display: inline-block;
				    padding: 10px 20px;
				    background: #cc1919;
				    color: #fff;
				    font-size: 21px;
				    font-weight: 100;
				}	
			}
		}
		
	}
	#cms-page{
		a.footer-text {
			color: #fff;
		}
	}
}