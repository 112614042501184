#store-locator {
	padding-top: 	(@headerExposedHeight - @headerHeight + 30px);

	> section {
		width:	@maxWidth;
		margin:	0 auto;
	}
}

#store-locator-search {
	padding-bottom: @gutter;
}

#sl-loader {
	display: none !important;
}

#map-container {
	padding-bottom: 50px;
	> .col {
		display: inline-block;
		float: left;
	}
}
#sidebar {
	.size((@maxWidth - @storeLocatorMap - @gutter),@storeLocatorMap);

	margin-right:		@gutter;
	overflow:			auto;
	background-color:	@tanLt;

	.sidebar-entry-container {
		&:first-child {
			.sidebar-entry-wrapper {
				padding-top: @gutter;

				> img {
					top: @gutter;
				}
			}
		}

		&:last-child {
			.sidebar-entry-wrapper {
				padding-bottom: @gutter;
			}
		}
	}

	.sidebar-entry-wrapper {
		position:			relative;
		padding:			(@gutter / 2) @gutter (@gutter / 2) (@gutter + 25px);
		background-color:	@tanLt;
		cursor:				pointer;

		.transition('background-color 0.25s ease');
		&:hover {
			background-color: @tanMd;
		}

		> img {
			position:	absolute;
			top:		(@gutter / 2);
			left:		@gutter;
		}
	}

	.sidebar-entry {
		#font > #futura > .heavy(14px,25px);
		> span {
			#font > #futura > .heavy(14px,25px);
			.kerning(10);
			color: #000;
			display: block;
		}
		> address {
			#font > #futura > .book(14px,21px);
			.kerning(10);
			color: #000;
			font-style: normal;
		}
		> br {
			display: none;
		}
	}
	.directions {
		display: none !important;
	}
}
#map {
	.square(@storeLocatorMap);
}

@media screen and (min-width: @tabletWidthP) and (max-width:  @tabletWidthL) {
	#sidebar {
		margin-right:	0;
		margin-left:	15px;
	}

	#store-locator-search {
		.inline-block-form label {
			display: block;
		}

		.inline-block-form {
			padding: 0 20px;
			> .col {
				width:	320px;

				&:nth-child(2) {
					width: 200px;
				}

				&:last-child {
					width:		200px;
					padding:	36px 0 0;
				}
			}
		}
	}
}

@media screen and (min-width: @tabletWidthP) and (max-width:  @tabletWidthL) and (orientation: portrait) {
	#store-locator > section {
		width: 100%;
	}

	#map {
		width: 514px;
	}

	#sidebar {
		margin-right:	0;
		margin-left:	0;
		width:			33%;
	}


}