#scrolling {
	width:	100%;
	color:	#fff;

	#font > #futura > .book(18px,24px);
	.kerning(15);

	> section {
		width: 100%;
		background-size: cover;
		background-position: center center;
		position: relative;
		z-index: 1;

		&.active {
			z-index: 0;
			.scrolling-wrapper {
				display: block;
			}
		}
	}
	.scrolling-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		.square(100%);
		z-index: 5;
		opacity: 0;
		display: none;
		text-align: center;
		text-shadow: 0 0 10px rgba(0,0,0,1.0);
	}

	.scrolling-table {
		display:	inline-table;
		max-width:	@maxWidth;
		height:		100%;
	}
	.scrolling-cell {
		display:		table-cell;
		vertical-align:	middle;
		.square(100%);
	}

	h1 {
		#font > #trajan > .regular(61px,69px);
		text-transform: uppercase;
		letter-spacing: normal;
		margin-bottom: (@gutter * 2);

		small {
			#font > #playfair > .regular(20px,20px);
			display: block;
			padding-top: 10px;
		}
	}

	h2 {
		#font > #trajan > .regular(61px,69px);
		text-transform: uppercase;
		letter-spacing: normal;
		margin-bottom: (@gutter / 2);
	}

	.col {
		display: inline-block;
		float: left;
		width: 41%;
		text-align: left;
		margin: 0 4%;

		&:first-child {
			margin: 0 5%;
		}
	}

	.scrolling-prompt {
		display: block;
		text-align: center;
		width: 100%;
		padding-top: 23%;
		padding-bottom: 45px;
		position: absolute;
		bottom: 0;
		left: 0;

		#font > #futura > .medium(18px,18px);

		&:before {
			position: absolute;
			left: 50%;
			bottom: 10px;
			margin-left: -9px
		}

		&:after {
			content: '';
			.size(0,78%);
			position: absolute;
			top: 0;
			left: 50%;
			margin-left: -1px;
			border-right: dotted 1px #fff;
		}
	}



	@media screen and(min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		h1 {
			text-transform:		uppercase;
			letter-spacing:		normal;
			margin-bottom:	(@gutter * 2);
			margin-top:			32px;

			#font > #trajan > .regular(34px, 41px);

			small {
				#font > #playfair > .regular(20px,20px);

				display:			block;
				padding-top:	10px;
			}
		}

		h2 {
			text-transform:		uppercase;
			letter-spacing:		normal;
			margin-bottom:	(@gutter / 2);
			margin-top:			30px;

			#font > #trajan > .regular(26px,34px);
		}

		p {
			padding: 0 15px;
		}

		.col {
			width:		100%;
			text-align:	center;
			padding:	0 20px;
			margin-left: 0;
			margin-right: 0;

			&:first-child {
				margin-left: 0;
				margin-right: 0;
			}
		}

		.scrolling-prompt {
			display: none;

			&:after {
				display: none;
			}
		}

		.scrolling-wrapper {
			position:	absolute;
			display:	block;
			opacity:	1;
		}

		#scrolling-subnav {
			display: none;
		}
	}

	@media screen and(min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: portrait) {
		h1 {
			margin-top: 32px;
		}

		.col {
			width: 100%;
			float: none;
			text-align: center;
			padding-top: 30px;
			margin-left: 0;
			margin-right: 0;

			&:first-child {
				margin-left: 0;
				margin-right: 0;
			}
		}

		.scrolling-prompt {
			display: none;
		}

		p {
			padding: 0 15px;
		}

		.scrolling-cell {
			padding: 0 20px;
		}
	}

	@media screen and(min-width: @tabletWidthP) and (max-width: @tabletWidthL)
	and (orientation: landscape) {
		h1 {
			margin-top: 32px;
		}


		.scrolling-cell {
			padding: 0 20px;
		}

		.scrolling-prompt {
			display: none;
		}
	}
}


#scrolling-subnav {
	position:		fixed;
	left:			0;
	top:			-@scrollingSubNavHeight;
	background:		rgba(0,0,0,0.9);
	z-index:		2;
	text-align:		center;

	.backface-visibility(hidden);
	.transition('top 0.25s ease');
	.size(100%,@scrollingSubNavHeight);

	&.pinned {
		top: 0;
	}

	ul {
		display: inline-table;;
		width: @maxWidth;

		li {
			display: table-cell;
			text-transform: uppercase;

			#font > #futura > .book(12px,@scrollingSubNavHeight);
			.kerning(100);

			&.active {
				a {
					#rgba > .color(@logoWhite);
				}
			}

			a {
				text-decoration: none;

				#rgba > .color(@logoWhite,0.4);
				.transition('color 0.25s ease');
			}
		}
	}
}

body {
	&.ipad {
		#scrolling {
			.scrolling-wrapper {
				position: absolute;
				display: block;
				opacity: 1 !important;
			}
		}
		#scrolling-subnav {
			top: 0;
			display: none;
		}
	}
}