@media screen and (max-width: @tabletWidthP) {

	.sustainability-page #cms-page .slide-wrapper .slide-text > div { padding: 0 5%; }

}

@media screen and (max-width: @mobileWidth) {

	.sustainability-page #cms-page {

		.environment-list li {
			width: 	50%;
		}

		.environment-block{
			.content-wrapper{
				overflow: hidden;
			}
		}

	}

}
