#recipe-list {
	> img {
		display: block;
		width: 100% !important;
		height: auto !important;
	}
}

#recipe-list-items {
	max-width:	@maxWidth;
	margin:		40px auto (40px - @recipeListGutter) auto;

	> li {
		display:		inline-block;
		float:			left;
		width:			32%;
		overflow:		hidden;
		margin-bottom:	@recipeListGutter;

		&:nth-child(3n+2){
			margin-left:	@recipeListGutter;
			margin-right:	@recipeListGutter;

			span.img {
				img {
					.transform-origin(center,center);
				}
			}
		}

		&:nth-child(3n+3){
			span.img {
				img {
					.transform-origin(right,center);
				}
			}
		}

		> a {
			display:			block;
			padding:			@recipeListItemPad @recipeListItemPad (@recipeListItemPad * 2) @recipeListItemPad;
			background-color:	@tanLt;
			text-align:			center;
			text-decoration:	none;

			&:hover {
				.ui-btn.green {
					background-color: @greenLt;
				}

				span.img {
					img {
						opacity: 1;
						.transform('scale3d(1.05,1.05,1.05)');
					}
				}
			}
		}

		span.img {
			display: block;
			.square(270px);
			overflow: hidden;
			margin: 0 auto 40px auto;
			.border-radius(100%);
			// webkit border radius overflow hidden bug
			// check recipe-list.js this was moved because of magento concat
			//-webkit-mask-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC');
			img {
				opacity: 0.85;
				.transform-origin(left,center);
				.transform('scale3d(1,1,1)');
				.backface-visibility(hidden);
				.transition('all 0.5s ease');
			}
		}
		strong {
			#font > #playfair > .regular(27px,34px);
			color: @grey3F;
			margin-bottom: 0;
			display: block;
		}
		span.descr {
			#font > #futura > .book(16px,21px);
			display: block;
			.kerning(15);
			margin-bottom: 20px;
			color: @grey3F;
		}
	}

	@media screen and (min-width: @tabletWidthP)
	and (max-width: @tabletWidthL)
	and (orientation: portrait) {
		li {
			width: 50%;

			&:nth-child(3n+2) {
				margin-left:	0;
				margin-right:	0;
			}

			strong {
				#font > #playfair > .regular(23px, 34px);
			}
		}
	}

	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL){
		li {
			width: 100%;

			&:nth-child(3n+2) {
				margin-left:	0;
				margin-right:	0;
			}

			strong {
				#font > #playfair > .regular(18px, 26px);
			}

			span {
				&.img {
					width: 190px;
					height: 190px;

					img {
						width:		130%;
						max-width:	130%;
					}
				}
			}
		}
	}
}