#dummy {
	// default magento password reset uses this id for some input that's not needed
	display: none;
}
#account {
	background-color: @tanLt;

	> section {
		max-width:	@maxWidth;
		padding:	80px 0;
		margin:		0 auto;
	}

	#login-form, #form-validate {
		max-width:	603px;
		position:	relative;
		padding:	50px 75px;
		margin:		0 auto;
		background:	#fff;

		.box-sizing(border-box);
	}


	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		> section {
			padding: 40px 2%;
		}

		#login-form, #form-validate {
			padding: 30px 15px;
		}

		.account-form {
			.fieldset {
				padding-top: 20px;
			}

			.form-list li.fields {
				.field {
					width:		100%;
					padding:	0;
					margin:		5px 0 5px;

					&:last-child, &:first-child {
						padding: 0;
					}
				}
			}
		}
	}
}

.account-form {
	position: relative;

	h1 {
		margin-bottom:	@gutter;
		padding-bottom:	(@gutter / 2);
		border-bottom:	solid 1px @grey3F;

		#font > #playfair > .regular(16px,16px);
	}

	h2, h3, dt {
		margin-bottom: (@gutter / 2);
		text-transform: uppercase;

		#font > #futura > .heavy(14px,14px);
		.kerning(30);
	}

	h4 {
		text-transform:	uppercase;
		margin-bottom:	(@gutter / 5);
		color:			@greenLt;

		#font > #futura > .book(14px,14px);
		.kerning(30);
	}

	h5 {
		text-transform: uppercase;
		margin-bottom: (@gutter / 5);

		#font > #futura > .heavy(13px,13px);
		.kerning(30);
	}

	.input-box {
		position: relative;

		.validation-advice {
			text-transform:	uppercase;
			text-decoration:none;
			color:			@redMsg;
			display:		block;
			margin:			0;
			padding:		3px 0;


			#font > #futura > .book(11px,11px);
			.kerning(30);
			.backface-visibility(hidden);
		}
	}

	.col2-set {
		width:		100%;
		position:	relative;

		.clearfix();

		.col-1, .col-2 {
			width:		50%;
			display:	inline-block;
			float:		right;

			.box-sizing(border-box);
		}

		.col-1 {
			padding-left: @gutter;
		}

		.col-2 {
			padding-right: @gutter;

			&:after {
				content:	'';
				position:	absolute;
				left:		50%;
				top:		0;
				background-color: @greyF2;

				.size(1px,100%);
			}
		}
	}

	a {
		&.f-password {
			display:			inline-block;
			text-transform:		uppercase;
			text-decoration:	none;
			color:				@grey3F;

			#font > #futura > .book(11px,11px);
			.kerning(30);
			.transition('color 0.25s ease');

			&:hover {
				color: @greenLt;
			}
		}
	}

	p {
		&.plaintext {
			#font > #futura > .book(16px,24px);
			color: @grey3F;
		}
	}

	.right-links {
		display:	inline-block;
		float:		right;
		text-align:	right;

		a {
			#font > #futura > .book(11px,11px);
			text-transform: uppercase;
			text-decoration: none;
			.kerning(30);
			color: @grey3F;
			.transition('color 0.25s ease');
			&:hover {
				color: @greenLt;
			}
		}
	}

	.form-list {
		margin: 0;
		list-style-type: none;

		li {
			margin: 0 0 10px 0;

			&.fields {
				width: 100%;
				.clearfix();

				.field {
					width:		50%;
					display:	inline-block;
					float:		left;

					.box-sizing(border-box);

					&:first-child {
						padding-right: (@gutter / 2);
					}

					&:last-child {
						padding-left: (@gutter / 2);
					}
				}
			}
		}
	}

	label {
		text-transform: uppercase;

		#font > #futura > .book(12px,12px);
		.kerning(30);

		em {
			display:		inline-block;
			font-style:		normal;
			margin-right:	2px;
		}
	}

	input {
		&[type="text"], &[type="password"] {
			padding:			0 15px;
			border:				none;
			background:			none;
			background-color:	@greyF2;
			color:				@grey3F;
			margin-top:			5px;

			.size(100%,@btnHeight);
			.border-radius(2px 0 0 2px);
			.box-sizing(border-box);
		}

		&[type="text"] {
			#font > #futura > .book(14px,normal);
			.kerning(15);
		}

		&[type="radio"], &[type="checkbox"] {
			margin-right: 10px;
		}
	}

	textarea {
		padding:	0 15px;
		border:		none;
		background:	none;
		background-color: @greyF2;
		color:		@grey3F;
		margin-top:	5px;

		.textarea-size(100%,115px);
		.border-radius(2px 0 0 2px);
		.box-sizing(border-box);
	}

	select {
		padding:			0 15px;
		border:				none;
		background:			none;
		background-color:	@greyF2;
		color:				@grey3F;
		margin-top:			5px;

		.border-radius(2px 0 0 2px);
		.box-sizing(border-box);
		.size(100%,@btnHeight);
	}

	li.item {
		border-top:		1px solid  @greyF2;
		padding-top:	20px;
		margin-top:		20px;

		&:first-child {
			margin-top: 0;
		}

		.product-img-box {
			display: none;
		}
	}

	.buttons-set {
		position:	relative;
		padding:	35px 0 25px 0;

		.required {
			text-transform:	uppercase;
			text-decoration:none;
			color:			@grey3F;
			margin:			0;
			position:		absolute;
			bottom:			0;
			left:			0;

			.kerning(30);
			#font > #futura > .book(11px,11px);
		}
	}

	.split { .size(100%, @gutter); }
}

#my-reviews-table {
	width:			100%;
	color:			@grey3F;
	border-bottom:	2px solid @grey98;
	border-top:		2px solid  @grey98;
	border-collapse:collapse;

	#font > #futura > .book(14px,16px);
	.kerning(30);

	h2 {
		text-transform: none;
		margin:			0;

		#font > #futura > .book(14px,16px);

		a {
			color: @grey3F;

			&:hover { color: @greenLt; }
		}
	}

	td {
		padding:		10px 0;
		vertical-align:	top;
		text-align:		left;
		border-top:		1px solid @greyD7;

		&.product { width: 100px; }

		&.date { width: 95px; }

		&.review { width: (@ratingStarWidth * 5); }

		&.link {
			width:		80px;
			text-align:	right;
		}
	}

	tr:first-child td {
		border-top: none;
	}

	div.ratings {
		padding: 0;
	}

	a {
		color:			@greenLt;
		text-decoration:none;
		.transition('color 0.25s ease');

		&:hover {
			color: @grey3F;
		}
	}
}

#my-orders-table {
	width:			100%;
	color:			@grey3F;
	border-bottom:	solid 2px @grey98;
	border-collapse:collapse;

	#font > #futura > .book(14px,16px);
	.kerning(30);

	th {
		width:				20%;
		text-transform:		uppercase;
		border-bottom:		2px solid @grey98;
		text-align:			left;
		padding:			10px 0;

		#font > #futura > .medium(14px,16px);
	}

	td {
		padding:		10px 0;
		vertical-align:	top;
		text-align:		left;
		border-top:		1px solid  @greyD7;

		&:last-child {
			text-align: right;
		}
	}

	tr:first-child td {
		border-top: none;
	}

	a {
		color:			@greenLt;
		text-decoration:none;

		.transition('color 0.25s ease');

		&:hover {
			color: @grey3F;
		}
	}
}

#account-review-order-buttons {
	margin-bottom: @gutter;

	a {
		float:			left;
		margin-right:	5px;
	}
}

#my-account-order-summary {
	h3:after {
		display: none;
	}

	address {
		padding-top:	0 !important;
		padding-bottom:	@gutter !important;
	}
}

.customer-account-index,
.customer-account-edit,
.newsletter-manage-index,
.review-customer-index,
.sales-order-history,
.customer-address-form,
.review-customer-view,
.sales-order-view,
.customer-address-index,
.customer-paymentinfo-index,
.monkey-customer-account-index {
	main {
		background-color: @tanLt;
		padding-top: 80px;

		> div {
			padding-bottom: 80px;

			> aside, > article {
				background-color: #fff;
				padding: @gutter;
			}

			> aside {
				width: 35%;
			}

			> article {
				width: 62%;
			}
		}
	}

	#breadcrumbs {
		display: none;
	}

	@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) {
		main > div {
			> article, > aside {
				width:	96%;
				margin:	20px 2%;
			}
		}
	}

	@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: landscape) {
		main > div {
			> aside {
				width: 30%;
			}

			> article {
				width: 62%;
			}
		}
	}

	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		main {
			padding-top: 0;
			padding-bottom:0;
		}

		main > div {
			width: 100%;
			padding-bottom: 0;

			> aside {
				width: 100%;
				float: none;
			}

			> article {
				width: 100%;
				float: none;
				margin-left: 0;

				.col2-set .col-2, .col2-set .col-1 {
					width:		100%;
					float:		none;
					display:	block;
					padding:	20px 0 0;
				}

				.my-account > .buttons-set {
					margin-top: 26px;
				}
			}
		}

		#my-orders-table {
			border: 0;

			thead {
				display: none;
			}

			tr {
				margin-bottom: 10px;
				display: block;
				border-bottom: 2px solid #ddd;
			}

			td {
				display: block;
				text-align: right;
				font-size: 13px;
				border-bottom: 1px dotted #ccc;
			}

			td:last-child {
				border-bottom: 0;
			}

			td:before {
				content: attr(data-label);
				float: left;
				text-transform: uppercase;
				font-weight: bold;
			}
		}

		.account-form {
			.fieldset {
				padding-top: 20px;
			}

			.form-list li.fields {
				.field {
					width:		100%;
					padding:	0;
					margin:		5px 0 5px;

					&:last-child, &:first-child {
						padding: 0;
					}
				}
			}
		}
	}
}
