@media screen and (max-width: @tabletWidthL) {

	.quality-page #cms-page .second .second-img {
		width: 40%;
	}
	
	.quality-page .slide-text-style .content-wrapper {
	    padding: 50px 15px;
	}

	.quality-page .circle-img .content-wrapper img, .quality-page .block-managemnt .content-wrapper img {
    	top: 0%;
	}

	.quality-page .circle-img .content-wrapper h2, .quality-page .block-managemnt .content-wrapper h2{
		width: 50%;
	}

	.quality-page .circle-img .content-wrapper img, .quality-page .block-managemnt .content-wrapper img {
	    width: 55% !important;
	    left: 0;
	    right: 0;
	}

	.quality-page .slide-text-style .content-wrapper h1 {
	    color: #093e2c;
	    width: 70%;
	}

}

@media screen and (max-width: @tabletContainerWidthP) {

	.quality-page .slide-text-style .content-wrapper h1 {
	    width: 100%;
	}

	.quality-page .slide-text-style .content-wrapper .description-slide {
   		 padding: 15px 20px;
	}

}

@media screen and (max-width: @iphoneWidthL) {

	.quality-page .slide-text-style .content-wrapper {
    	position: inherit;
	}

	.quality-page .slide-text-style .content-wrapper .description-slide{
		position: relative;
		bottom: 0
	}
	

	.quality-page .slide-text-style .content-wrapper {
    	padding: 0;
	}

	.quality-page .slide-text-style .content-wrapper h1 {
	    font-size: 20px !important;
	    width: 100%;
	    text-align: center;
	    top: 100px !important;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    padding-left: initial !important;
	    position: absolute;
	    margin-bottom: 0;
	}

	.quality-page .circle-img .content-wrapper img, .quality-page .block-managemnt .content-wrapper img {
	    width: 80% !important;
	    left: 0;
	    right: 0;
	    margin: 0 auto;
	}

	.quality-page .circle-img .content-wrapper h2, .quality-page .block-managemnt .content-wrapper h2 {
    	width: 100%;
    	background: #005847;
    	margin-bottom: 0 !important;
	}

	.quality-page .block-testing .content-wrapper .description-slide {
	    top: 0px;
	}

}

@media screen and (max-width: @phoneWidthP) {
	.quality-page .circle-img .content-wrapper h2, .quality-page .block-managemnt .content-wrapper h2 {
	    font-size: 35px !important;
	}
}