.amseohtmlsitemap-index-index{

  main {
    width: 1024px;
    margin: 0 auto;
    margin-top: 100px;
    font: normal normal 400 15px/60px futura-pt, Arial, 'Helvetica Neue', Helvetica, sans-serif;

    h1, h2 {
      color: #3f4442;
      text-transform: uppercase;
      font: normal normal 400 38px/38px trajan-pro-3, Times, 'Times New Roman', Georgia, serif;
    }
    h2 {
      margin: 15px 0;
    }
    h2, dt {
      float:left;
      width:100%;
      margin: 30px 0 10px;
      font: normal normal 400 24px/24px trajan-pro-3, Times, 'Times New Roman', Georgia, serif;
    }
    dd{
      float:left;
      width:100%;
    }

    ul {
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;
        overflow: hidden;
        width:100%;
        float:left;
        line-height:32px;
        a {
          color: #3f4442;
          padding:0;
          margin:0;
          text-decoration: none;
          &:hover {
            color: #005e20;
          }
        }
      }

    }
    li {
      width:100%;
      float:left;
      padding: 0;
      overflow: hidden;
      line-height:32px;
      a {
        color: #3f4442;
        text-decoration: none;
        padding:0;

        &:hover {
          color: #005e20;
        }
      }
    }
  }
  .am-sitemap-cell{

    overflow:hidden;
  .tree-leaf{
    margin-left:-30px;
  }

  }
}