#how-to-brew {
	> header, > section {
		max-width: @maxWidth;
		margin: 0 auto;
	}

	> header {
		padding:	70px 0 40px 0;
		text-align:	center;

		h1 {
			#font > #trajan > .regular(61px,59px);
			color: @grey28;

			span {
				display:block;
				color:	@grey3F;

				#font > #trajan > .regular(30px,59px);
			}
		}
	}

	h2 {
		#font > #playfair > .regular(30px,30px);

		color:			@grey28;
		text-align:		center;;
		margin-bottom:	@gutter;
	}

	.ui-tabs {
		text-align:		center;
		padding-bottom:	(@gutter * 2);

		.ui-tabnav {
			width: 450px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: (@gutter * 2);
			li {
				a {
					text-align: center;
				}
			}
		}
		.ui-tab-container {
			text-align: left;
			.col {
				display: inline-block;
				float: right;
				width: @recipePostColWidth;
				&:first-child {
					margin-left: @recipePostGutter;
				}
			}
		}
	}

	table {
		width: 100%;
		margin-bottom: 1em;
		padding-top: @gutter;
		border-bottom: solid 1px @greyD7;
		th {
			width: 25%;
			#font > #futura > .book(12px,11px);
			color: @grey63;
			text-transform: uppercase;
			text-align: left;;
			padding-bottom: 8px;
			border-bottom: solid 1px @greyD7;
			&:first-child {
				width: 60%;
			}
			&:last-child {
				width: 15%;
			}
		}

		td {
			#font > #futura > .medium(14px,16px);
			color: @grey3F;
			padding: (@gutter / 2) 0;
			vertical-align: top;
		}
	}

	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		> header {
			padding: 40px 0 40px 0;

			h1 {
				#font > #trajan > .regular(39px, 40px);
			}
		}

		.how-to-brew-item {
			width:		90%;
			margin:		20px 5%;
			padding:		20px 0;

			h2 {
				#font > #futura > .book(22px,25px);
				.kerning(15);

				margin: 0 0 5px;
			}

			p {
				#font > #futura > .book(14px,17px);
				.kerning(15);
			}

			.col {
				width:	90%;
				margin:	0 5%;
				float:	none;
				display:block;
			}
		}
	}

	@media screen and(min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: portrait) {
		.ui-tabs {
			width:	80%;
			margin:	0 10%;
		}

		.ui-tabs .ui-tab-container .col {
			width:	50%;
			margin:	0 0;

			&:last-child {
				margin-left:	0;
				width:			50%;
			}

			&:first-child {
				margin-left: 0;
			}

			img {
				width: 80%;
				height: auto;
			}
		}
	}

	@media screen and(min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: landscape) {
		.ui-tabs {
			width:	80%;
			margin:	0 10%;
		}

		.ui-tabs .ui-tab-container .col {
			width:	50%;
			margin:	0 0;

			&:last-child {
				margin-left:	0;
				width:			50%;
			}

			&:first-child {
				margin-left: 0;
			}

			img {
				width: 80%;
				height: auto;
			}
		}
	}
}

#how-to-brew-steps {
	margin-bottom: (@gutter * 3);

	> li {
		float:				left;
		overflow:			hidden;
		padding:			@recipeListItemPad @recipeListItemPad;
		background-color:	@tanLt;
		text-align:			center;
		display:			block;
		color:				@grey3F;
		counter-increment: 	section;

		.box-sizing(border-box);
		.kerning(15);
		#font > #futura > .book(14px,21px);
		.size(@recipeListItemWidth,623px);

		&:nth-child(3n+2){
			margin-left:	@recipeListGutter;
			margin-right:	@recipeListGutter;
		}

		span.img {
			display:	block;
			overflow:	hidden;
			margin:		0 auto 40px auto;

			.border-radius(100%);
			.square(270px);
			// webkit border radius overflow hidden bug
//			-webkit-mask-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC');
		}

		strong {
			color:			@grey3F;
			margin-bottom:	20px;
			display:		block;

			.kerning(0);
			#font > #playfair > .regular(29px,34px);

			&:before {
				content: counter(section)'. ';
			}
		}
	}

	@media screen and(min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		> li {
			width:	90%;
			margin:	20px 5%;
			float:	none;

			span.img {
				width:	190px;
				height:	190px;

				img {
					width:		130%;
					max-width:	130%;
				}
			}
		}
	}

	@media screen and (min-width: @tabletWidthP) and(max-width: @tabletWidthL) and (orientation: portrait) {
		> li {
			width:			47%;
			margin-right:	0;

			&:first-child {
				margin-left: 15px;
			}

			&:last-child {
				width:	96%;
				margin:	20px 2%;
			}

			&:nth-child(3n+2) {
				margin-right: 0;
			}
		}
	}

	@media screen and (min-width: @tabletWidthP) and(max-width: @tabletWidthL) and (orientation: landscape) {
		& {
			width: 100%
		}

		> li {
			width:	33%;
			margin:	0 2px 90px;

			&:nth-child(3n+2) {
				margin-right: 0;
				margin-left: 0;
			}
		}
	}
}