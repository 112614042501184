.catalog-product-view {
  	.share-section {
	  overflow: hidden;
	  clear: both;
	  .ttl {
		float: left;
		overflow: hidden;
		color: #093e2c;
		font-weight: 400;
		line-height: 35px;
		margin:0 10px 0 0;
	  }
	  .share-list {
		float: left;
		margin: 0 0 10px;
		padding: 0;
		list-style: none;
		li {
		  float: left;
		  margin:0 10px 0 0;
          list-style:none;
		  a {
			background: url(../images/share-icons.png) no-repeat;
			width: 35px;
			height: 35px;
			display: block;
			text-indent: -9999px;
			overflow: hidden;
			font-size: 0;
			line-height: 0;
			&:hover {
			  background-position: 0 -45px;
			}
			&.facebook {
			  background-position: -45px 0;
			  &:hover {
				background-position: -45px -45px;
			  }
			}
			&.pinterest {
			  background-position: -90px 0;
			  &:hover {
				background-position: -90px -45px;
			  }
			}
			&.google {
			  background-position: -135px 0;
			  &:hover {
				background-position: -135px -45px;
			  }
			}
			&.linkedin {
			  background-position: -180px 0;
			  &:hover {
				background-position: -180px -45px;
			  }
			}
		  }
		}
	  }
	}
	#breadcrumbs {
		padding: 70px 0 30px;
	}

	.product-type-switcher {
		margin: 20px 0 0;

		li {
			display:	inline-block;
			margin:		0 10px;

			&:first-child {
				margin-left: 0;
			}

			a {
				display:			block;
				text-align:			left;
				color:				@grey3F;
				text-decoration:	none;
				border-bottom:		solid 1px @greyD7;

				#font > #futura > .book(13px,@tabNavHeight);
				.box-sizing(border-box);
				.transition('color @{tabTransTime}s ease');
				.kerning(75);
				.size(100%,@tabNavHeight);

				&:hover {
					color: @greenLt;
				}
			}

			span {
				display:	inline-block;
				height:		@tabNavHeight;
				position:	relative;

				&:after {
					content:	'';
					background:	@greenLt;
					position:	absolute;
					bottom:		-1px;
					left:		0;
					opacity:	0;

					.size(0,2px);
					.transition('opacity @{tabTransTime}s ease, width 0s ease @{tabTransTime}s');
				}

				&:hover, &.active {
					&:after {
						width:		100%;
						opacity:	1;
						.transition('opacity @{tabTransTime}s ease, width @{tabTransTime}s ease');
					}
				}
			}
		}
	}
}

#product-info {
	max-width:	@maxWidth;
	margin:		0 auto;
	position:	relative;
}

#product-details {
	position:	relative;
	float:		right;

	.box-sizing(border-box);
}

#product-media {
	position:	relative;
	float:		left;

	.box-sizing(border-box);
}

#product-details {
	width: 50%;

	.product-code-id {
		display:	block;
		margin:		20px 0;
		color:		@greyBA;
		#font > #futura > .medium(15px,normal);
	}
	.info-holder {
	  overflow: hidden;
	  .product-code-id {
		float: left;
	  }
	  .promotion-msg {
		overflow: hidden;
		color: #093e2c;
		font-weight: 400;
		padding: 14px;
		display: block;
	  }
	}
	h1 {
		color:			@grey3F;
		text-transform:	uppercase;

		#font > #trajan > .regular(38px,38px);

		small {
			display:		block;
			width:			auto;
			padding:		19px 0 6px 0;
			margin-left:	3px;
			text-transform:	none;
			position:		relative;
			overflow:		hidden;
			color:			@grey3F;

			.kerning(75);
			#font > #futura > .book(13px,13px);

			a {
				color:			@grey3F;
				text-decoration:none;
				display:		inline-block;
				float: 			left;

				.transition('color 0.25s ease');

				&:first-child {
					margin-right:	18px;
					padding-right:	18px;
					border-right:	dotted 1px @yellow;

					&.active {
						&:after {
							left: -18px;
						}
					}
				}

				&:hover {
					color: @greenLt;
				}

				&.active {
					font-weight: 500;
					position: relative;

					&:hover {
						color: @grey3F;
						cursor: default;
					}

					&:after {
						content: '';
						.size(100%,2px);
						left: 0;
						position: absolute;
						bottom: -6px;
						border: none;
						background-color: @greenLt;
					}
				}
			}
		}
	}
}

.product-form {
	.availability {
		color:			@grey28;
		display:		block;
		margin-bottom:	1em;

		#font > #playfair > .regular(16px,normal);
	}
}

#product-group {
	margin-bottom: 1em;

	li {
		border-bottom: solid 1px @greyF2;
		padding: 15px 0;
		width: 100%;
		display: inline-table;
		color: @grey3F;

		#font > #futura > .medium(14px,21px);
		.kerning(15);

		> strong, > span, > div {
			display: table-cell;
		}

		.name {
			width: 50%;
			#font > #futura > .medium(14px,21px);
			.box-sizing(border-box);
		}

		> span, > div {
			width: 17%;
		}

		> div {
			text-align: right;
		}

		.savings {
			display: block;
			color: @redSavings;
			text-transform: uppercase;
			font-size: 12px;
		}
	}
}
.ui-tab {
  	> .available-other {
	  display: none;
	  &.show {
		display: block;
	  }
	}
	.available-other {
	  h2 {
		text-align:		center;
		padding:		0 0 5px;
		text-transform:	uppercase;
		letter-spacing:	1px;
		#font > #futura > .book(16px, 18px);
	  }

	  p {
		#font > #futura > .book(15px, 18px);

		a {
		  color: #85a99f;
		  text-decoration: none;
		}
	  }
	  .thumbs {
		z-index:	3;
		margin:		15px 0 30px;

		.clearfix();

		li {
		  .square(0);
		  border: 1px solid #e9e9e9;
		  cursor: pointer;
		  overflow: hidden;
		  margin: 10px 10px;
		  float: left;

		  .box-sizing(border-box);
		  .transition('border-color 0.25s ease');
		  .square(@mediaThumbWidth);

		  &:last-child {
			margin-right: 0;
		  }

		  &:hover {
			border-color: @grey7B;
		  }
		}

		img {
		  display:	block;
		  width:		auto;
		  height:		58px;
		  margin:		7px auto;
		}
	  }
	}
}
#product-media {
	width:			46%;
	margin-right:	4%;
	padding-bottom:	(@gutter + @mediaThumbWidth);

	&.single {
		padding-bottom: 0;

		.thumbs {
			li {
				.square(0);
				border: none;
			}
		}
	}

	.available-block {
		display: none;

		&.show {
			display: block;
		}

		h2 {
			margin:			20px 0;
			text-align:		center;
			padding:		0 0 5px;
			border-bottom:	1px solid  #005e20;
			text-transform:	uppercase;
			letter-spacing:	1px;

			#font > #futura > .book(16px, 18px);
		}

		p {
			#font > #futura > .book(15px, 18px);

			a {
				color: #85a99f;
				text-decoration: none;
			}
		}
	}

	figure {
		display:	none;
		width:		100%;
		padding:	0;
		overflow:	hidden;
		z-index:	2;

		.box-sizing(border-box);

		&.ready {
			&.active {
				display:	block;

				img {
					opacity: 1;
				}

				figcaption {
				}
			}

			figcaption {
				opacity: 1;
			}
		}

		img {
			display:	block;
			margin:		0 auto;
			opacity:	0;
			width:		auto;
			height:		80%;

			.transition('opacity 0.5s ease');
		}
	}

	figcaption {
		display:	block;
		width:		100%;
		text-align:	center;
		color:		@greyBA;
		opacity:	0;
		position:	relative;

		#font > #futura > .medium(15px,normal);
		.kerning(50);
		.transition('bottom 0.25s ease, opacity 0.25s ease');
	}

	.thumbs {
		z-index:	3;
		margin:		15px 0 30px;

		.clearfix();

		li {
			border: solid 1px @greyE9;
			cursor: pointer;
			overflow: hidden;
			margin: 10px 10px;
			float: left;

			.box-sizing(border-box);
			.transition('border-color 0.25s ease');
			.square(@mediaThumbWidth);

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				border-color: @grey7B;
			}
		}

		img {
			display:	block;
			width:		auto;
			height:		58px;
			margin:		7px auto;
		}
	}
}

#media-loader {
  width:16px;
	height: 11px;
	margin: 0 auto;
	z-index: 1;
	opacity: 1;
	position: absolute;
	top:		0;
	left:		0;

	.background-img('loading-share.gif',no-repeat,center,center);
	.size(@homeInstagramMax,@homeInstagramDim);
	.transition('opacity 0.25s ease, width 0s ease 0.25s, height 0s ease 0.25s');
}

#product-upsell {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		background-color: @tanLt;


		.size(100%,63%);
	}

	h2 {
		text-transform:	uppercase;
		color:			@green;
		position:		relative;
		z-index:		1;
		text-align:		center;
		padding:		35px 0 60px 0;


		.kerning(100);
		#font > #futura > .heavy(12px,normal);
	}

	.product-list {
		max-width: 1024px;
		margin: 0 auto;
		position: relative;
		z-index: 1;

		li {
			width: 33%;

			&.ready {
				img {
					position:	absolute;
					bottom:		0;
					left:		20%;
				}

				a:hover .quickBuy {
					left: 110px;
				}
			}
		}
	}
}

@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
	.catalog-product-view #breadcrumbs {
		padding: 23px 20px 0px;

		li:last-child {
			display: none;
		}
	}

	#product-upsell {
		&:after {
			background: none;
		}

		.product-list {
			max-width: 1024px;
			margin: 0 auto;
			position: relative;
			z-index: 1;

			li {
				text-align:		center;
				width:			100%;
				max-height:		304px;
				min-height:		304px;
				border-bottom:	1px solid #e9e9e9;
				border-right:	0;

				img {
					width: 160px;
				}

				&.ready img {
					left: 25%;
				}

				a {

					text-align:	center;
					.price {
						margin: 0;
					}
				}

				a strong {
					width:		100%;
					text-align:	center;
					margin:		0;
					min-height:	23px;
				}

				span.price {
					width:			100%;
					text-align:		center;
					margin-bottom:	0;
				}
			}
		}
	}

	#product-details {
		width:		100%;
		display:	block;
		float:		none;
		padding:	20px;

		h1 {
			#font > #trajan > .regular(22px, 29px);
		}

		.ui-add-cart .cart-msg {
			width:	57%;

			#font > #futura > .book(14px, 16px);
		}
	}

	#product-media {
		width:	100%;
		float:	none;
		display:block;

		figure {
			position: relative;
			display: 	none;

			&.active {
				display: block;
			}
		}

		.thumbs {
			width:			100%;
			position:		relative;
			text-align:	center;
			display:		inline-block;
			margin:			0;

		}

		figure img {
			width:		60%;
			margin-top:	30px;
		}

		.available-block.show {
			&.available-other {
				display: none;
			}
		}
	}

	#product-group {
		li {
			strong, span, div {
				display: inline-block;
			}

			.name {
				width: 85%;
			}

			> span {
				width:			15%;
				font-weight:	bold;
			}

			.ui-increment {
				width:		100%;
				padding:	12px;
				border:		1px solid #005e20;
				display:	block;
				margin:		15px 0 0 0;

				.ui-minus, .ui-plus {
					top: 13px;
				}

				.ui-minus {
					left:	15px;
				}

				.ui-plus {
					right: 13px;
				}

				.no-stock {
					width:		100%;
					text-align:	center;
					display:	block;
				}
			}
		}
	}

	.properties {
		margin: -20px 20px 20px;

		ul > li {
			background: #e1e6e4;
			margin:5px 0 0 0;

			h3 {
				margin:0;

				a {
					color:		#000;
					padding:	13px 16px;
					display:	block;
					position:	relative;

					#font#futura.book(13px);

					&:before {
						position:	absolute;
						right:		15px;
						top:		16px;
						display:	block;
						content:	"";

						.ssg-icon;
						.transition(all 0.2s);
					}
				}
			}

			.content {
				max-height:	0;
				overflow:	hidden;
				padding:	0;
				color:		transparent;

				.transition(all 0.2s);
				#font#futura.book(13px);
			}

			&.active {
				h3 a:before {
					.rotate(-180deg);
				}

				.content {
					color:		#555;
					max-height:	10000px;
					padding:	0 15px 15px 15px;
				}
			}
		}
	}
}

@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) {
	.catalog-product-view {
		main {
			margin: 20px 20px;
		}
	}

	#product-details {
		width: 60%;

		h1 {
			#font > #trajan > .regular(32px, 38px);
		}
	}

	#product-media {
		width: 35%;

		figure img {
			width: 100%;
		}
	}
}

@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: portrait) {
	#product-group {
		li {
			strong, span, div {
				display: inline-block;
			}

			.name {
				width: 85%;
			}

			> span {
				width:			auto;
				font-weight:	bold;
				float:			right;
			}

			.ui-increment {
				width:		25%;
				padding:	11px 12px 12px 11px;
				border:		1px solid #005e20;
				display:	block;
				margin:		15px 0 0 0;
				float:		right;

				.ui-minus, .ui-plus {
					top: 13px;
				}

				.ui-minus { left:	15px; }

				.ui-plus { right: 13px; }

				.no-stock {
					width:		100%;
					text-align:	center;
					display:	block;
				}
			}
		}
	}
}
