.blog-post-view{
    font-family: "futura-pt", sans-serif;
    .postWrapper{
        padding: 100px 0 50px;
        overflow: hidden;
        @media screen and (max-width: 980px){
            padding: 50px 0;
        }
        @media screen and (max-width: @iphoneWidthL){
            padding: 30px 0;
        }
    }
    .postTitle {
        margin-bottom: 20px;
        p, h1, h3 {
            font-size: 16px;
            font-weight: 900;
            line-height: 40px;
            color: @grey43;
            display: inline;
            text-transform: uppercase;
            @media screen and (max-width: @tabletContainerWidthG){
                font-size: 28px;
                line-height: 35px;
            }
            @media screen and (max-width: @iphoneWidthL){
                font-size: 25px;
                line-height: 32px;
            }
        }
        p{
            padding-right: 8px;
        }
    }
    .postContent{
        font-size: 16px;
        font-weight: 400;
        line-height:30px;
        color: @grey43;
        float: left;
        width: 800px;
        margin-right: 24px;
        @media screen and (max-width: @secondTabletWidth){
            width: 700px;
            font-size: 16px;
            line-height: 35px;
        }
        @media screen and (max-width: 980px){
            width: 550px;
            font-size: 16px;
            line-height: 28px;
        }
        @media screen and (max-width: @tabletContainerWidthG){
            width: 100%;
            float: none;
            font-size: 16px;
            line-height: 28px;
            margin: 0 0 40px 0;
        }
        @media screen and (max-width: @iphoneWidthL){
            font-size: 16px;
            line-height: 23px;
        }
        .post-sub-title{
            color: @green;
            font-size: 16px;
            font-weight: 600;
            line-height: 40px;
            margin-bottom: 25px;
            text-transform: uppercase;
            @media screen and (max-width: @secondTabletWidth){
                font-size: 16px;
            }
            @media screen and (max-width: @tabletContainerWidthG){
                font-size: 16px;
                line-height: 31px;
            }
            @media screen and (max-width: @tabletContainerWidthG){
                font-size: 16px;
                line-height: 26px;
            }
            @media screen and (max-width: @iphoneWidthL){
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 15px;
            }
        }
        .sources{
            font-size: 16px;
            line-height: 35px;
            @media screen and (max-width: @secondTabletWidth){
                font-size: 16px;
                line-height: 31px;
                margin-bottom: 15px;
            }
            @media screen and (max-width: 980px){
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 10px;
            }
            @media screen and (max-width: @tabletContainerWidthG){
                font-size: 16px;
                line-height: 22px;
            }
            &.title{
                font-weight: 500;
            }
            a{
                color:#434343;
            }
        }
    }
    .postBookmarks{
        float: left;
        width: 200px;
        background: #23684d;
        color: @text-color-first;
        border: 2px solid #5c8d7a;
        box-sizing: border-box;
        @media screen and (max-width: @secondTabletWidth){
            width: 175px;
        }
        @media screen and (max-width: 980px){
            width: 125px;
        }
        @media screen and (max-width: @tabletContainerWidthG){
            float: none;
        }
        .postBookmarks-title{
            font-size: 16px;
            text-transform: uppercase;
            text-align: center;
            padding: 25px 0;
            margin-bottom: 0;
            font-weight: 500;
            @media screen and (max-width: 980px){
                padding: 15px 0;
                font-size: 16px;
            }
        }
        .postBookmarks-box{
            overflow: hidden;
            a{
                float: left;
                width: 50%;
                box-sizing: border-box;
                border-top: 2px solid #5c8d7a;
                display: block;
                text-align: center;
                height: 85px;
                position: relative;
                &:nth-child(2n){
                    border-left: 2px solid #5c8d7a;
                }
                @media screen and (max-width: 980px){
                    height: 60px;
                }
                .img{
                    position: absolute;
                    content: '';
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                .fb{
                    width: 11px;
                    height: 23px;
                    background: url(../images/facility/icon-fb.png);
                }
                .tw{
                    width: 22px;
                    height: 17px;
                    background: url(../images/facility/icon-twiter.png);
                }
                .in{
                    width: 17px;
                    height: 17px;
                    background: url(../images/facility/icon-in.png);
                }
            }
        }
    }
    .commentWrapper{
        .commentWrapper-box {
            background: @greyF2;
            padding: 30px;
            margin-bottom: 30px;
            @media screen and (max-width: @iphoneWidthL){
                padding: 20px 20px 1px;
            }
        }
        .commentTitle,
        .legend{
            color: #093e2c;
            font-size: 16px;
            font-weight: 400;
            line-height: 40px;
            margin-bottom: 15px;
            @media screen and (max-width: @secondTabletWidth){
                font-size: 16px;
                line-height: 35px;
            }
            @media screen and (max-width: @tabletContainerWidthG){
                font-size: 16px;
                line-height: 26px;
            }
            @media screen and (max-width: @iphoneWidthL){
                font-size: 16px;
                line-height: 25px;
            }
        }
        .commentDetails{
            p{
                display: block;
                color: @grey63;
                .kerning(50);
                #font > #playfair > .italic(16px,normal);
                @media screen and (max-width: @tabletContainerWidthG){
                    margin-bottom: 10px;
                }
                @media screen and (max-width: @iphoneWidthL){
                    font-size: 14px;
                }
            }
        }
        .commentContent{
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 30px;
            @media screen and (max-width: @tabletContainerWidthG){
                font-size: 16px;
                line-height: 22px;
            }
            @media screen and (max-width: @iphoneWidthL){
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 20px;
            }
        }
        ul{
            list-style: none;
            li{
                margin-left: 0;
                label{
                    position: absolute;
                    top: 8px;
                    left: 10px;
                }
            }
            .field {
                position: relative;
                margin: 30px 0;
                @media screen and (max-width: @iphoneWidthL){
                    margin: 25px 0;
                }
            }
        }
        .field.hovered label {
            top: -25px;
            left: 0;
            @media screen and (max-width: @iphoneWidthL){
                top: -18px;
            }
        }
        input{
            height: 35px;
            padding: 0 15px;
            border: none;
            color: #3f4442;
            font: normal normal 500 14px/normal 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif;
            letter-spacing: 0.015em;
            -moz-border-radius: 2px 0 0 2px;
            -khtml-border-radius: 2px 0 0 2px;
            -webkit-border-radius: 2px 0 0 2px;
            border-radius: 2px 0 0 2px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            width: 100%;
        }
        textarea, select{
            width: 100%;
            border: none;
        }
        textarea{
            padding: 10px;
            box-sizing: border-box;
            resize: none;
        }
        .button-set{
            @media screen and (max-width: @iphoneWidthL){
                margin-bottom: 15px;
            }
        }
    }
}