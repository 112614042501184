body {
  font-family: "Garamond Premiere Pro","Futura PT", sans-serif;
  font-size: 17px;
}
.unstyled {
  &.clearfix {
    &.col2-set {
      .home-colls {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        list-style: none;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-line-pack: justify;
        align-content: space-between;
        li {
          margin: 0 auto;
          text-align: center;
          max-width: 485px;
          position: relative;
          margin: 0;
          a{
            strong[itemprop=name]{
              display: block;
              color: #053e2c;
              margin: 0 0 10px;
              font: 400 33px/38px 'Garamond Premier Pro It', 'Times New Roman', Georgia, serif;
              font-style: italic;
            }
          }
          &.first, &.second {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;
            padding: 20px;
            img {
              display: block;
              margin: 0 auto 10px;
              max-width: 100%;
              height: auto;
              position: relative;
              z-index: 0;
            }
          }
          &.first {
            background: #eeefec;
          }
          &.second {
            background: #f9f5ee;
          }
          .colls-bg-l,
          .colls-bg-r {
            text-decoration: none;
            display: block;
            width: 100%;
            text-decoration: none;
            color: inherit;
            &::before {
              width: 100px;
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              background: url(../images/img-leaf-l.png) 100% 50% no-repeat;
              -webkit-transition: all .3s ease;
              -o-transition: all .3s ease;
              transition: all .3s ease;
              z-index: 1;
            }
            &:hover {
              &::before {
                width: 150px;
                -webkit-transition: all .3s ease;
                -o-transition: all .3s ease;
                transition: all .3s ease;
              }
            }
          }
          .colls-bg-r {
            &:before {
              left: auto;
              right: 0;
              background: url(../images/img-leaf-r.png) 0 50% no-repeat;
            }
          }
          .descr {
            margin: 0 auto 10px;
            max-width: 250px;
            position: relative;
            z-index: 3;
            color: #000;
          }
        }
      }
    }
  }
}
@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
  .unstyled.clearfix.col2-set {
    .home-colls{
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      .first,.second{
        margin-top: 15px;
        p{
          font-size: 20px;
        }
        .ui-btn.green{
          font-size: 14px;
        }
      }
    }
  }
  .unstyled{
    &.clearfix{
      &.col3-set{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 480px;
        margin: 0 auto!important;
        li{
          margin:10px 0;
          p{
            font-size: 20px;
          }
          .ui-btn.green{
            font-size: 14px;
          }
        }
      }
    }
  }
}
.blockquote {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  overflow: hidden;
  background: url(../images/bg-blockquote.png) 50% 50% #053e2c;
  text-align: center;
  color: #fff;
  margin: 0 0 13px;
  position: relative;
  z-index: 2;
  min-height: 240px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  blockquote{
    padding: 50px 25px 15px;
  }
  q {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 20px;
    padding: 0 50px;
    text-align: center;
    font: 30px/35px 'Garamond Premier Pro It',sans-serif;
    color: #fff;
    &::before,&::after{
      font-size: 70px;
      display: inline-block;
      vertical-align: top;
    }
    &::before{
      margin: 7px 29px -10px -50px;
      float: left;
    }
    &::after{
      margin: 41px -50px -44px 23px;
      float: right;
    }
  }
  cite {
    font-size: 18px;
    display: block;
    color: #fff;
    margin: 0 auto;
    width: 80%;
    text-align: right;
  }
  &.style2 {
    q {
      font-size: 20px;
    }
  }
}
.col3-set {
  width: 100%;
  overflow: hidden;
  -moz-column-count: 3;
  -moz-column-gap: 14px;
  -webkit-column-count: 3;
  -webkit-column-gap: 14px;
  column-count: 3;
  column-gap: 14px;
  margin: 0 0 18px!important;
  text-align: center;
  color: #000;
  list-style: none;
  @media screen and (max-width: @iphoneWidthL) {
    column-count: 1;
    -moz-column-count: 1;
    -webkit-column-count: 1;
  }
  li {
    margin: 0;
    img {
      display: block;
      margin: 0 auto 30px;
      width: 100%;
      height: auto;
    }
    a {
      text-decoration: none;
      color: #000;
      &.colls-bg-l, &.colls-bg-r {
        text-decoration: none;
        display: block;
        width: 100%;
        text-decoration: none;
        color: inherit;
        &::before {
          width: 100px;
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          background: url(../images/img-leaf-l.png) 100% 50% no-repeat;
          -webkit-transition: all .3s ease;
          -o-transition: all .3s ease;
          transition: all .3s ease;
          z-index: 1;
        }
        &:hover {
          &::before {
            width: 150px;
            -webkit-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
          }
        }
      }
      &.colls-bg-r {
        &:before {
          left: auto;
          right: 0;
          background: url(../images/img-leaf-r.png) 0 50% no-repeat;
        }
      }
    }
    &.recipe,
    &.journal,
    &.story {
      width: 100%;
      overflow: hidden;
      background: #efdea3;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 18px;
      page-break-inside: avoid;
      position: relative;
    }
    &.journal {
      background: #fbd2cc;
    }
    &.story {
      background: #f9f5ee;
    }
    & strong[itemprop=name] {
      display: block;
      color: #053e2c;
      margin: 0 0 10px;
      font: 400 33px/38px 'Garamond Premier Pro It', 'Times New Roman', Georgia, serif;
      font-style: italic;
    }
  }
}

#home-main{
  .primary-col{
    width: 100%!important;
  }
  .picks-block {
    padding: 20px 40px;
    margin: 0 0 40px;
    background: #fff;
    float: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    -webkit-box-shadow: 0 0 26px 0 grey;
    box-shadow: 0 0 26px 0 grey;
    z-index: 1;
    .slick-next {
      right: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 14px 0 14px 15px;
      border-color: transparent transparent transparent #86898c;
      &:before {
        border-width: 10px 0 10px 11px;
        border-color: transparent transparent transparent #fff;
        left: -15px;
      }
    }
    .slick-prev {
      left: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 14px 15px 14px 0;
      border-color: transparent #86898c transparent transparent;
      &:before {
        border-width: 10px 11px 10px 0;
        border-color: transparent #fff transparent transparent;
        right: -15px;
      }
    }
    .slick-next:before, .slick-prev:before {
      border-style: solid;
      top: -10px;
      position: absolute;
      width: 0;
      height: 0;
      content: '';
    }
    .slick-next,.slick-prev {
      position: absolute;
      font-size: 0;
      line-height: 0;
      background: 0 0;
      top: 40%;
      cursor: pointer;
      margin: -25px 0 0;
    }
    h2 {
      font: 400 18px/23px 'Garamond Premiere Pro','Times New Roman',Georgia,serif;
      margin: 0 0 15px;
    }
    .picks-list{
      .item{
        img{
          display: block;
          width: 100%;
          max-width: 80%;
          height: auto;
          margin: 0 auto 10px;
        }
        a{
          text-decoration: none;
          color: #000;
          display: block;
          text-align: center;
        }
        .ratings {
          padding: 0 0 5px;
          .rating-box {
            width: 100px;
            margin: 0 auto;
            display: block;
            float: none;
            background: url(../images/rating-star.png) repeat-x;
            background-size: auto 30px;
            left: 0;
            top: 0;
            overflow: hidden;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  #search {
    padding: 25px 0 12px 22px;
    background-color: #fff;
    text-transform: capitalize;
    font-size:16px;
    border-bottom: 1px solid #eeefec;
  }
  input::-webkit-input-placeholder {
    color:grey!important;
  }
  input:-moz-placeholder {
    color:grey!important;
  }
  input::-moz-placeholder {
    color:grey!important;
  }
  input:-ms-input-placeholder {
    color:grey!important;
  }
  .unstyled.clearfix.col2-set .home-colls {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  #tea-finder select.none {
    font-size: 14px;
  }
  #tea-finder strong {
    font-size: 16px;
  }
  body.menu-active .wrapper, body.menu-active #footer, body.menu-active #header {
    margin-left: -270px;
  }
  #header #mobile-nav {
    right: -270px;
    width: 270px;
  }
  #header #mobile-nav > ul > li a {
    font-size: 18px;
  }
  #header #mobile-nav > ul > li ul li a {
    font: normal normal 400 18px/normal 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  }
  #header #mobile-nav .other-links a {
    font: normal normal 400 18px/normal 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  }
  #footer #bottomLinks a {
    font: normal normal 400 16px/normal 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  }
  #footer #newsletter-signup strong {
    font: italic normal 400 16px/normal 'Playfair Display', Times, 'Times New Roman', Georgia, serif;
  }
  #footer .copyright {
    font: italic normal 400 12px/normal 'Playfair Display', Times, 'Times New Roman', Georgia, serif;
  }
  #footer #newsletter-signup .inline-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #footer #newsletter-signup input {
    margin: 0;
  }
  #product-ratings .no-reviews {
    font-size: 16px;
  }
  #product-ratings p {
    font: normal normal 400 16px/21px 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  }
  #product-ratings aside .ui-btn.green {
    font-size: 16px;
    height: 45px;
    width: 100%;
  }
  .properties ul > li h3 a {
    font: normal normal 400 18px/normal 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  }
  .properties ul > li .content {
    font: normal normal 400 17px/normal 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  }
  #categoryList .heading p {
    font: normal normal 400 15px/24px 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  }
  #breadcrumbs li a {
    padding: 15px 5px 15px 5px;
    font: normal normal 400 9px/normal 'Playfair Display', Times, 'Times New Roman', Georgia, serif;
  }
  #breadcrumbs {
    padding: 15px 0 10px;
  }
  #breadcrumbs li a {
    padding: 15px 9px 15px 11px;
    font: normal normal 400 11px/normal 'Playfair Display', Times, 'Times New Roman', Georgia, serif;
  }
  #how-to-brew-steps > li {
    font: normal normal 400 16px/21px 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  }
  .how_make_holder .how_make_box_right,.how_make_holder .how_make_box_left {
    width: 100%;
  }
  .how_make_box p{
    font-size: 16px;
  }
  #recipe-list-items .ui-btn.green {
    width: 100%;
    display: block;
    font-size: 16px;
    line-height: 15px;
  }
  #recipe-list-items .ui-btn.green:hover{
    color:#fff;
  }
  #catalogSearch i.ssg-search {
    top: 8px;
    right: 20%;
  }
  #scrolling p{
    font-size: 16px;
  }
  #charity-subnav li a {
    height: 45px;
    font: normal normal 400 16px/60px 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  }
  #charity-achievements p{
    font-size: 16px;
  }
  html .cms-contact-us #cms-page .slide-text-style div#box-contact .box-right,
  html .cms-contact-us #cms-page .slide-text-style div#box-contact .box-left {
    width: 100%;
  }
  .cms-contact-us .block-form #contactForm button {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 751px){
  #nav > ul.unstyled > li > a {
    padding: 0 10px!important;
  }
}
#header .drop ul li {
  max-width: 170px;
}
#product-upsell{
  background-color: #f9f5ee!important;
  margin-bottom: 1rem;
  margin-bottom: 1rem;

}