#benefits-of-tea {
	> img {
		display: block;
		width: 100% !important;
		height: auto !important;
	}

	h1 {
		#font > #playfair > .regular(36px,36px);
		color: @grey28;
		text-align: center;
		margin-bottom: @gutter;
	}

	h2 {
		#font > #playfair > .regular(30px,30px);
		color: @grey28;
		margin-bottom: (@gutter / 2);
	}

	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		h2 {
			#font > #playfair > .regular(20px,30px);
		}
	}
}

#benefits-of-tea-intro {
	background:	@tanLt;
	position:	relative;
	padding:	40px 0;

	&:before, &:after {
		content:	'';
		position:	absolute;
		top:		0;
		z-index:	0;

		.size(300px,100%);
		.background-img('benefits-pattern.png',repeat-y,left,top);
		.background-size(300px,auto);
	}

	&:before {
		left:			50%;
		margin-left:	(@maxWidth / -2);
	}

	&:after {
		right:			50%;
		margin-right:	(@maxWidth / -2);
	}

	> div {
		width:		670px;
		border:		solid 20px #fff;
		padding:	50px 75px;
		background:	@tanLt;
		text-align:	center;
		margin:		0 auto;
		position:	relative;
		z-index:	1;

		.box-sizing(border-box);

		p {
			color: @grey3F;
			margin: 0;

			#font > #futura > .book(16px,24px);
			.kerning(15);
		}
	}

	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		& {
			padding: 0;
		}

		> div {
			width:	100%;
			padding:30px 20px;
		}

		&:before, &:after {
			display: none;
		}
	}

	@media screen and (min-width: @tabletWidthP)
	and (max-width: @tabletWidthL)
	and (orientation: portrait) {
		&:before, &:after {
			display: none;
		}
	}
}

#benefits-of-tea-tabs {
	max-width:	@maxWidth;
	margin:		0 auto;
	padding:	(@gutter * 2) 0 @gutter 0;

	.col {
		display:	inline-block;
		float:		right;
		width:		@recipePostColWidth;

		&:first-child {
			margin-left: @recipePostGutter;
		}
	}

	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		.benefits-item  {
			margin:		20px 0;
			padding:	20px 0;
			text-align:	center;
			border-bottom: 1px solid #fff6f1;

			&:last-child {
				border-bottom: 0;
			}

			#font > #futura > .book(16px,20px);
			.kerning(15);

			h2 {
				#font > #futura > .book(24px,24px);
				.kerning(15);
			}

			h3 {
				display: none;
			}
		}

		.col {
			width:		90%;
			display:	block;
			float:		none;
			margin:		0 5%;
			text-align:	center;

			&:first-child {
				margin-bottom:	30px;
				margin-left:	5%;
			}
		}
	}

	@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) {
		.ui-tabs {
			.ui-tabnav {
				margin: 0 15px;
			}
		}

		.col {
			width:		470px;
			margin-left:20px;

			&:first-child {
				margin-left: 40px;
			}
		}
	}

	@media screen and (min-width: @tabletWidthP)
	and (max-width: @tabletWidthL)
	and (orientation: portrait) {
		.ui-tabs {
			.ui-tabnav {
				width:	20%;
				float:	left;
				margin:	0 15px;

				li {
					display:	block;
					width:		100%;
					height:		auto;
					padding:	10px 0;

					span {
						height: auto;
					}

					a {
						#font > #futura > .book(13px, 17px);
					}
				}
			}

			.ui-tab {
				padding: 15px 15px 15px 0;
			}

			.ui-tab-container {
				width: 70%;
				float: right;
			}
		}

		.col {
			width:		100%;

			img {
				width:	100%;
				height:	auto;
				margin:	30px 0 0;
			}

			&:first-child {
				margin-left: 0;
			}
		}
	}
}