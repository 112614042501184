#footer {
    overflow: hidden;
	.size(100%,@footerHeight);
	background-color: @green;
}

#newsletter-signup {
	strong {
		display:	block;
		width:		100%;
		text-align:	center;
		color:		#fff;
		padding:	40px 0 15px 0;

		#font > #playfair > .regular(14px,14px);
		.kerning(50);
	}

	.inline-form {
		width:		335px;
		display:	block;
		margin:		0 auto 30px auto;

		.ui-input {
			width:	225px;
		}
	}
}

#footer-social {
	max-width: 100%;
	text-align: center;
	li {
		display: inline;
	}
	a {
		margin: 0 5px;
		display: inline-block;
		.square(35px);
		overflow: hidden;
		text-indent: -9999px;
		position: relative;
		text-decoration: none;
		background-color: #fff;
		color: @green;
		.border-radius(100%);
		&:hover {
			i {
				.transform('scale3d(0.85,0.85,0.85)');
			}
		}
		i {
			position: absolute;
			top: 0;
			left: 0;
			text-indent: 0;
			font-size: 25px;
			line-height: 35px;
			.square(35px);
			text-align: center;
			display: block;
			.transform-origin(center,center);
			.transform('scale3d(1,1,1)');
			.transition('all 0.25s ease');
		}
	}
}
#bottomLinks {
	max-width: @maxWidth;
	margin: 10px auto 0 auto;
	position: relative;
	.memberships {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		li {
			display: inline-block;
			float: left;
			margin-right: 5px;
			position: relative;
			&.uktc {
				top: 7px;
				.size(125px,25px);
				.background-img('uktc-logo.png');
				.background-size(125px,auto);
			}
			&.etp {
				top: 7px;
				opacity: 0.6;
				.size(60px,20px);
				.background-img('etp-logo.png');
				.background-size(60px,auto);
			}
			&.iso {
				opacity: 0.6;
				.size(50px,35px);
				.background-img('isoqar-logo.png');
				.background-size(50px,auto);
			}
			a {
				display: block;
				.square(100%);
				overflow: hidden;
				text-indent: -9999px;
			}
		}
	}
	.mainInfo {
		width:			100%;
		position:		absolute;
		top:			12px;
		left:			0;
		z-index:		1;
		text-transform:	uppercase;
		text-align:		center;


		#font > #futura > .book(11px,normal);
		.kerning(100);

		li {
			display: inline;
		}

		a {
			display: inline-block;
			padding: 0 5px;
			color: #fff;
			text-decoration: none;
		}
	}
	.copyright {
		position: absolute;
		top: 12px;
		right: 0;
		z-index: 0;
		#font > #playfair > .italic(11px,normal);
		.kerning(25);
		color: #fff;
	}
}

@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
	#pattern {
		content: " ";
		height: 89px;
		display: block;
		z-index: 3;
		width: 100%;
		left: 0;
		.background-size(340px,89px);
		.background-img("pattern.png",repeat-x,center,bottom);
	}

	#footer{
		max-width:	100%;
		background:	#093e2c;
		position:	relative;
		overflow:	hidden;
		z-index:	2;
		text-align:	center;
		color:		#fff;
		max-height:	411px;
		height:		100%;

		#newsletter-signup {
			strong{
				width: 			100%;
				margin:			0 auto;
				padding: 		20px 14px;
				text-indent:	0;
				text-align:		center;
				display:		block;
				font-size:		12px;

				#font#playfair.italic(11px);
			}

			.inline-form {
				width:			100%;
				padding:		15px 0;
				position:		relative;
				border-top:		1px solid #1c4637;
				border-bottom:	1px solid #1c4637;
				margin:			0;
			}

			input {
				border:			none;
				padding:		5px 10px;
				width:			125px;
				height:			28px;
				margin:			0 0 0 20%;
				color:			#000;
				outline:		0;
				display:		inline-block;
				vertical-align:	top;

				#font#futura.medium(12px);
				.border-radius(0);

				&::-webkit-input-placeholder {
					color: #000;
				}
				&:-moz-placeholder {
					color: #000;
				}
				&::-moz-placeholder {
					color: #000;
				}
				&:-ms-input-placeholder {
					color: #000;
				}
			}

			button {
				margin:			0 0 0 -4px;
				outline:		0;
				position:		relative;
				vertical-align:	top;
				display:		inline-block;
				border: 		none;
				background:		#d7d7d7;
				color:			#093e2c;
				padding:		7px;
				height:			28px;
				text-transform:	uppercase;


				#font#futura.book(10px);
			}
		}

		.copyright {
			#font#playfair.italic(8px);
		}

		#footer-social {
			background:	#063021;
			margin:		0;
			list-style:	none;
			padding:	15px 0;

			li {
				display: inline-block;
			}

			a {
				text-indent:	-999px;
				display:		inline-block;
				position:		relative;
				color:			#063021;
				text-decoration:none;
				width:			30px;
				height:			30px;
				margin:			5px;
				background:		#fff;
				.border-radius(30px);
				line-height:	30px;

				i {
					text-indent:0;
					font-size:	20px;
					top:		-1px;
					left:		-2px;

					&.fa-facebook { }
				}
			}
		}
		#bottomLinks {
			width:			100%;
			height:		100%;
			background:	#042519;
			position:	relative;
			margin:		0;

			a {
				color:			#fff;
				text-transform:	uppercase;

				#font#futura.book(11px);
			}

			.mainInfo {
				position: relative;

				li {
					width:	100%;
					display:block;
					float:	none;
				}
			}

			.copyright {
				display:	block;
				position:	relative;
				width:		100%;
				margin:		20px 0;
			}

			.memberships {
				position:	relative;
				padding:	20px 0;
				margin:		0;

				li{
					display: inline-block;
					float:	none;

					a {
						display: inline-block;
						overflow: hidden;
						text-indent: -9999px;
					}
				}

				li.uktc {
					top:	7px;
					width:	125px;
					height:	25px;
					margin:	3px;

					.background-size(105px,auto);
					.background-img("uktc-logo.png",no-repeat,left,center);
				}

				li.etp {
					top:	7px;
					width:	60px;
					height:	20px;
					margin:	3px;

					.background-size(50px,auto);
					.background-img("etp-logo.png",no-repeat,left,center);
				}

				li.iso {
					width:		50px;
					height:		35px;
					margin:		3px;
					position:	relative;
					top:		11px;

					.background-size(40px,auto);
					.background-img("isoqar-logo.png",no-repeat,left,center);
				}
			}
		}
	}
}

@media screen and (min-width: @iphoneWidthL) and (max-width: @phoneWidthL) {
	#footer {
		height: auto;

		#newsletter-signup {
			input {
				margin: 0 0 0 35%;
			}
		}
	}
}