.art-tea-page {

    #cms-page .slide-wrapper .tea-list {

        li {
            float:  left;
            width:  22%;
            margin: 0 2%;
            position: relative;
            .box-sizing(border-box);

            &.first {
                margin-left: 0;
            }

            &.last {
                margin-right: 0;
            }

            * {
                color:     @green;
            }

            img {
                position: relative;
                top:0;
                left:0;
                right: 0;
                z-index: 0;
            }

        }

        .tea-list-small-wrap {
            position: absolute;
            top:21%;
            left:0;
            right: 0;
            z-index: 1;
            .box-sizing(border-box);

            span {
                font-size: 18px;
                line-height: 25px;
            }

        }

        h5 {
           font: normal normal 500 16px/normal @playFairStack;
		  // #font > #playfair > .heavy(16px,normal);
            text-transform: uppercase;
            margin-bottom: 19px;
        }

    }

}