@media screen and (max-width: @tabletWidthL) {

    .culture-page #cms-page {

        > section {
            width: 		100%;
            padding: 	0;
        }

        .slide-wrapper .slide-text > div {
            padding: 0 10%;
        }

        .culture-block {

            ul li {
                padding: 30px 10%;
            }

            &.second {

                li {
                    padding: 30px 10%;
                }

                span {
                    font-size: 	32px;
                }

            }

        }

        .second-wrapper {
            width: 		96%;
            padding: 	0 2%;
        }

       .culture-list {

           li {

               > span, .subimg-wrapper {
                   padding:         7% 2% 0;
                   font-size:       15px;
                   line-height:     normal;
               }

           }

           &.third {

               ul.second li {
                   padding: 25px 0;
               }

               li { padding: 25px 0; }

           }

       }

    }

}

@media screen and (max-width: @tabletWidthP) {

    .culture-page #cms-page {

        .culture-block {

            &.second span {
                font-size: 	25px;
            }

            span {
                font-size: 	    17px;
                line-height:    normal;
            }

        }

        .culture-list {

            .subimg-wrapper span {
                background-size: 50%;
            }

            li {

                > span, .subimg-wrapper {
                    font-size:  11px;
                }

            }

            &.third {

                ul.second li {
                    padding: 15px 0;
                }

                li {
                    padding: 15px 0;

                    img {
                        width:  50%;
                        margin: 0 auto;
                    }

                }

            }

        }

    }

}

@media screen and (max-width: @tabletContainerWidthG) {
    .culture-page #cms-page ul.child-ul li span {
        font-size: 18px;
        line-height: 25px;
    }
}

@media screen and (max-width: @mobileWidth) {

    .culture-page #cms-page {

        .culture-block {

            &.second  li span {
                font-size: 	20px;
            }

            span {
                font-size: 	15px;
            }

        }

        .culture-list {

            li {
                width: 100%;

                > span, .subimg-wrapper {
                    font-size:  13px;
                }

            }

            &.third li img {
                width:      50%;
                margin:     0 auto;
            }

        }


    }

}

@media screen and (max-width: @secondMobileWidth) {

    .culture-page #cms-page {

        .culture-block {

            &.second  li span {
                font-size: 	15px;
            }

            span {
                font-size: 	13px;
            }

        }

    }

}
