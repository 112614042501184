#cms-page {
	padding-top:100px;
	color:		@grey28;
	#font > #futura > .book(16px,24px);

	> header, > section {
		max-width:	@maxWidth;
		margin:		0 auto;
	}

	> header {
		padding-bottom:	@gutter;
		text-align:		center;
	}

	> section {
		margin-bottom: 50px;
	}

	.h1{
		font-family: "Garamond Premiere Pro", sans-serif;
	}

	h1 {
		#font > #trajan > .regular(40px);
		text-transform: uppercase;
		margin-bottom: 1em;
	}

	h2 {
		#font > #playfair > .regular(30px);
		margin-bottom: 1em;
	}

	h3 {
		#font > #playfair > .regular(24px);
		margin-bottom: 1em;
	}

	h4 {
		#font > #futura > .medium(20px);
		margin-bottom: 1em;
	}

	h5 {
		#font > #futura > .medium(16px);
		margin-bottom: 1em;
	}

	a {
		text-decoration: none;
		font-weight: 500;
		color: @greenLt;

		.transition('color 0.25s ease');

		&:hover {
			color: @green;
		}
	}

	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		& {
			padding-top: 45px;
			color:		@grey28;

			#font > #futura > .book(14px,21px);
		}

		> header {
			#font > #trajan > .regular(29px);
			margin-bottom:	0;
			text-align:		left;
			padding-bottom:	0;
		}

		> section {
			width:		96%;
			padding:	0 2%;
		}

		h1 {
			#font > #trajan > .regular(21px);
			text-transform:	uppercase;
			padding-left:	4%;
			margin-bottom:	1em;
		}

		h2 {
			#font > #playfair > .regular(19px);
			margin-bottom: 1em;
		}

		h3 {
			#font > #playfair > .regular(24px);
			margin-bottom: 1em;
		}

		h4 {
			#font > #futura > .medium(17px);
			margin-bottom: 1em;
		}

		h5 {
			#font > #futura > .medium(16px);
			margin-bottom: 1em;
		}
	}

	@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) {
		> section {
			width:	 96%;
			padding: 0 2%;
		}
	}
}

