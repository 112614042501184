@media screen and (max-width: @desktopFirstWidth) {

	.art-tea-page #cms-page .slide-wrapper {

		.tea-list {

			.tea-list-small-wrap {
				top: 15%;

				span {
					font-size: 11px;
					line-height: normal;
				}

			}

			h5 {
				font-size: 13px;
				margin-bottom: 5px;
			}

		}

	}

}


@media screen and (max-width: @tabletContainerWidthP) {

	.art-tea-page #cms-page .slide-wrapper {

		.tea-list {

			.tea-list-small-wrap {
				padding: 0 5px;
			}

			h5 {
				font-size: 10px;
			}


		}

	}

}

@media screen and (max-width: @mobileWidth) {

	.art-tea-page #cms-page .slide-wrapper .tea-list .tea-list-small-wrap {
		top: 25%;

		span {
			display: none;
		}

	}

}

