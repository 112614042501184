// Paths
@imgPathPrefix:		'../images/';
@fontPathPrefix:	'../fonts/';

@font-face {
    font-family: 'begumw03-boldregular';
    src: url('../fonts/begum/begum_w03_bold.eot');
    src: url('../fonts/begum/begum_w03_bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/begum/begum_w03_bold.woff2') format('woff2'),
    url('../fonts/begum/begum_w03_bold.woff') format('woff'),
    url('../fonts/begum/begum_w03_bold.svg#begumw03-boldregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'begumw03-lightregular';
    src: url('../fonts/begum/begum_w03_light.eot');
    src: url('../fonts/begum/begum_w03_light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/begum/begum_w03_light.woff2') format('woff2'),
    url('../fonts/begum/begum_w03_light.woff') format('woff'),
    url('../fonts/begum/begum_w03_light.svg#begumw03-lightregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'begumw03-mediumregular';
    src: url('../fonts/begum/begum_w03_medium.eot');
    src: url('../fonts/begum/begum_w03_medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/begum/begum_w03_medium.woff2') format('woff2'),
    url('../fonts/begum/begum_w03_medium.woff') format('woff'),
    url('../fonts/begum/begum_w03_medium.svg#begumw03-mediumregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'begumw03-regularregular';
    src: url('../fonts/begum/begum_w03_regular.eot');
    src: url('../fonts/begum/begum_w03_regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/begum/begum_w03_regular.woff2') format('woff2'),
    url('../fonts/begum/begum_w03_regular.woff') format('woff'),
    url('../fonts/begum/begum_w03_regular.svg#begumw03-regularregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'begumw03-semiboldregular';
    src: url('../fonts/begum/begum_w03_semibold.eot');
    src: url('../fonts/begum/begum_w03_semibold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/begum/begum_w03_semibold.woff2') format('woff2'),
    url('../fonts/begum/begum_w03_semibold.woff') format('woff'),
    url('../fonts/begum/begum_w03_semibold.svg#begumw03-semiboldregular') format('svg');
    font-weight: normal;
    font-style: normal;

}


// Typography
@baseStack:			Arial, 'Helvetica Neue', Helvetica, sans-serif;
@baseWeight:		normal;
@boldWeight:		bold;
@baseFontSize:		18px;
@baseLineHeight:	normal;
@futuraStack:		'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif;
@trajanStack:		'trajan-pro-3', Times, 'Times New Roman', Georgia, serif;
@playFairStack:		'Playfair Display', Times, 'Times New Roman', Georgia, serif;

@begumBoldReg:          'begumw03-boldregular', Times, 'Times New Roman', Georgia, serif;
@begumLightReg:         'begumw03-lightregular', Times, 'Times New Roman', Georgia, serif;
@begumMedReg:           'begumw03-mediumregular', Times, 'Times New Roman', Georgia, serif;
@begumReg:              'begumw03-regularregular', Times, 'Times New Roman', Georgia, serif;
@begumSemiBold:         'begumw03-semiboldregular', Times, 'Times New Roman', Georgia, serif;
// Colors
@green:				#093e2c;
@greenDk:			#152b23;
@greenLt:			#005e20;
@greenMsg:			#ccdfd2;
@greenELT:			#e1e6e4;
@grey28:			#282828;
@grey3F:			#3f4442;
@greyF2:			#f2f2f2;
@greyD0:			#d0d0d0;
@grey43:			#434343;
@greyD7:			#d7d7d7;
@greyE9:			#e9e9e9;
@grey7B:			#7b7b7b;
@grey5C:			#5c5c5c;
@greyBA:			#bababa;
@grey93:			#939393;
@grey63:			#636363;
@greyDF:			#dfdfdf;
@greyC5:			#c5c7c6;
@greyB8:			#b8b8b8;
@greyF9:			#f9f5ee;
@grey89:			#898989;
@greyC1:			#c1c1c1;
@greyCC:			#cccccc;
@grey98:			#989898;
@greyE5:			#e5e5e5;
@grey80:			#808080;
@brown:				#363434;
@orangeFC:			#fc9a00;
@orangeE2:			#e28c06;
@orangeEE:			#ee8306;
@orangeD8:			#d87c0a;
@orangeFF:			#ff931f;
@orangeFF4:			#ff4800;
@tanLt:				#f9f5ee;
@tanMd:				#ebe5dc;
@tanDk:				#a69782;
@logoWhite:			#fffef0;
@yellow:			#ecc768;
@redMsg:			#f69679;
@redError:			#f69679;
@redSavings:		#dc3652;
@charityGreen:		#44871c;
@charityRed:		#ff0000;
@charityOrange:		#ff7d27;
@charityYellow:		#ffd200;
@charityBlue:		#2e90ff;
@charityRedDk:		#9e0b0f;
@charityBlueDk:		#003471;

@list-bg-first:     #e6efef;
@list-bg-second:    #efe6ec;
@list-bg-third:     #e1dbfa;
@list-bg-fourth:    #e4f4e1;
@block-bg-first:    #e2ecf2;
@block-bg-second:   #58241e;
@block-bg-third:    #97a9a3;
@block-bg-fourth:   #f8fbec;
@block-bg-fifth:    #ecfcfb;
@block-bg-sixth:    #fdf4ff;
@block-bg-seventh:  #e4fce6;
@block-bg-eighth:   #fce4e4;
@block-bg-ninth:    #e4f9fc;
@block-bg-tenth:    #fcf6e4;
@block-bg-eleventh: #fce4fa;
@block-bg-twelfth:  #f4fce4;
@block-bg-thirteenth:  #e4ebfc;
@block-bg-fourteenth:  #fce8e4;

@text-color-first:      #fff;
@text-color-second:     #6d6e71;
