//.laboratory-page {
//
//    #cms-page {
//
//        h2 {
//            color:      @green;
//            margin:     -5px 0 3px 0;
//            #font > #playfair > .heavy(45px,normal);
//        }
//
//        .laboratory-list  {
//
//            .second h4 {
//                text-transform:     inherit;
//                margin:             7px 0 32px 0;
//                #font > #playfair > .heavy(29px,normal);
//            }
//
//            h4 {
//                margin-bottom:      33px;
//                text-transform:     uppercase;
//                #font > #playfair > .heavy(21px,normal);
//            }
//
//        }
//
//    }
//
//    .our-experts-block {
//        padding:    55px 0 30px;
//        display:    inline-block;
//        width:      100%;
//
//        .img-block {
//            width:  40%;
//            float:  left;
//
//            img {
//                width:  100%;
//            }
//
//        }
//
//        .text-block {
//            width:      55%;
//            float:      left;
//            padding:    0 3% 0 2%;
//
//            p {
//                font-size:      22px;
//                line-height:    30px;
//                padding:        0 10px;
//            }
//
//        }
//
//
//    }
//
//    .laboratory-block  li {
//        padding: 26px 5% 34px;
//        width: 100%;
//    }
//
//    .laboratory-list {
//
//        .icon-block {
//            margin:         39px 0 0 0;
//            display:        block;
//            text-align:     center;
//        }
//
//        > ul {
//            display: inline-block;
//            width: 100%;
//
//            > li {
//                float:          left;
//                width:          33%;
//                text-align:     center;
//                margin:         0;
//
//                &.aside-li {
//                    margin:         60px 0 0 0;
//
//                    p {
//                        width:          83%;
//                        margin:         0 auto;
//                        font-size:      24px;
//                        line-height:    30px;
//                    }
//
//                }
//
//                * {
//                    color: @text-color-second;
//                }
//
//                &.second {
//
//                    * {
//                        #font > #playfair > .heavy(29px,40px);
//                    }
//
//                }
//
//                li {
//                    margin:0;
//                }
//
//            }
//
//        }
//
//    }
//
//}

.cms-laboratory {
    .slide-wrapper {
        .content {
            position: absolute;
            bottom: 8%;
            left: 50%;
            transform: translate(-50%, 0);
            @media screen and (max-width: 980px) {
                position: relative;
                left: 0;
                transform: translate(0, 0);
				width: 100%;
				box-sizing: content-box!important;
            }
        }
        .content-second {
            position: absolute;
            bottom: 8%;
            left: 50%;
            transform: translate(-50%, 0);
            @media screen and (max-width: 980px) {
                position: relative;
                left: 0;
                transform: translate(0, 0);
            }
        }
    }
}

.cms-laboratory {
    .main-page-title {
		padding: 2%;
		text-align: center;
		overflow: hidden;
		position: relative;
		top: 0;
		right: 0;
		width: 96%;
		margin: 0 auto;
		background: rgba(0,0,0,.68);
		bottom: 0;
        @media screen and (max-width: @tabletContainerWidthG) {
            //padding: 20px 0 0;
        }
        @media screen and (max-width: 980px) {
            text-align: left;
            position: relative;
            left: 0;
            //padding-left: 0;
        }
        .lab-title {
			color: #feffff;
			font-size: 30px;
			font-weight: 400;
			line-height: 34px;
			text-transform: uppercase;
			position: relative;
			margin-bottom: 0;
			padding-bottom: 30px;
			width: 100%;
			float: right;
			box-sizing: content-box;
            @media screen and (max-width: @iphoneWidthL) {
                font-size: 30px;
                line-height: 30px;
            }
            @media screen and (max-width: 980px) {
               // padding-left: 60px;
                //float: none;
				text-align: center;
            }
            @media screen and (max-width: @iphoneWidthL) {
                padding-left: 0;
            }
           // &:before {
             //   content: url(../images/facility/dots.png);
             //   position: absolute;
             //   top: -4px;
             //   right: 336px;
             ///   width: 39px;
             //   height: 11px;
              //  @media screen and (max-width: 980px) {
              //      left: 0;
              //  }
              //  @media screen and (max-width: @iphoneWidthL) {
              //      display: none;
             //   }
            //}
            &:after {
				content: '';
				bottom: 13px;
				right: 0;
				height: 5px;
				background: url(../images/facility/line.jpg) 50% 50%;
				background-size: cover;
				width: 74%;
				text-align: center;
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
                @media screen and (max-width: 980px) {
					left: 0;
					right: 0;
					margin: 0 auto;
					
                }
                @media screen and (max-width: @iphoneWidthL) {
					left: 0;
					right: 0;
					margin: 0 auto;
                }
            }
        }
        .lab-text {
            width: 100%;
            color: #fff;
            font-size: 16px;
            font-weight: 300;
            line-height: 28px;
            float: left;
            @media screen and (max-width: 980px) {
                width: 100%;
                float: none;
                //padding-left: 60px;
            }
            @media screen and (max-width: @iphoneWidthL) {
                padding-left: 0;
            }
        }
    }

    .second-page-title{
        color: #fff;
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
        width: 100%;
        //padding-left: 60px;
        position: relative;

        @media screen and (max-width: 980px) {
            width: 100%;
        }

        @media screen and (max-width: @iphoneWidthL) {
            padding-left: 0;
        }

        //&:before{
         //   content: url(../images/facility/dots.png);
        //    position: absolute;
        //    top: 1px;
        //    left: 0;
        //    width: 39px;
       //     height: 11px;
        //    @media screen and (max-width: 980px) {
         //       left: 0;
        //        top: 0;
       //     }
          //  @media screen and (max-width: @iphoneWidthL) {
        //        display: none;
         //   }
        //}
    }
	.cms-laboratory-content{
		border: 1px solid #fff;
		padding: 1%;
		width: 98%;
	}
}