.checkout-onepage-index {
	background-color: @tanLt;

	#cart-order-totals {
		// padding 5px
		padding:		@gutter 0;
		border-top:		solid 1px @grey3F;
		border-bottom:	solid 1px @grey3F;
		margin:			30px 0;

		li {
			// margin-bottom: (@gutter / 2);
			// &:last-child {
			// 	margin-bottom: 0;
			// }
			margin-bottom: 0;

			&.grand-total {
				display: none;
			}
		}
	}

	.col-right {
		> div {
			padding: 60px 0;

			> aside {
				background:	#fff;
				padding:	@gutter;
				color:	@grey3F;

				h2 {
					#font > #playfair > .regular(16px,16px);

					padding-bottom:	15px;
					margin-bottom:	30px;
					border-bottom:	solid 1px @grey3F;
				}
			}
		}
	}

	#checkout-cart-items {
		a {
			display:			block;
			position:			relative;
			padding-left:		90px;
			padding-top:		5px;
			min-height:			85px;
			margin-bottom:		15px;
			text-decoration:	none;
			color:				@grey3F;

			#font > #futura > .book(15px,18px);
			.kerning(35);
			.transition('color 0.25s ease');

			&:hover {
				color: @greenLt;
			}

			> strong {
				display:		block;
				margin-bottom:	10px;

				#font > #futura > .heavy(15px,18px);
			}
		}

		img {
			position:	absolute;
			top:		0;
			left:		0;
			display:	block;
		}
	}

	#checkout-cart-footer {
		background:	@grey98;
		color:		#fff;
		padding:	@gutter;

		#font > #futura > .book(15px,20px);
		.kerning(30);

		p:last-child {
			margin-bottom: 0;
		}

		strong {
			text-transform:	uppercase;
			display:		block;
			margin-bottom:	5px;

			#font > #futura > .heavy(14px,20px);
			.kerning(100);
		}

		a {
			display:			inline-block;
			margin-bottom:		1em;
			text-decoration:	none;
			color:				#fff;
			cursor:				text;

			#font > #futura > .heavy(20px,24px);
			.kerning(100);
		}
	}

	#checkoutSteps {
		> li {
			padding:		20px @gutter;
			margin-bottom:	(@gutter / 2);
			background:		@greyE5;
			color:			@grey3F;

			&:last-child {
				margin-bottom: 0;
			}

			&.allow:not(.active) {
				cursor: pointer;

				.transition('background-color 0.25s ease');

				&:hover {
					background-color: @greyF2;
				}
			}

			&.active {
				background:	#fff;
				padding:	@gutter;

				h2 {
					padding-bottom:	15px;
					margin-bottom:	30px;
					border-bottom:	solid 1px @grey3F;
				}
			}

			h2 {
				#font > #playfair > .regular(16px,16px);
			}
		}

		.input-box {
			position: relative;

			.validation-advice {
				text-transform:	uppercase;
				text-decoration:none;
				color:			@redMsg;
				display:		block;
				margin:			0;
				padding:		3px 0;

				.kerning(30);
				#font > #futura > .book(11px,11px);
				.backface-visibility(hidden);
			}
		}

		.col2-set {
			width: 100%;
			position: relative;

			.clearfix();

			.col-1, .col-2 {
				width: 50%;
				display: inline-block;
				float: right;
				.box-sizing(border-box);
			}

			.col-1 {
				padding-left: @gutter;
			}

			.col-2 {
				padding-right: @gutter;

				&:after {
					content: '';
					position: absolute;
					left: 50%;
					top: 0;
					.size(1px,100%);
					background-color: @greyF2;
				}
			}
		}

		p {
			&:empty {
				display: none;
			}
			&.note {
				#font > #futura > .book(14px,19px);
				.kerning(30);
				color: @grey3F;
			}
		}

		h3, dt {
			#font > #futura > .heavy(14px,14px);
			text-transform: uppercase;
			.kerning(30);
			margin-bottom: (@gutter / 2);
		}

		h4 {
			#font > #futura > .book(14px,14px);
			text-transform: uppercase;
			.kerning(30);
			margin-bottom: (@gutter / 5);
			color: @greenLt;
		}

		h5 {
			#font > #futura > .heavy(13px,13px);
			text-transform: uppercase;
			.kerning(30);
			margin-bottom: (@gutter / 5);
		}

		a {
			&.f-password,
			&.cvv-what-is-this {
				display: inline-block;
				#font > #futura > .book(11px,11px);
				text-transform: uppercase;
				text-decoration: none;
				.kerning(30);
				color: @grey3F;
				.transition('color 0.25s ease');
				&:hover {
					color: @greenLt;
				}
			}
		}

		.authcim_methodcode {
			display: inline-block;
			#font > #futura > .book(11px,11px);
			text-transform: uppercase;
			.kerning(30);
			color: @grey3F;
			a {
				color: @grey3F;
				text-decoration: underline;
				.transition('color 0.25s ease');
				&:hover {
					color: @greenLt;
				}
			}
		}

		.form-list {
			margin: 0;
			li {
				margin-bottom: 10px;
				&.fields {
					width: 100%;
					.clearfix();

					.field {
						width: 50%;
						display: inline-block;
						float: left;
						.box-sizing(border-box);
						&:first-child {
							padding-right: (@gutter / 2);
						}
						&:last-child {
							padding-left: (@gutter / 2);
						}
						&:first-child:last-child {
							padding-left: 0;
						}
					}
				}
			}
		}

		label {
			#font > #futura > .book(12px,12px);
			text-transform:	uppercase;

			.kerning(30);

			em {
				display: inline-block;
				font-style: normal;
				margin-right: 2px;
			}
		}

		#checkout-step-shipping_method, #checkout-step-payment {
			label {
				margin-top:		-15px;
				display:				block;
				margin-left:		19px;
			}
		}

		#checkout-step-payment li {
			label {
				margin-top:		0;
				margin-left:		0;
				display:				block;
			}
		}

		input {
			&[type="text"],
			&[type="password"] {

				padding:			0 15px;
				border: 				none;
				background:		none;
				background-color: @greyF2;
				color: @grey3F;
				margin-top: 5px;

				.size(100%,@btnHeight);
				.border-radius(2px 0 0 2px);
				.box-sizing(border-box);
			}
			&[type="text"] {
				#font > #futura > .book(14px,normal);
				.kerning(15);
			}
			&[type="radio"],
			&[type="checkbox"] {
				margin-right: 10px;
			}
		}

		textarea {
			padding: 15px;
			border: none;
			background: none;
			background-color: @greyF2;
			color: @grey3F;
			margin-top: 5px;

			.textarea-size(100%,115px);
			#font > #futura > .book(14px,normal);
			.kerning(15);
			.border-radius(2px 0 0 2px);
			.box-sizing(border-box);
		}

		select {
			padding: 0 15px;
			border: none;
			background: @greyF2;
			color: @grey3F;
			margin-top: 5px;

			.size(100%,@btnHeight);
			.border-radius(2px 0 0 2px);
			.box-sizing(border-box);
		}

		li.item {
			border-top: solid 1px @greyF2;
			padding-top: 20px;
			margin-top: 20px;

			&:first-child {
				margin-top: 0;
			}

			.product-img-box {
				display: none;
			}
		}

		.buttons-set {
			position:	relative;
			padding:	35px 0 25px 0;

			.required {
				text-transform: uppercase;
				text-decoration: none;
				color: @grey3F;
				margin: 0;
				position: absolute;
				bottom: 0;
				left: 0;

				.kerning(30);
				#font > #futura > .book(11px,11px);
			}

			.please-wait {
				display: none !important;
			}
		}
	}

	#allow-gift-messages-for-items-container,
	#allow-gift-messages-for-order-container {
		padding: 1em @gutter 0 @gutter;

		p {
			color: @grey3F;

			#font > #futura > .book(14px,14px);
			.kerning(30);
		}
	}

	#payment_form_ccsave {
		width: 100%;
		.clearfix();

		> li {
			width:	 50%;
			display: inline-block;
			float:	 left;

			.box-sizing(border-box);

			&:nth-child(odd) {
				padding-right: (@gutter / 2);
			}

			&:nth-child(even) {
				padding-left: (@gutter / 2);
			}

			&:last-child {
				.v-fix {
					width: 100%;
					padding: 0;

					input[type="text"] {
						width: 25%;
					}
				}
			}

			.v-fix {
				width: 50%;
				display: inline-block;
				float: left;
				.box-sizing(border-box);

				&:first-child{
					padding-right: (@gutter / 5);
				}

				&:last-child{
					padding-left: (@gutter / 5);
				}
			}
		}

		.cvv-what-is-this {
			display: inline-block;
			text-transform: uppercase;
			text-decoration: none;
			color: @grey3F;

			.kerning(30);
			#font > #futura > .book(11px,11px);
			.transition('color 0.25s ease');
			.clearfix();

			&:hover {
				color: @greenLt;
			}
		}
	}

	#payment-tool-tip {
		> .btn-close {
			text-align: right;

			a {
				font-size:		24px;
				text-decoration:none;
				display:		inline-block;
				height:			24px;
				color:			@grey3F;

				.transition('color 0.25s ease');

				&:hover {
					color: @greenLt;
				}
			}
		}
		> .tool-tip-content {
			text-align: center;

			img {
				display: block;
				margin: 0 auto;
			}
		}
	}

	#checkout-review-table-wrapper {
		color: @grey3F;

		#font > #futura > .book(14px,14px);
		.kerning(30);

		table {
			width: 100%;
			border-bottom: solid 2px @grey98;
		}

		h3 {
			#font > #futura > .book(14px,14px);
			text-transform: none;
			margin: 0;
		}

		th {
			text-transform:	uppercase;
			padding-bottom:	5px;
			border-bottom:	solid 2px @grey98;
			text-align:		right;
			vertical-align:	top;

			#font > #futura > .book(14px,14px);

			&.name {
				width: 65%;
				text-align: left;
			}

			&.price, &.qty {
				width: 10%;
			}

			&.sub {
				width: 15%;
			}
		}

		td {
			text-align:	right;
			padding:		10px 0;
			vertical-align:	top;

			&:first-child {
				text-align: left;
			}
		}

		ul.unstyled {
			li {
				text-align:	right;
				padding:	10px 0;

				> span {
					display: inline-block;
					width:	 25%;
				}

				strong {
					#font > #futura > .book(14px,14px);
				}
			}
		}
	}
}

#checkout-success {
	max-width:	@maxWidth;
	margin:		50px auto;
	text-align:	center;
	color:		@grey3F;

	#font > #futura > .book(15px,20px);

	p  a {
		color:			 @greenLt;
		text-decoration: none;

		.transition('color 0.25s ease');
		#font > #futura > .heavy(16px,24px);

		&:hover {
			color: @green;
		}
	}

	h1 {
		#font > #playfair > .regular(25px,25px);
		.kerning(10);

		margin-bottom: 15px;
	}
}

@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
	.checkout-onepage-index {
		#checkoutSteps  {
			.col2-set .col-2, .col2-set .col-1 {
				float:			left;
				padding-left:	0;
				padding-right:	0;
			}

			.col2-set .col-2 {
				&:after {
					display: none;
				}
			}

			.form-list li.fields {
				margin-bottom: 0px;

				.field {
					width:	100%;
					float:	none;
					display:block;

					margin-bottom: 10px;
				}

				.field:last-child {
					padding-left: 0;
				}

				.field:first-child {
					padding-right: 0;
				}
			}
		}

		main.col-right {
			> div {
				width: 100%;
			}

			> div > article {
				width:	100%;
				float:	none;
				display:block;
			}

			> div > aside {
				width:	100%;
				float:	none;
				display:block;
			}
		}

		.ui-btn {
			height: auto;
			display: inline;
		}
	}

	.checkout-onepage-success {
		#checkout-success {
			#font > #futura > .book(15px,20px);
			padding: 80px;
		}

		.ui-btn {
			margin: 10px;
			height: auto;
			line-height: 35px;

			&.green {
				display: block;
			}
		}
	}
}

@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) {
	.checkout-onepage-index {
		main.col-right > div {
			max-width:	100%;
			width:		96%;
			padding:	60px 2%;

			> aside {
				width: 33%;
			}

			> article {
				width:	62%;
				float:	left;
			}
		}
	}
}


// remove sagepay logo
// SORRY FOR THIS :(((((
label[for="p_method_sagepaydirectpro"],
label[for="p_method_sagepayserver"],
label[for="p_method_sagepayform"] {
	padding-left: 0 !important;
	background-image: none !important;
}