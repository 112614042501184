.ratings {
	padding: 25px 0;
	> span,
	> a {
		#font > #futura > .heavy(12px,@ratingStarHeight);
		text-decoration: none;
		.kerning(75);
		color: @greenLt;
		display: inline-block;
		padding-left: 10px;
		margin-left: 10px;
		border-left: dotted 1px @yellow;
		position: relative;
		top: -2px;
	}
	> a {
		#font > #futura > .book(12px,@ratingStarHeight);
		text-transform: uppercase;
		.transition('color 0.25s ease');
		&:hover {
			color: @green;
		}
		&:first-child:last-child {
			border-left: 0;
			padding-left: 0;
			margin-left: 3px;
		}
	}
}
.ratings,
#rating-set {
	.rating-box {
		#font > #futura > .heavy(12px,@ratingStarHeight);
		color: @greenLt;
		display: inline-block;
		float: left;

		.rating {
			display: inline-block;
			.size((@ratingStarWidth * 5),@ratingStarHeight);
			line-height: @ratingStarHeight;
			.background-img('rating-star.png',repeat-x,left,top);
			.background-size(auto,(@ratingStarHeight*2));
			margin-right: 5px;
			position: relative;
			top: 1px;
			left: -2px;
			&.clickable {
				cursor: pointer;
			}
			> span {
				display: inline-block;
				height: @ratingStarHeight;
				line-height: @ratingStarHeight;
				.background-img('rating-star.png',repeat-x,left,bottom);
				.background-size(auto,(@ratingStarHeight*2));
			}
		}
	}
}

#product-ratings {
	position: relative;

	> img {
		position: absolute;
		top: (@ratingsBoxGutter / 2);
		width: 177px;
		left: 50%;
		margin-left: -482px;
		z-index: 0;
	}

	> div {
		max-width: @ratingsBoxWidth;
		position: relative;
		left: ((@maxWidth - @ratingsBoxWidth) / 2);
		margin: 0 auto 90px auto;
		background-color: @greyF2;
		z-index: 1;
		padding: @ratingsBoxGutter;
		.box-sizing(border-box);
	}

	.ratings {
		padding: 5px 0 20px 0;
	}

	.reviewer {
		display: block;
		color: @grey63;
		padding-bottom: 35px;
		.kerning(50);
		#font > #playfair > .italic(12px,normal);
	}

	p {
		color: @grey3F;
		margin-bottom: @ratingsBoxGutter;

		#font > #futura > .book(14px,21px);
		.kerning(15);
	}

	.rating-list, .no-reviews, aside {
		display: inline-block;
		float: right;
	}

	aside {
		width: 260px;
		padding-right: (@ratingsBoxGutter * 2);
		.box-sizing(border-box);

		&:after, &:before {
			content: '';
			.size(1px,50%);
			background-color: @greyDF;
			position: absolute;
			left: 260px;
		}

		&:after {
			top: @ratingsBoxGutter;
		}

		&:before {
			bottom: @ratingsBoxGutter;
		}

		h3 {
			color: @grey3F;
			margin-bottom: 35px;

			#font > #playfair > .regular(28px,normal);
		}

		p {
			margin-bottom: 30px;
		}
	}

	.rating-list, .no-reviews {
		width: (@ratingsBoxWidth - 260px - (@ratingsBoxGutter * 2));
	}
}

@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
	#product-ratings {
		width:		100%;

		> div {
			width: 		100%;
			left:		0;
			padding:	10px;
			background:	#093e2c;
		}

		> img {
			display: none;
		}

		aside {
			width:	 100%;
			padding: 20px;

			.ui-btn.green, h3 {
				color: #fff;
			}

			.ui-btn.green {
				font-size: 13px;
			}

			&:after {
				.none;
			}

			&:before {
				.none;
			}
		}

		.rating-list {
			width:		96%;
			margin:		0 2%;
			float:		none;
			display:	block;
			text-align:	center;
		}

		.ratings {
			padding: 5px 0 10px 0;
		}

		.reviewer {
			padding-bottom: 10px;
			color:	#fff;

			#font > #futura > .book(14px,21px);
		}

		.no-reviews {
			width:		100%;
			text-align:	center;
		}

		p {
			color: #fff;
		}

		.ratings > span, .ratings > a {
			color: #fff;
		}
	}

	.ratings .rating-box, #rating-set .rating-box {
		float:	none;
		color:	#fff;
	}

	#modal {
		width:	100%;
		left:	0;
		margin:	0;
	}

	body.modal #modal {
		top:		0;
		left:		0;
		max-height:	100%;
		height:		100%;
	}
}

@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) {
	#product-ratings {
		> div {
			max-width:	100%;
			position:	relative;
			top:		0;
			left:		0;
		}



		.no-reviews {
			width:	60%;
			float:	right;
		}

		.rating-list {
			width:	60%;
			float:	right;
		}

		aside {
			width:			30%;
			float:			left;
			padding-right:	40px;
		}

		.review-product-image {
			display: none;
		}
	}
}

@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: landscape) {
	#product-ratings {
		aside:after, aside:before {
			left: 325px;
		}
	}
}