// @todo environment functionality
@font-face {
	font-family: 'Ahmad-Icons';
	src:url('/skin/frontend/default/default/fonts/Ahmad-Icons.eot?g41f5w');
	src:url('/skin/frontend/default/default/fonts/Ahmad-Icons.eot?#iefixg41f5w') format('embedded-opentype'),
		url('/skin/frontend/default/default/fonts/Ahmad-Icons.woff?g41f5w') format('woff'),
		url('/skin/frontend/default/default/fonts/Ahmad-Icons.ttf?g41f5w') format('truetype'),
		url('/skin/frontend/default/default/fonts/Ahmad-Icons.svg?g41f5w#Ahmad-Icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="ahmad"], [class*=" ahmad"] {
	font-family: 'Ahmad-Icons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	&:before {
		font-family: 'Ahmad-Icons' !important;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

.ahmadhealthcare:before {
	content: "\e600";
}
.ahmadchild:before {
	content: "\e601";
}
.ahmadeducation:before {
	content: "\e602";
}
.ahmadcommunity:before {
	content: "\e603";
}
.ahmadenvironment:before {
	content: "\e604";
}
.ahmadbasket:before {
	content: "\e605";
}
