body {
	&.catalogsearch-result-index {
		#categoryList {
			.heading {
				display: none;
			}
			.product-list {
				li {
					&.spacer {
						display: none;
					}
				}
			}
		}
	}
}
#catalog-results {
	> header {
		max-width:	@maxWidth;
		margin:		0 auto;
		padding:	(@gutter * 3) 0 0 0;

		h1 {
			color:		@green;
			text-align:	center;

			#font > #trajan > .regular(40px);
			.kerning(-25);
		}
	}

	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		> header {
			padding: 43px 0 0;

			h1 {
				#font > #trajan > .regular(27px);
			}
		}
	}
}