.culture-page {

	#cms-page {

		.content-wrapper.first {
			line-height: 0;
		}

		.culture-block {

			li {
				padding: 		37px 19% 50px;
				width: 			100%;
			}

			&.second  {

				li {
					padding:  50px 0 75px;
				}

				span {
					font: normal normal 400 38px/normal @futuraStack;
					//#font > #futura > .medium(38px,normal);
				}

			}

		}

		.culture-list  {
			display: 		inline-block;
			width: 			100%;
			line-height: 	normal;

			&.first {
				display: 	inline-block;
				width: 		100%;
				margin-top: 35px;
			}

			li {
				float: 			left;
				width: 			50%;
				margin-left: 	0;
				position: 		relative;

				&.first {
					background: 	@block-bg-fifth;
				}

				&.second {
					background: 	@block-bg-sixth;
				}

				img, .subimg-wrapper, > span {
					width: 	50%;
					float: 	left;
				}

				> span, .subimg-wrapper {
					padding: 		9% 3% 0;
					text-align: 	center;
					.box-sizing(border-box);
					font: normal normal 500 19px/31px @futuraStack;
					//#font > #futura > .bold(19px,31px);
				}

				&.left {

					 > span {
						 float: left;
					 }

					img {
						float: right;
					}

					.subimg-wrapper {
						position: 	absolute;
						top:      	0;
						left:    	0;
						bottom:   	0;
						right: 		auto;
					}

				}

			}

			.subimg-wrapper {
				position: 	absolute;
				top: 		0;
				right: 		0;
				bottom: 	0;
				left: 		auto;

				span {
					display: 	inline-block;
					height: 	100%;
					width:	 	100%;
					background-position: bottom center;
					background-repeat: no-repeat;
				}

			}

			&.third {
				width: 		100%;
				display: 	table;

				ul {
					display: 	table-row;

					&.second li {
						padding: 	44px 0;
					}
				}

				li {
					float: 			none;
					text-align: 	center;
					display: 		table-cell;
					width: 			25%;
					padding: 		33px 0;
					vertical-align: middle;

					img, > span {
						float: 	none;
						width: 	auto;
					}

					> span {
						position: 	absolute;
						left: 		0;
						top: 		0;
						bottom: 	0;
						right: 		0;
						padding: 	0;
						height: 	100%;

						.span-table {
							width: 			100%;
							text-align: 	center;
							display: 		table;
							vertical-align: middle;
							height: 		100%;
						}

						.span-table-cell {
							display: 		table-cell;
							vertical-align: middle;
							height: 		100%;
							padding: 		0 5%;
							color: 			@green;
							font: normal normal 400 29px/31px @playFairStack;
							//#font > #playfair > .heavy(29px,31px);
							.box-sizing(border-box);
						}

					}

					&.first {
						background: @block-bg-seventh;
					}

					&.second {
						background: @block-bg-eighth;
					}

					&.third {
						background: @block-bg-ninth;
					}

					&.fourth {
						background: @block-bg-tenth;
					}

					&.fifth {
						background: @block-bg-eleventh;
					}

					&.sixth {
						background: @block-bg-twelfth;
					}

					&.seventh {
						background: @block-bg-thirteenth;
					}

					&.eighth {
						background: @block-bg-fourteenth;
					}

				}

			}

		}
		ul.child-ul{
			margin-bottom: 0;
			overflow: hidden;
			li{
				display: inline-block;
				width: 33.33%;
				margin-left: 0;
				vertical-align: middle;
				text-align: center;
				span{
					color: #7F7F7F;
				    font-size: 29px;
				    display: inline-block;
				    padding: 0 8px;
				    line-height: 35px;
				}
			}
		}
		.top-gym-first, .top-gym-fourth{
		    background: #F2DCDB;
		}
		.top-gym-second, .top-gym-third, .top-gym-fifth{
    		background: #DBEEF4;
		}
		.line {
		    background: #005847;
    		color: #fff;
    		text-align: center;
    		font-size: 20px;
    		min-height: 44px;
    		line-height: 44px;
		}

	}

	.full-slide img {
		width: 100%;
	}

}
