
	#home-page {
		@media only screen and(min-width: @tabletWidthP) and (max-width: 980px) {
		  & {
			max-width:	100%;
			margin:		0 4%;
		  }
		}
	}

	#tea-finder {
		@media only screen and(min-width: @tabletWidthP) and (max-width: 980px) {
		  & {
			width:		100%;
			max-width:	100%;
		  }
		}

		@media only screen and(min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
			& {
				width:		100%;
				max-width:	100%;
				text-align:	center;

				background-color: transparent;
				.background-img('global/tea-finder-bgd.png',no-repeat,center, 8px);

				&:after {
					display: none;
				}

				&:before {
					display: none;
				}

				// @todo check it in html
				select.none {
					display: block;
					margin: 5px auto;
				}

				strong {
					width:		100%;
					text-align:	center;
				}
			}
		}
	}

	#home-video-banner-container {
		width:			100%;
		min-width:	100%;
		height:		550px;
		float:			none;
		position:		relative;
		background: #f4f4f4;
		overflow:	  hidden;
		z-index:		-1;

		.box-sizing(border-box);

		video {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			z-index: -1;
			background-size: cover;
		}
	}

	#home-promo-code {
		#font > #trajan > .regular(30px,30px);
		text-transform:	uppercase;
		color:			@grey43;
		text-align:		center;
		padding:		20px;
		position:		relative;
		margin:			0;

		.promo-details {
			position:		absolute;
			right:			50%;
			margin-right:	(@maxWidth / -2);
			top:			0;
			height:			100%;
			text-align:		right;
			text-transform:	none;

			#font > #futura > .book(12px,15px);

			> span {
				.square(100%);

				display: inline-table;

				> span {
					display:		table-cell;
					vertical-align: middle;
				}
			}

			a {
				color: @greenLt;
				.transition('color 0.25s ease');

				&:hover {
					color: @green;
				}
			}

			strong {
				text-transform: uppercase;
				#font > #futura > .heavy(12px,15px);
			}
		}

		@media only screen and (max-width: 980px) and (min-width: @tabletWidthP) {
			& {
				text-align:	right;
				padding:	36px 0;
				display:	block;
			}

			.promo-details {
				position:	relative;
				top:		0;
				right:		0;
				margin:		20px 0 0;
				display:	block;
			}
		}

		@media only screen and (min-width: 980px) and (max-width: @tabletWidthL) {
			.promo-details {
				right:		 1%;
				margin-right:0;
			}
		}

		@media only screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
			.promo-details {
				position:	relative;
				margin:		0;
				top:		0;
				right:		0;
			}
		}
	}

	#home-carousel-container {
		position:	relative;
		z-index:	0;
		overflow:	hidden;

		.swipe {
			margin:		0;
			overflow:	hidden;
			position:	relative;

			> li, > div {
				float:			left;
				width:			100%;
				position:		relative;
				margin-left:	0;
			}
		}

		#swipe-elements b {
			display:	inline-block;
			background:	#d1c8b8;
			width:		10px;
			height:		10px;
			margin:		5px;

			.inner-shadow(1px,1px,4px,0,0.5);
			.border-radius(10px);

			&.active{
				background: #ff931f;
			}
		}
	}

	#home-carousel {
		width:		100%;
		overflow:	hidden;
		position:	relative;
		z-index:	0;
	}

	#home-carousel-list {
		width:		100%;
		overflow:	hidden;
		position:	relative;
		z-index:	0;

		> li {
			width: 100%;
			.backface-visibility(hidden);

			img {
				width:		100% !important;
				height:		auto !important;
				display:	block;
			}

			a { display: block; }
		}
	}

	#home-carousel-pager {
		display:	block;
		position:	absolute;
		bottom:		40px;
		left:		50%;
		z-index:	1;

		&:before, &:after {
		  position:			absolute;
		  top:				9px;
		  line-height:		0;
		  content:			'';
		  overflow:			hidden;
		  background-color:	rgba(255,255,255,0.4);
		}

		&:before {
			left: -65px;
		}

		&:after {
			right: -65px;
		}

		span {
			display:		inline-block;
			background-color: #fff;
			margin-right:	10px;
			text-indent:	-9999px;
			border:			solid 7px #fff;
			cursor:			pointer;

			.square(20px);
			.border-radius(100%);
			.box-sizing(border-box);
			.transition('background-color 0.15s ease');

			&:first-child {
				margin-left: 10px;
			}

			&.cycle-pager-active {
				background-color: @orangeFF;
			}
		}
	}

	#home-main {
		width:    @maxWidth;
		margin:   0 auto @homeGutter;
		position: relative;

		> div {
			display: inline-block;
			float:   left;

			&.primary-col {
				width:       100%;
				//margin-right: @homeGutter;
			}

			&.secondary-col {
				width: @homeSecondaryWidth;
			}
		}

		@media screen and(min-width: @tabletWidthP) and (max-width: @tabletWidthL) {
			& {
				max-width:	100%;
				width:		96%;
				margin:		0 2%;
			}

			#home-promo-newsletter {
				width: 49%;
				margin:0 1% 0 0;
			}

			#home-promo-carousel {
				width: 49%;
				margin:0 0 0 1%;

				#home-promo-list > li.recipe img {
					width: 100%;
				}

				#home-promo-list > li.benefits {
					background-size: 100%;
				}
			}

			> div {
				&.primary-col {
					min-width:	100%;
					width:		100%;
					margin:		0;
				}

				&.secondary-col {
					width:		100%;
					float:		left;
					background:	#f9f5ee;
					text-align:	left;
					margin-top:	34px;

					&:after {
						display: none;
					}
				}
			}
		}

		@media screen and(min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: landscape) {
			& {
				max-width:	100%;
				width:		96%;
				margin:		0 2%;

				> div {
					&#home-promo-col.secondary-col {
						min-width:	100%;
						float:	right;
						margin:	25px 0 0;
					}

					&.primary-col {
						margin-right: 0;
					}
				}

				.circle {
					width:  191px;
					height: 191px;
					float:	left;
					margin:	0 20px 13px 0;

					img {
						width: 100%;
					}
				}
			}
		}

		@media screen and (min-width: @phoneWidthL) and (max-width: @tabletWidthP) {
			#home-categories {
				.categories {
					div {
						height: 270px;
					}
				}
			}
		}

		@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
			& {
				width:		100%;
				max-width:	100%;

				#home-promo-carousel, #home-promo-newsletter {
					max-width:		100%;
					width:			auto;
					margin-right:	0;
				}

				#home-promo-newsletter {
					a {
						padding: 22px 62px 23px 65px;
					}
				}

				> div {
					display:	block;
					float:  	left;

					&.primary-col {
						width:        auto;
						margin-right: 0;

						#home-promo-carousel, #home-promo-newsletter {
							float:	none;
							margin:	0 4%;
						}
					}

					&.secondary-col {
						width:	243px;
						float:	right;
						display:none;
					}
				}

				#home-categories {
					h3 {
						#font#playfair.regular(13px);

						color:		#3f4442;
						text-align:	center;
					}

					.categories {
						margin:0 20px;

						div {
							width:			50%;
							height:			250px;
							float:			left;
							border-right:	1px solid #e9e9e9;
							margin-top:	 	40px;

							h4 {
								margin:	0 20px;
								height:	48px;

								#font#playfair.regular(14px);

								a {
									color: #282828;
								}
							}

							img {
								width: 100%;
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: @iphoneWidthL) and (max-width: @phoneWidthL) {
			& {
				#home-categories {
					.categories div {
						width: 25%;
					}
				}
			}
		}
	}

	#home-weekly {
		position: 		relative;
		color:			@grey3F;
		margin-bottom:	@homeGutter;

		#font > #futura > .medium(14px,21px);
		.kerning(15);

		&.loaded {
			&:after {
				opacity: 0;
				.square(0);
			}
		}

		> div {
			padding:			60px 50px 80px 50px;
			position:			relative;
			z-index: 			1;
			width:				(@homePrimaryWidth - 175px);
			margin-left:		175px;
			background-color:	@greyF2;

			.box-sizing(border-box);
		}

		> img {
			position:	absolute;
			bottom:		5px;
			left:		0;
			z-index:	0;
			display:	block;
			width:		29%;
		}

		h1 {
			#font > #playfair > .regular(24px,28px);
			margin-bottom: 25px;
			letter-spacing: normal;
		}

		p {
			margin-bottom: 25px;
		}

		.ui-btn.green {
			margin-right: 3px;
		}

		@media screen and(min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: landscape) {
			& {
				margin-bottom: 15px;
			}

			> img {
				width:	19%;
				left:	17px;
			}

			> div {
				padding:			60px 20px 80px 35px;
				position:			relative;
				z-index: 			1;
				width:				82%;

				.box-sizing(border-box);
			}
		}

		@media only screen and(min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
			& { display: none; }
		}
	}

	#home-promo-carousel, #home-promo-newsletter {
		display:	inline-block;
		float:		left;

		.size(@homeTileWidth,270px);


		@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
			display:block;
			float:	none;

			.size(100%, auto);
		}
  	}

	#home-promo-carousel {
		position:		relative;
		float:			right;
  	}

	#home-promo-list {
		.square(100%);

		overflow:	hidden;
		position:	relative;
		z-index:	1;

		> li {
			position:	relative;
			color:		@grey3F;
			padding:	50px 40px;

			.square(100%);
			#font > #futura > .medium(14px,21px);
			.kerning(15);
			.box-sizing(border-box);

			&.charity {
				background-color: @greyF9;
				.background-img('home-bg-charity.png',no-repeat,160px,bottom);
			}

			&.benefits {
				.background-img('home-bg-benefits.jpg',no-repeat,left,top);
			}

			&.recipe {
				> a {
					position:	absolute;
					display:	block;
					top:		0;
					left:		0;

					.square(100%);

					&:hover {
						img { .transform('scale3d(1,1,1)'); }
						strong { bottom: 30px; }
					}
				}

				strong {
					margin-bottom:	0;
					position:		absolute;
					bottom:			20px;
					left:			25px;
					color:			#fff;
					z-index:		1;

					.backface-visibility(hidden);
					.transition('bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940)');
				}

				small {
					display: block;
					padding-top: 5px;

					#font > #futura > .medium(14px,21px);
					.kerning(15);
				}

				img {
					display:	block;
					position:	relative;
					z-index:	0;

					.backface-visibility(hidden);
					.transform-origin(left,center);
					.transform('scale3d(1.05,1.05,1.05)');
					.transition('all 0.5s ease');
				}
			}

			strong {
				#font > #playfair > .regular(24px,28px);

				margin-bottom:	25px;
				letter-spacing:	normal;
				display:		block;
			}

			> p { margin-bottom: 25px; }
		}

		@media only screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
			& {
				overflow:	visible;
				text-align:	center;

				> li {
					position:	relative;
					margin-top:	40px;

					&.recipe {
						padding: 0 0 40px;

						strong {
							left:					 23%;
							bottom:			13px;
							text-align:		center;
							color:				#222;
							background:	rgba(255,255,255,.5);
							padding:			5px;
						}

						a {
							max-height:	231px;
							overflow:	hidden;
							position:	relative;

							img {
								width: 100%;
								height: auto;

								.transform-origin(left,center);
								.transform('scale3d(1,1,1)');
								.transition('all 0.5s ease');
							}
						}
					}

					&.benefits {
						background-size: 100%;
					}
				}
			}
		}

		@media screen and (min-width: @iphoneWidthL) and (max-width: @phoneWidthL) {
			& {
				li {
					&.benefits {
						background-size: 100%;
					}
				}
			}
		}
	}

	#home-promo-list-pager {
		position:	absolute;
		right:		20px;
		bottom:		13px;
		z-index:	2;

		span {
			.square(10px);
			display:			inline-block;
			margin-right:		10px;
			background-color:	#c0b8a9;
			text-indent:		-9999px;
			cursor:				pointer;

			.box-shadow('inset 0 0 6px -2px rgba(0,0,0,0.8)');
			.border-radius(100%);
			.box-sizing(border-box);
			.transition('all 0.15s ease');

			&.cycle-pager-active {
				background-color: @orangeFF;
				.box-shadow('inset 0 0 6px -2px rgba(0,0,0,0.0)');
			}
		}
	}

	#home-promo-newsletter {
		background-color: @greenELT;
		overflow: hidden;

		.background-img('home-bg-newsletter.png',repeat-y,-59px,top);
		.backface-visibility(hidden);
		.transition('background-position-x 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940)');

		&:hover {
			background-position-x: -49px;

			a { margin-left: 20px; }
		}

		a {
			display:			block;
			position:			relative;
			text-decoration:	none;
			color:				@grey3F;
			padding:			70px 62px 0 65px;

			.box-sizing(border-box);
			.backface-visibility(hidden);
			.transition('margin-left 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940)');

			#font > #futura > .medium(15px,21px);
			.square(100%);

			strong {
				display:		block;
				margin-bottom:	25px;
				letter-spacing:	normal;

				#font > #playfair > .regular(24px,28px);
			}
		}
	}

	#home-promo-col {
		padding:	45px 30px 30px 30px;
		text-align:	center;
		color:		@grey3F;

		#font > #futura > .medium(14px,21px);
		.kerning(15);
		.box-sizing(border-box);

		&:after {
			position:	absolute;
			top:		0;
			right:		0;
			z-index:	-1;
			content:	'';
			background:	@greyF9;

			.size(@homeSecondaryWidth,100%);
		}

		.circle {
			display:		block;
			overflow:		hidden;
			margin-bottom:	75px;

			.square(230px);
			.border-radius(100%);

			img {
				.border-radius(100%);
			}
		}

		h1 {
			#font > #playfair > .regular(24px,28px);
			margin-bottom: 25px;
			letter-spacing: normal;
		}

		p {
			margin-bottom: 25px;
		}

		@media screen and(max-width: @tabletWidthL) and (min-width: 980px) {
			&.secondary-col {
				width:		100%;
				background:	#f9f5ee;
				float:		none;

				&:after {
				  display: none;
				}
			}

			.circle {
				width:	181px;
				height:	182px;
				margin-left: 7px;

				img {
					width: 100%;
				}
			}
		}

		@media screen and(min-width: @tabletWidthP) and (max-width: 980px) {
			&.secondary-col {
				width:		100%;
				background:	#f9f5ee;
				float:		none;

				&:after {
				  display:	none;
				}
			}

			& {
				padding-left: 22px;

				.circle {
					width:	181px;
					height:	182px;
					float:	left;
					margin-bottom: 25px;
					margin-right:  22px;

					img {
						width: 100%;
					}
				}
			}
		}

		@media only screen and(min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
			&.secondary-col { display: none; }
		}
	}

	#home-instagram {
		.size(@maxWidth,@homeInstagramDim);

		margin:		0 auto @homeGutter auto;
		position:	relative;

	&:after {
		content: '';
		background-color: #fff;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		opacity: 1;

		.background-img('loading-share.gif',no-repeat,center,center);
		.size(@homeInstagramMax,@homeInstagramDim);
		.transition('opacity 0.25s ease, width 0s ease 0.25s, height 0s ease 0.25s');
	}

	&.loaded {
		&:after {
			opacity: 0;
			.square(0);
		}
	}

	> div {
		.size((@homeTileWidth + @homeGutter),@homeInstagramDim);

		padding-right:	170px;
		margin-right:	@homeGutter;
		display:		inline-block;
		float:			left;
		border-right:	solid 1px @greyE9;
		color:			@grey3F;

		#font > #futura > .medium(14px,21px);
		.kerning(15);
		.background-img('home-bg-instagram.jpg',no-repeat,right,bottom);
		.box-sizing(border-box);
	}

	> ul {
		.size(@homeInstagramMax,@homeInstagramDim);

		display:	inline-block;
		float:		left;

		li {
			.square(@homeInstagramDim);

			margin-right: @homeInstagramGutter;
			display:	inline-block;
			float:		left;

			&:last-child {
				margin-right: 0;
			}

		a {
			.square(100%);
			display:	block;
			overflow:	hidden;

			&:hover {
				img {
				  opacity: 0.75;
				}
			}

			img {
				opacity: 1;
				.transition('opacity 0.5s ease');
			}
		}
	  }
	}

	h1 {
		#font > #playfair > .regular(24px,28px);
		margin-bottom: 25px;
		letter-spacing: normal;
	}

	@media screen and(max-width: @tabletWidthL) and (min-width: 980px) {
	  & {
		.size(auto, @homeInstagramDim);

		margin-top:		33px;
		margin-left:	2%;
		margin-right:	2%;
	  }

	  > div {
		width: 539px;
	  }

	  ul {
		width: auto;

		li {
		  &:nth-child(2) {
			margin-right: 0;
		  }

		  &:last-child {
			display: none;
		  }
		}
	  }
	}

	@media screen and(min-width: @tabletWidthP) and (max-width: 980px) {
		& {
			.size(auto, @homeInstagramDim);

			margin-top:		33px;
			margin-left:	0;
			margin-right:	0;
		}

		> div {
			width: 468px;
		}

		ul {
			width: auto;

			li {
			  display:	none;
			  margin:		0 0 0 0;

			  &:first-child {
				display: block;
			  }
			}
		}
	}

	@media screen and(min-width: @phoneWidthP) and (max-width:  @phoneWidthL) {
		& { display: none; }
	}
}
	.cms-how-to-make-iced-tea #cms-page .title {
		font: italic normal 400 32px/40px trajan-pro-3,Times,'Times New Roman',Georgia,serif;
		color: #5583b6;
		padding: 0 39px 30px;
		overflow: hidden;
		display: block;
	}
	.how_make_holder {
		padding: 0 60px;
		overflow: hidden;
	}
	.how_make_holder .how_make_box_left {
		float: left;
		width: 52%;
	}
	.how_make_holder .how_make_box_right {
		float: right;
		width: 43%;
	}
	.how_make_holder .how_make_box {
		overflow: hidden;
		width: 100%;
		position: relative;
		padding: 9px 0 17px 30px;
		box-sizing: border-box;
		font-size: 19px;
	}
	.how_make_holder .how_make_box_left .how_make_box:nth-child(1):before {
		display: block;
		content: "1";
	}
	.how_make_holder .how_make_box_left .how_make_box:nth-child(2):before {
		display: block;
		content: "2";
	}
	.how_make_holder .how_make_box_right .how_make_box:nth-child(1):before {
		display: block;
		content: "3";
	}
	.how_make_holder .how_make_box_right .how_make_box:nth-child(2):before {
		display: block;
		content: "4";
	}
	.how_make_holder .how_make_box:before {
		color: #5583b6;
		font: italic normal 400 40px/45px trajan-pro-3,Times,'Times New Roman',Georgia,serif;
		position: absolute;
		left: 0;
		top: 0;
	}
	.how_make_holder .how_make_box strong {
		font-weight: 700;
	}
	.how_make_holder .how_make_box p {
		margin: 0 0 10px;
	}
