#header{
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	background: #f2f2f2;
	border-top: 6px solid #093e2c;
	height: 53px;

	#logo{
		position: absolute;
		height: 61px;
		z-index: 100;
		top: -4px;
		left:20px;
		width: 102px;
		text-indent: -999px;
		background-color: #093e2c;

		.background-size(99px,auto);
		.background-img("logo-640.png",no-repeat,center,3px);
	}

	#topNav {
	  background: none;
	}

  #mobile-nav {
    position:	fixed;
    right:		-140px;
    width:		140px;
    height:		100%;
    text-align:	left;
    background:	#093e2c;
    color:		#fff;
    top:		0;
    //bottom:		0;
    z-index:	999;
	overflow-y:	auto;
    .transition(all .45s);
    .inner-shadow(13px,5px,8px,-10px,0.4);

    > ul{
      > li{
        border-bottom: 1px solid #005e20;
        position: relative;
        z-index: 3;
        a{
          color: #fff;
          text-transform: uppercase;
          #font#futura.book(11px);
          padding: 20px;
          display: block;
        }
        p{
          display: none;
        }
        ul{
          display: none;
          margin-top: -10px;
          margin-bottom: 10px;
          li{
            position: relative;

            a{
              color: #fff;
              text-transform: initial;
              #font#futura.book(12px);
              padding: 12px 15px 5px 20px;
            }
          }
        }
        &.has-submenu{
          > a:before{
            position: absolute;
            right:15px;
            top:20px;
            display: block;
            content: "";
            .ssg-icon;
            .transition(.2s);
          }
          &.active{
            > a:before{
              .rotate(180deg);
            }
            ul{
              display: block;
            }
          }
        }
      }
    }
	.other-links {
		position:	absolute;
		bottom:		0;
		height: 	72px;

		li{ border:none; }

		a{
			color:			#fff;
			padding:		5px 20px;
			display:		block;
			text-transform:	uppercase;

			#font#futura.book(11px);
		}
	}
  }
  #shoppingBasket{
    position: absolute;
    right:13%;
    top:17px;
    height: 14px;
    padding-left:20px;
    .background-size(14px,14px);
    .background-img("global/basket.png",no-repeat,left,center);
      @media(max-width:767px) {
          display: none !important;
      }
    a {
      color: #181818;
      > span{
        position: relative;
        top:-3px;
        #font#futura.heavy(12px);
      }
    }
  }
  #menuToggle{
    position: absolute;
    top:    15px;
    right:   3%;
    cursor: pointer;
    width: 22px;
    height: 22px;
    display: block;
    span {
      display: block;
      width:   22px;
      height:  3px;
      background:#000;

      .transition(all .5s);

      &:nth-child(1){
        margin-top:0px;
      }

      &:nth-child(2){
        margin-top:4px;
      }

      &:nth-child(3){
        margin-top:4px;
      }
    }
  }
}

body.menu-active{
  #header{
    #mobile-nav {
      right:0;
      .transition(all .25s);
    }
    #menuToggle{
      span{
        &:nth-child(1){
          .rotate(45deg);
          top: 10px;
          position: absolute;
        }
        &:nth-child(2){
          .opacity(0);
        }
        &:nth-child(3){
          .rotate(-45deg);
          top: 7px;
          position: absolute;
        }
      }
    }
  }
}

#header #topNav #catalogSearch {
	position:	static;
	float:		left;
}

#catalogSearch{


 	i.ssg-search {
		color:		#222;
		font-size:	19px;
		position:	absolute;
		top:		13px;
		right: 		12%;
		cursor:		pointer;
		z-index:	1999;
	}
}

// search

#search_mini_form {
	position:	absolute;
	background:	#222;
	width:		100%;
	top:		49px;
	right:		0;
	display:	none;
}

#search_mini_form.show {
	display: block;
}

#search {
	width:			70%;
	text-transform:	uppercase;
	padding:		15px 0 12px 22px;
}

#topLinks{
  display: none;
}
#mod{
  display: none;
}

