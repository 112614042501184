* {
	padding: 0;
	margin: 0;
	outline: none;
}
img,
fieldset {
	border: none;
}
p,
ul,
ol {
	margin-bottom: 1em;
}
li {
	margin-left: 35px;
}
sup,
sub {
	height: 0;
	line-height: 1;
	vertical-align: baseline;
	position: relative;
}
sup {
	bottom: 1ex;
}
sub {
	top: .5ex;
}
abbr {
	border: none;
}
.price{
	display: none !important;
}