.mobile-hidden {
  display: none;
}

.desktop-hidden {
  display: block;
}

.tablet-hidden {
	display: none;
}

#google_translate_element {
	float:	right;
	z-index:100;
	margin: 10px 20px 0 0;
}

#google_translate_element_mobile {
	position:	fixed;
	bottom:		0;
	padding:	8px;
	left:		0;
	right:		0;
	background:	#093e2c;
}

.touch .goog-te-banner-frame.skiptranslate {
	display: none;
}

.ui-btn {
	display: inline-block;
	height: @btnHeight;
	#font > #futura > .book(14px,(@btnHeight + 1px));
	.kerning(100);
	text-transform: uppercase;
	text-decoration: none;
	border: none;
	padding: 0 20px;
	.border-radius(2px);
	cursor: pointer;
	opacity: 1;
	white-space: nowrap;
	&[disabled] {
		opacity: 0.8;
		cursor: default;
	}
	&.default {
		background-color: @greyD7;
		color: @green;
		.transition('opacity 0.15s ease, color 0.25s ease, background-color 0.25s ease');
		&:hover:not([disabled]) {
			background-color: @greyF2;
			color: @greenLt;
		}
	}
	&[class^="ssg-"],
	&[class*=" ssg-"] {
		&:before {
			font-size: 15px;
			position: relative;
			top: 3px;
			left: -3px;
		}
		&.ssg-print {
			&:before {
				left: -6px;
			}
		}
	}
	&[class^="ahmad"]:before,
	&[class*=" ahmad"]:before {
		font-size: 15px;
		position: relative;
		top: 0px;
		left: -6px;
	}
	&.green {
		background-color: @green;
		color: #fff;
		.transition('opacity 0.15s ease, background-color 0.25s ease');
		&:hover:not([disabled]) {
			background-color: @greenLt;
		}
		&.ui-cart {
			position: relative;
			overflow: hidden;
			&:after {
				content: '';
				.square(100%);
				background-color: @greenLt;
				.background-img('loading-greenlt.gif',no-repeat,center,center);
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				opacity: 0;
				.backface-visibility(hidden);
				.transform-origin(center,center);
				.transform('scale3d(7,7,7)');
				.transition('all 0.3s ease');
			}
			&:before {
				content: attr(data-success);
				.square(100%);
				background-color: @greenLt;
				position: absolute;
				top: 0;
				left: 100%;
				z-index: 0;
				.border-radius(2px);
				.backface-visibility(hidden);
				.transition('left 0.25s ease');
			}
			&.loading {
				&:after {
					opacity: 1;
					.transform('scale3d(1,1,1)');
				}
			}
			&.success {
				background-color: @greenLt;
				&:before {
					left: 0;
				}
			}
		}
	}
	&.white {
		background-color: #fff;
		color: @grey28;
		.transition('color 0.25s ease');
		&:hover {
			color: @greenLt;
		}
	}
	&.black {
		color: #000 !important;
	}
	&.orange {
		color: #fff;
		.gradient(linear, to bottom, @orangeFC 0%, @orangeE2 100%);
		&:hover {
			.gradient(linear, to bottom, @orangeEE 0%, @orangeD8 100%);
		}
	}
	&.label {
		#font > #futura > .heavy(14px,(@btnHeight + 1px));
		color: @green;
		padding: 0 20px 0 0;
		cursor: default;
	}


	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		& {
			display: inline;
		}
	}
}
.inline-block-form {
	> .col {
		display: inline-block;
		float: left;
		width: (@maxWidth / 4);
		&:first-child {
			width: (@maxWidth / 2);
		}
	}
	> .action-set {
		text-align: right;
		.ui-btn {
			margin-left: 5px;
		}
	}
	label {
		#font > #futura > .book(12px,(@btnHeight + 1px));
		text-transform: uppercase;
		.kerning(30);
		padding-right: 15px;
	}
	.ui-input {
		.size(300px,@btnHeight);
		#font > #futura > .book(14px,normal);
		.kerning(15);
		padding: 0 15px;
		border: none;
		background: none;
		background-color: @greyF2;
		color: @grey3F;
		.border-radius(2px 0 0 2px);
		.box-sizing(border-box);
	}
	select {
		.size(150px,@btnHeight);
		padding: 0 15px;
		border: none;
		background: none;
		background-color: @greyF2;
		color: @grey3F;
		.border-radius(2px 0 0 2px);
		.box-sizing(border-box);
	}
}
.inline-form {
	position: relative;
	.ui-input {
		height: @btnHeight;
		padding: 0 15px;
		border: none;
		background: none;
		background-color: #fff;
		float: left;
		color: @grey3F;
		#font > #futura > .medium(14px,normal);
		.kerning(15);
		.border-radius(2px 0 0 2px);
		.box-sizing(border-box);
	}
	.ui-btn {
		float: left;
		.border-radius(0 2px 2px 0);
        max-width:126px;
	}
	.validation-advice {
		#font > #futura > .book(11px,11px);
		text-transform: uppercase;
		text-decoration: none;
		.kerning(30);
		color: @redMsg;
		display: block;
		margin: 0;
		padding: 3px 0;
		position: absolute;
		bottom: -18px;
		left: 0;
		.backface-visibility(hidden);
	}
	.ssg-delete {
		position: absolute;
		top: 0;
		font-size: 27px;
		line-height: 42px;
		color: @grey3F;
		height: 35px;
		overflow: hidden;
		cursor: pointer;
		.transition('color 0.25s ease');
		&:hover {
			color: @greenLt;
		}
	}
}
.inline-nav {
	width: 100%;
	border-bottom: solid 1px @greyD7;
	> div {
		max-width:	@maxWidth;
		margin:		0 auto;
		padding:	30px 0;
		color:		@grey43;
		text-transform: uppercase;

		#font > #futura > .book(12px,12px);
		.kerning(50);
		> ul.unstyled {
			display: inline-block;
			float: left;
			&.right {
				float: right;
			}
			&.teasbar-gifts{
				clear: both;
				:before {
					clear: both;
					overflow: hidden;
					width: 100%;
					display: block;
				}
			}

			&.teasbar-gifts {
				&:before {
					content:				'';
					display:				block;
					border-bottom: 1px solid  #d7d7d7;
					position:				relative;
					margin-top:		14px;
					margin-left:		14px;
					max-width: 		97%;
				}
			}
		}
		li {
			display: inline-block;
			float: left;
			margin-right: 30px;
			&:last-child {
				margin-right: 0;
			}
		}
		a {
			display: inline-block;
			text-decoration: none;
			font-weight: 700;
			color: rgba(0,0,0,0);
			text-align: center;
			position: relative;
			overflow: hidden;
			.transition('color 0.25s ease');
			&:hover {
				&:before {
					top: -100%;
				}
				&:after {
					top: 0;
				}
			}
			&.active {
				cursor: default;
				&:before {
					top: -100%;
				}
				&:after {
					top: 0;
					color: @grey43;
				}
			}
			&:before,
			&:after {
				display: block;
				content: attr(data-title);
				text-align: center;
				position: absolute;
				width: 100%;
				left: 0;
				.transition('top 0.25s ease, color 0.25s ease');
			}
			&:before {
				color: @grey43;
				top: 0;
				font-weight: 400;
			}
			&:after {
				color: @greenLt;
				font-weight: 700;
				top: 100%;
			}
		}
	}
}
.ui-tabs {
	&.alt {
		.ui-tabnav {
			li {
				&.active {
					a {
						font-weight: 400;
					}
				}
				a {
					text-transform: uppercase;
					border-bottom: none;
				}
				span {
					&:after {
						height: 3px;
						background-color: @grey43;
					}
				}
			}
		}
		.ui-tab-container {
			border-bottom: none;
		}
	}
	.ui-tabnav {
		display: inline-table;
		width: 100%;
		li {
			display: table-cell;
			height: @tabNavHeight;

			&.active {
				a {
					font-weight: 500;
					&:hover {
						color: @grey3F;
						cursor: default;
					}
				}
				span {
					&:after {
						width: 100%;
						opacity: 1;
						.transition('opacity @{tabTransTime}s ease, width @{tabTransTime}s ease');
					}
				}
			}

			a {
				#font > #futura > .book(13px,@tabNavHeight);
				.kerning(75);
				display: block;
				text-align: left;
				.size(100%,@tabNavHeight);
				color: @grey3F;
				text-decoration: none;
				border-bottom: solid 1px @greyD7;
				.box-sizing(border-box);
				.transition('color @{tabTransTime}s ease');
				&:hover {
					color: @greenLt;
				}
			}
			span {
				display: inline-block;
				height: @tabNavHeight;
				position: relative;
				&:after {
					content: '';
					.size(0,2px);
					background-color: @greenLt;
					position: absolute;
					bottom: -1px;
					left: 0;
					opacity: 0;
					.transition('opacity @{tabTransTime}s ease, width 0s ease @{tabTransTime}s');
				}
			}
		}
	}
	.ui-tab-container {
		position: relative;
		overflow: hidden;
		border-bottom: solid 1px @greyD7;
		.backface-visibility(hidden);
		.transition('height @{tabTransDelay}s ease');
	}
	.ui-tab {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		opacity: 0;
		padding: 25px 0;
		#font > #futura > .book(14px,21px);
		.kerning(15);
		color: @grey3F;
		.backface-visibility(hidden);
		.transition('opacity @{tabTransDelay}s ease');
		&.active {
			opacity: 1;
			z-index: 1;
		}
	}
}
.ui-increment {
	position: relative;

	> span {
		width: @incrementBtnWidth;
		text-align: center;
		cursor: pointer;
		color: @green;
		#font > #futura > .book(24px,21px);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		.user-select(none);
		&.ui-plus {
			left: auto;
			right: 0;
		}
	}
	input {
		background: none;
		border: none;
		padding: 0 @incrementBtnWidth;
		width: 100%;
		position: relative;
		z-index: 0;
		#font > #futura > .medium(14px,21px);
		.kerning(15);
		color: @grey3F;
		text-align: center;
		.box-sizing(border-box);
	}
}
.ui-add-cart {
	text-align: right;
	.cart-msg {
		float: left;
		#font > #futura > .book(14px,@btnHeight);
		.kerning(15);
		color: @grey3F;
		display: inline-block;
	}
}
.ui-cart-input {
	.size(60px,@btnHeight);
	border: none;
	background: none;
	background-color: @greyF2;
	color: @grey3F;
	#font > #futura > .medium(14px,normal);
	.kerning(15);
	text-align: center;
	.border-radius(2px 0 0 2px);
	.box-sizing(border-box);
}
#modal {
	width: @modalWidth;
	max-height: @modalMaxHeight;
	overflow: auto;
	position: fixed;
	top: -100%;
	left: -100%;
	margin-left: (@modalWidth / -2);
	margin-top: -@headerHeight;
	z-index: 9999;
	background-color: #fff;
	padding: 30px @modalGutter;
	opacity: 0;
	.box-shadow('0 0 18px rgba(0,0,0,0.36)');
	.box-sizing(border-box);
	.transition('opacity 0.25s ease, margin-top 0.5s ease, top 0 ease 0.5s, left 0 ease 0.5s');
	&.dark {
		background-color: @brown;
		text-align: center;
		padding: 0 100px;
		border: solid 10px @greyF2;
		i.ssg-delete {
			color: @greyF2;
			&:hover {
				color: #fff;
			}
		}
		.modal-header {
			margin-bottom: 0;
			border-bottom: none;
			padding-top: 25px;
			> strong {
				color: @greyF2;
			}
			span {
				&.img {
					display: block;
					margin: 0 auto 25px auto;
					.size(200px,188px);
					overflow: hidden;
					.border-radius(100%);
				}
			}
		}
		.modal-content {
			color: @greyF2;
			#font > #futura > .book(16px,21px);
			p {
				margin-bottom: 25px;
			}
		}
	}
	i.ssg-delete {
		font-size: 25px;
		height: 18px;
		line-height: 25px;
		color: @green;
		position: absolute;
		top: 15px;
		right: 15px;
		cursor: pointer;
		.transition('color 0.25s ease');
		&:hover {
			color: @greenLt;
		}
	}
	.modal-header {
		padding-bottom: 25px;
		margin-bottom: 25px;
		border-bottom: solid 1px @greyC5;
		&:empty {
			display: none;
		}
		> strong {
			display: block;
			#font > #playfair > .regular(28px,28px);
			color: @grey3F;
			small {
				display: block;
				#font > #futura > .book(12px,12px);
				.kerning(100);
				text-transform: uppercase;
				text-indent: 2px;
				color: @grey93;
				padding-top: 8px;
				span {
					color: @greenLt;
				}
			}
		}
	}
	.form-row {
		padding-bottom: 15px;
		label {
			display: block;
			#font > #futura > .heavy(12px,12px);
			.kerning(25);
			text-transform: uppercase;
			color: @grey3F;
			padding-bottom: 5px;
			&.required {
				&:before {
					content: '\002A\0020';
				}
			}
			&:after {
				content: '\003A';
			}
		}
		input[type="text"] {
			.size(100%,@btnHeight);
			padding: 0 15px;
			border: solid 1px @greyB8;
			background: none;
			background-color: #fff;
			color: @grey3F;
			#font > #futura > .medium(14px,normal);
			.kerning(15);
			.box-sizing(border-box);
		}
		textarea {
			.textarea-size(100%,150px);
			padding: 15px;
			border: solid 1px @greyB8;
			background: none;
			background-color: #fff;
			color: @grey3F;
			#font > #futura > .medium(14px,normal);
			.kerning(15);
			.box-sizing(border-box);
		}
	}
	.form-col {
		width: 50%;
		padding-right: 10px;
		float: left;
		.box-sizing(border-box);
		&:last-child {
			padding-left: 10px;
			padding-right: 0;
		}
	}
	.buttons-set {
		text-align: center;
	}
}
body {
	&.touch {
		top: 0 !important;
	}

	&.modal {
		.wrapper {
			&:before {
				top: 0;
				left: 0;
				opacity: 1;
				.transition('opacity 0.25s ease');
			}
		}
		#modal {
			top: @headerHeight;
			left: 50%;
			opacity: 1;
			margin-top: 0;
			.transition('opacity 0.5s, margin-top 0.5s ease');
		}
	}
}
#modal-quick-view {
	.ratings {
		padding: 15px 0 0 0;
		.ratings-write {
			display: none;
		}
	}
	.modal-content {
		> img {
			display: inline-block;
			float: left;
			width: 35%;
		}
	}
}
.modal-product-content {
	display: inline-block;
	float: left;
	#font > #futura > .book(14px,21px);
	color: @grey3F;
	width: 378px;
	padding-left: (@modalGutter / 2);
	.box-sizing(border-box);
	.availability.in-stock,
	> form > .regular-price {
		display: none;
	}

	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		& {
			width: 100%;
		}
	}
}
#tea-finder {
	width:				@maxWidth;
	position:			relative;
	margin:				-25px auto 40px auto;
	background-color:	@tanLt;
	color:				@grey3F;
	padding:			40px 30px;

	#font > #playfair > .regular(26px,26px);
	.box-sizing(border-box);

	&:before, &:after {
		content:	'';
		position:	absolute;
		bottom:		17px;
		z-index:	-1;

		.box-shadow('0 15px 20px rgba(0, 0, 0, 0.5)');
		.size(49%,20%);
	}

	&:before {
		left: 10px;
		.transform('rotate(-1deg)');
	}

	&:after {
		right: 10px;
		.transform('rotate(1deg)');
	}

	&.catalog {
		z-index:			101;
		width:				100%;
		background-color:	transparent;
		margin:				0;
		border-bottom:		1px solid @greyD7;
		padding:			30px 0;
		color:				@grey43;
		text-transform:		uppercase;

		#font > #futura > .book(14px,14px);
		.kerning(100);
		.box-sizing(content-box);

		&:before, &:after {
			display: none;
		}

		> a {
			position:			absolute;
			top:				30px;
			left:				50%;
			margin-left:		(@maxWidth / -2);
			color:				@grey43;
			text-decoration:	none;
			z-index:			1;

			.transition('color 0.25s ease');

			&:before {
				position:		relative;
				top:			2px;
				margin-right:	6px;
			}

			&:hover {
				color: @greenLt;
			}
		}

		> strong {
			#font > #futura > .book(14px,14px);

			&:after {
				//display: none;
				content: '\003A';
			}
		}

		> span {
			color:			@grey43;
			text-transform:	uppercase;
			margin-left:	30px;

			#font > #futura > .heavy(14px,14px);

			&:after {
				color: @grey43;
				font-size: 14px;
			}
		}

		> ul {
			height:				90px;
			text-transform:		uppercase;
			border:				1px solid #fff;
			color:				@grey43;
			background-color:	transparent;

			&.open {
				top:			-9px;
				margin-left:	8px;

				&.ready {
					border-color: @greyD7;

					li {
						border-color: @greyD7;
					}
				}
			}

			li {
				padding:			0 20px;
				height:				30px;
				border-bottom:		solid 1px #fff;
				background-color:	#fff;
				line-height:		30px;

				&:hover {
					background-color: @greyF2;
				}
			}
		}
	}

	> strong {
		display: 	inline-block;
		width:		50%;
		text-align:	right;

		#font > #playfair > .regular(26px,26px);

		&:after {
			content: '\2026';
		}
	}

	> span {
		color:			@tanDk;
		text-transform:	lowercase;
		cursor:			pointer;
		display:		inline-block;
		margin-left:	60px;

		.transition('color 0.25s ease');

		&:hover {
			color: @greenLt;

			&:after {
				color: @greenLt;
			}
		}

		&:after {
			color:		@green;
			position:	relative;
			top:		2px;
			left:		8px;
			font-size:	20px;

			.transition('color 0.25s ease');
		}
	}

	> ul {
		position:			absolute;
		top:				-100%;
		left:				-100%;
		margin-left:		69px;
		color:				@tanDk;
		background-color:	@tanLt;
		text-transform:		lowercase;
		height:				180px;
		border:				1px solid @tanLt;
		overflow:			hidden;
		opacity:			0;

		.transition('opacity 0.25s ease, top 0 ease 0.25s, left 0 ease 0.25s, margin-left 0.25s ease');

		&.open {
			top:			-37px;
			left:			50%;
			margin-left:	29px;
			opacity:		1;

			.transition('margin-left 0.5s ease, opacity 0.1s ease, border-color 0.25s ease');

			&.ready {
				border-color:	@tanDk;
				overflow-y:		auto;
				overflow-x:		hidden;

				li {
					border-color: @tanDk;
					.transition-delay('0s !important');
				}
			}

			li {
				opacity:		1;
				margin-left:	0;

				a {
					color:				#a69782;
					text-decoration:	none;
				}
			}
		}

		li {
			padding:			0 30px;
			height:				60px;
			line-height:		60px;
			border-bottom: 		1px solid @tanLt;
			background-color:	@tanLt;
			cursor:				pointer;
			margin-left:		100%;
			white-space:		nowrap;
			opacity:			0;

			.box-sizing(border-box);
			.transition('background-color 0.25s ease 0, margin-left 0.25s ease, opacity 0.25s ease, border-color 0.25s ease 0');

			&:last-child {
				border-bottom: none;
			}

			&:hover {
				background-color: @tanMd;
			}
		}
	}
}

.ui-share-load {
	position: relative;

	&:after {
		content:			'';
		background-color:	#fff;
		position:			absolute;
		top:				0;
		left:				0;
		z-index:			1;
		opacity:			1;
		line-height:		0;
		overflow: 			hidden;

		.square(100%);
		.background-img('loading-share.gif',no-repeat,center,center);
		.transition('opacity 0.25s ease, width 0s ease 0.25s, height 0s ease 0.25s');
	}

	&.loaded {
		&:after {
			opacity: 0;
			.square(0);
		}
	}
}
body {
	&.firefox,
	&.ie-10 {
		#tea-finder {
			> ul {
				width: 251px;
			}
		}
	}
	&.ipad {
		#tea-finder {
			> ul {
				&.ready {
					overflow-y: scroll;
					-webkit-overflow-scrolling: touch;
				}
			}
		}
	}
}
.pager {
	width:			100%;
	padding:		5px 0;
	text-transform:	uppercase;
	color:			@grey3F;

	#font > #futura > .book(12px,@btnHeight);
	.kerning(25);

	.amount {
		margin:		0;
		display:	inline-block;
		float:		left;

		strong {
			#font > #futura > .book(12px, @btnHeight);
		}
	}

	.limiter {
		display:inline-block;
		float:	right;

		select {
			margin:				0 5px;
			padding:			0 0 0 5px;
			border:				none;
			background:			none;
			background-color:	@greyF2;
			color:				@grey3F;

			.size(50px,@btnHeight);
			.border-radius(2px 0 0 2px);
			.box-sizing(border-box);
		}
	}

	.pages {
		padding-bottom: 5px;

		> strong, > ol {
			display: inline-block;
			float: left;
		}

		> strong {
			#font > #futura > .medium(12px,normal);
		}

		> ol {
			margin-left: 15px;

			li {
				display: inline-block;
				float: left;
				margin-left: 10px !important;

				#font > #futura > .heavy(12px,normal);
				.kerning(25);

				a {
					#font > #futura > .book(12px,normal);
					color:				@greenLt;
					position: 			relative;
					text-decoration:	none;

					&:after {
						content: '';
						.size(100%,1px);
						background-color: @greenLt;
						position: absolute;
						left: 0;
						bottom: -2px;
					}

					&.jumper {
						&:after {
							display: none;
						}
					}

					&:hover {
						color: @greenLt;
					}

					i {
						font-size: 17px;
						position: relative;
						top: -2px;
					}
				}
			}
		}
	}
}


.swipe-message {
	width:		100%;
	text-align:	center;
	color:		#fff;
	padding:	11px 0 5px;
	position:	static;
	background:	rgba(0,0,0,0.5);
	display:	none;

	#font > #playfair > .regular(13px, 30px);
}

@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) {
	.swipe-message {
		display:	block;
		top:		104px;
	}

	.tablet-hidden {
		display: block;

		&.mobile-hidden {
			display: block;
		}
	}
}

@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: portrait) {
}

@media screen and(min-width: @phoneWidthP) and (max-width: @tabletWidthL) {
	.swipe-message {
		display: block;
	}
}
