.quality-page, 
.cms-people-and-career, 
.cms-laboratory, 
.cms-culture, 
.cms-activities-events,
.sustainability-page,
.cms-contact-us,
.cms-uae-facility{

	#cms-page{

		.slide-text-style {

			.content-wrapper{
				position: absolute;
			    left: 0;
			    right: 0;
			    top: 0;
			    bottom: 0;
				width: 100%;
			    h1{
				    color: #093e2c;
	    			width: 50%;
	    			padding: 50px 0 0 70px;
	    			#font > #futura > .book(50px,29px);
	    			line-height: 50px;
	    			text-shadow: 3px 3px 3px rgba(255,255,255, 1);
	    		}
			    h2{
	    			font-family: 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
	    			padding-top: 35px;
			    	
			    }	
			    .description-slide {
				    max-width: 470px;
				    width: 100%;
				    line-height: 1.2;
				    color: #fff;
				    background: #005847;
				    padding: 25px 20px;
				    font-size: 18px;
				    position: absolute;
				    right: 0;
				    box-shadow: 4px 2px 10px grey;
				    bottom: 0;
				}
			}	
		}
	}	

	.circle-img, .block-managemnt{
		.content-wrapper{
			padding-left: 0;
			img{
				width: 75% !important;
    			position: absolute;
    			left: -100px;
    			top: 200px;
			}
			h2 {
			    width: 30%;
			    font-size: 50px !important;
			    color: #fff;
			    float: right;
			    text-align: center;
			}
		}
	}

	.block-testing{
		.content-wrapper{
			.description-slide {
			    top: 0px !important;
			    bottom: auto !important;
			    left: 0;
			}
		}
	}
	// .circle-img{
	// 	.content-wrapper{
	// 		.description-slide {
	// 		    top: 30px !important;
	// 		}
	// 	}
	// }

}

img.second-img{
	display: none;
}
	
