@media screen and (max-width: @secondTabletWidth) {

	.sustainability-page,
	.culture-page,
	.tea-health-page,
	.quality-page,
	.laboratory-page {

		#cms-page .slide-wrapper .slide-text > div {
			padding: 	0 10%;
		}

	}

}

@media screen and (max-width: @tabletWidthL) {
	html, body{
    	overflow-x: hidden;
	}

	.sustainability-page,
	.culture-page,
	.tea-health-page,
	.quality-page,
	.laboratory-page,
	.art-tea-page {

		#cms-page {

			> section {
				width:   100%;
				padding: 0;
			}

			.second-wrapper {
				width: 		100%;
			}

			.slide-wrapper .slide-text > div {
				padding: 	0 10%;
			}

		}

	}

	.ahmad-cms-page {

		&.sustainability-page,
		&.culture-page,
		&.tea-health-page,
		&.quality-page,
		&.laboratory-page,
		&.art-tea-page {

			#cms-page .slide-wrapper .slide-text h1,
			#cms-page .slide-wrapper .slide-text h1 {
				font-size: 30px;
			}
			main #cms-page .slide-wrapper .slide-text p {
    			line-height: 25px;
    			font-size: 18px;
			}

		}

		&.sustainability-page #cms-page .slide-wrapper .slide-text p,
		&.culture-page #cms-page .slide-wrapper .slide-text p,
		&.tea-health-page #cms-page .slide-wrapper .slide-text p,
		&.quality-page #cms-page .slide-wrapper .slide-text p,
		&.laboratory-page #cms-page .slide-wrapper .slide-text p,
		&.art-tea-page #cms-page .slide-wrapper .slide-text p,
		&.culture-page #cms-page .culture-list.third li  > span .span-table-cell {
			font-size: 		20px;
		}

		&.activity-event-page #cms-page .reviews .review-wrap .review-text-wrap {
			p.author, q, p {
				font-size: 		20px;
				line-height: 	normal;
			}
		}

		&.culture-page #cms-page .culture-block span,
		&.sustainability-page #cms-page .environment-block span,
		&.sustainability-page #cms-page .environment-list span,
		&.tea-health-page #cms-page ul li h4,
		&.laboratory-page #cms-page .laboratory-block span {
			font-size: 		20px;
			line-height: 	normal;
		}

	}
	html .sustainability-page #cms-page .environment-list li img {
    	height: initial;
	}
	#header .middle-content {
    	margin: 0;
	}
	.sustainability-page #cms-page .slide-wrapper .slide-text > div, 
	.culture-page #cms-page .slide-wrapper .slide-text > div, 
	.tea-health-page #cms-page .slide-wrapper .slide-text > div, 
	.quality-page #cms-page .slide-wrapper .slide-text > div, 
	.laboratory-page #cms-page .slide-wrapper .slide-text > div, 
	.art-tea-page #cms-page .slide-wrapper .slide-text > div {
    	padding: 0 5%;
	}

	.quality-page, 
	.cms-people-and-career, 
	.cms-laboratory, 
	.cms-culture, 
	.cms-activities-events ,
	.sustainability-page,
	.cms-contact-us,
	.cms-uae-facility {
		.slide-text-style{
			.content-wrapper{
				h1{
					width: 100%;
	    			font-size: 35px;
				}
			}
		}
	}

	.activity-event-page .slide-wrapper .slide-text-style.block-testing h2 {
    	width: 50%;
	}

	.quality-page .block-testing .content-wrapper .description-slide, 
	.cms-people-and-career .block-testing .content-wrapper .description-slide, 
	.cms-laboratory .block-testing .content-wrapper .description-slide, 
	.cms-culture .block-testing .content-wrapper .description-slide, 
	.cms-activities-events .block-testing .content-wrapper .description-slide, 
	.sustainability-page .block-testing .content-wrapper .description-slide {
    	top: initial;
	}
	
	html{
		.cms-contact-us {
			#cms-page{
				.slide-text-style{
					div#box-contact{
					    right: 20px;
				        padding: 20px 30px;
				        float: right;
    					bottom: 40px;
    					top: -60px;
						h3{
							font: normal normal 400 38px/20px 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
						}

						.box-left {
						    float: left;
						    width: 45%;
						}
					}
				}
			}
		} 
	}
	 
	.cms-uae-facility {
		.facility-text-style{
			.content-wrapper{
				.layers{
			    	padding-right: 15px;			
				}
			}
		}
    	.slide-wrapper{
    		p{
			    padding: 0 15px;
    		}
    		ul.brands {
			    padding: 10px 15px;
			}
		}
	}
	.cms-uae-facility .slide-wrapper .block-arrow{
		margin-top: 0;
	}

	.cms-contact-us #cms-page .slide-text-style .content-wrapper div#box-contact:before{
		top: -45px;
	}

	.quality-page #cms-page .slide-text-style .content-wrapper .description-slide, .cms-people-and-career #cms-page .slide-text-style .content-wrapper .description-slide, .cms-laboratory #cms-page .slide-text-style .content-wrapper .description-slide, .cms-culture #cms-page .slide-text-style .content-wrapper .description-slide, .cms-activities-events #cms-page .slide-text-style .content-wrapper .description-slide, .sustainability-page #cms-page .slide-text-style .content-wrapper .description-slide, .cms-contact-us #cms-page .slide-text-style .content-wrapper .description-slide, .cms-uae-facility #cms-page .slide-text-style .content-wrapper .description-slide {
    	bottom: 0;
    	top: initial;
	}

	.activity-event-page .slide-wrapper .slide-text-style.block-testing h2 {
	    font-size: 35px !important;
	}

	.cms-uae-facility .slide-wrapper .block-arrow li {
    	margin-bottom: 10px;
	}

	html{
		.sustainability-page{
			#cms-page{
				.slide-text-style .content-wrapper{
		    		padding: 0;
		    		position: initial;
		    		.description-slide{
			            position: initial;
			            box-shadow: none;
			            padding: 10px 20px;
		        	}
				}
			}
		}
	}

	html{
		.sustainability-page{
			#cms-page{
				.slide-text-style {
					.content-wrapper{
						h1{
							width: 100%;
			    			text-align: center;
			    			top: 0px;
			    			position: absolute;
			    			padding: 50px 0 0 0px;
						}
					}
				}
			}	
		}
	}

	html{
		.sustainability-page{
			#cms-page{
				.slide-wrapper{
					.content-wrapper{
			 			.description-slide{
			 				max-width: 100%;
			 			}
					}
				}
			}
		}
	}

	html{
		.quality-page ,
		.cms-people-and-career,
		.cms-laboratory,
		.sustainability-page,
		.cms-culture,
		.activity-event-page,
		.cms-contact-us,
		.sustainability-page,
		.cms-uae-facility{
			#cms-page{
				.slide-text-style {
					.content-wrapper{
						h1{
							width: 100%;
			    			text-align: center;
			    			top: 0px;
			    			position: absolute;
						}
					}
				}
			}	
		}
	}
	
	html{
		.quality-page ,
		.cms-people-and-career,
		.cms-laboratory,
		.cms-culture,
		.activity-event-page,
		.sustainability-page,
		.cms-contact-us,
		.cms-uae-facility{
			#cms-page{
				.slide-text-style .content-wrapper{
		    		padding: 0;
		    		position: initial;
		    		.description-slide{
			            position: initial;
			            box-shadow: none;
			            padding: 10px 20px;
		        	}
				}
			}
		}
	}

	html{
		.quality-page ,
		.cms-people-and-career,
		.cms-laboratory,
		.sustainability-page,
		.cms-culture,
		.activity-event-page,
		.cms-contact-us,
		.sustainability-page,
		.cms-uae-facility{
			#cms-page{
				.slide-wrapper{
					.content-wrapper{
			 			.description-slide{
			 				max-width: 100%;
			 			}
			 			div#box-contact{
			 				padding-left: 45px;
			 				float: none;
		 				    top: 25px;
			 				&:before{
			 					background: transparent;
			 				}
			 			}
					}
				}
			}
		}
	}

	html{
		.quality-page ,
		.cms-people-and-career,
		.cms-laboratory,
		.sustainability-page,
		.cms-culture,
		.activity-event-page,
		.sustainability-page,
		.cms-contact-us,
		.cms-uae-facility{

			#cms-page{
				h1{
					padding: 0 !important;
				}
			}

		}
	}

	.quality-page .circle-img .content-wrapper h2, 
	.quality-page .block-managemnt .content-wrapper h2,
	.activity-event-page .slide-wrapper .slide-text-style.block-testing h2{
    	position: absolute;
    	top: 0;
    	right: 0;
	}
	
	.cms-contact-us{
		.block-form{
			#contactForm{
		    	padding: 30px;
			}
		} 
	}

	.cms-contact-us .block-contact {
    	padding: 30px 30px 0 30px;
	}

}

@media screen and (max-width: @tabletContainerWidthG) {

	.activity-event-page .slide-wrapper .slide-text-style.block-testing h2 {
    	width: initial;
    	padding: 10px 20px 0 0;
    	font-size: 25px !important;
	}


	html .cms-contact-us #cms-page .slide-text-style div#box-contact {
    	top: 40px;
    	.box-left {
	    	width: 45%;
	    	margin-right: 5%;
		}
		h3{
			margin-bottom: 10px;
		}
	}

	.cms-uae-facility .facility-text-style .content-wrapper .layers ul {
    	width: 100%;
	}

	.cms-uae-facility .facility-text-style .content-wrapper .layers h3 {
    	font: normal normal 300 24px/29px 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
	}

	.cms-uae-facility .slide-wrapper .block-arrow li {
    	margin-bottom: 20px;
	}

	.cms-contact-us #cms-page .slide-text-style .content-wrapper div#box-contact {
    	padding: 0;
    }

    ul.environment-list li {
    	height: initial !important;
	}

	.quality-page, 
	.cms-people-and-career, 
	.cms-laboratory, 
	.cms-culture, 
	.cms-activities-events, 
	.sustainability-page, 
	.cms-contact-us, 
	.cms-uae-facility {
		#cms-page{
			.slide-text-style {
				.content-wrapper{
					h2{
						padding-top: 0px !important;
					}
				}
			}
		}	
	}

}

@media screen and (max-width: @tabletWidthP) {

	.ahmad-cms-page {

		&.sustainability-page,
		&.culture-page,
		&.tea-health-page,
		&.quality-page,
		&.laboratory-page,
		&.art-tea-page {

			#cms-page .slide-wrapper .slide-text h1,
			#cms-page .slide-wrapper .slide-text h1 {
				padding-left: 	0;
				font-size: 		30px;
			}

		}

		&.sustainability-page #cms-page .slide-wrapper .slide-text p,
		&.culture-page #cms-page .slide-wrapper .slide-text p,
		&.art-tea-page #cms-page .slide-wrapper .slide-text p,
		&.tea-health-page #cms-page .slide-wrapper .slide-text p,
		&.quality-page #cms-page .slide-wrapper .slide-text p,
		&.laboratory-page #cms-page .slide-wrapper .slide-text p,
		&.culture-page #cms-page .culture-list.third li  > span .span-table-cell,
		&.tea-health-page #cms-page ul li p {
			font-size: 		16px;
			line-height: 	normal;
		}

		&.activity-event-page #cms-page .reviews .review-wrap .review-text-wrap {
			p.author, q, p {
				font-size: 	16px;
			}
		}

		&.culture-page #cms-page .culture-block span,
		&.sustainability-page #cms-page .environment-block span,
		&.sustainability-page #cms-page .environment-list span,
		&.tea-health-page #cms-page ul li h4,
		&.laboratory-page #cms-page .laboratory-block span {
			font-size: 		17px;
		}

	}

	html {
		.quality-page, 
		.cms-people-and-career, 
		.cms-laboratory, 
		.sustainability-page,
		.cms-culture,
		.cms-activities-events,
		.sustainability-page,
		.cms-uae-facility{
			.slide-text-style{
				.content-wrapper{
					h1{
						font-size: 20px !important;
					}
				}
			}
		}
	}

}

@media screen and (max-width: @tabletContainerWidthP) {

	html{
		.sustainability-page{
			.slide-text-style{
				.content-wrapper{
					p{
					    font-size: 18px;
					}
				}
			}
		}
	}

}

@media screen and (max-width: @iphoneWidthMG) {

	.ahmad-cms-page.sustainability-page main #cms-page .slide-wrapper .slide-text p{
    	font-size: 14px;
	}

	#cms-page h3{
		margin-bottom: 0;
	}

	html .sustainability-page #cms-page .environment-list li img {
    	height: 215px;
	}

	.quality-page .block-managemnt .content-wrapper h2{
		font-size: 40px !important;
	}

	.quality-page .block-managemnt .content-wrapper h2,
	.activity-event-page .slide-wrapper .slide-text-style.block-testing h2{
	    font-size: 40px !important;
	    position: absolute;
	    top: 0;
	    right: 0;
	}

	.activity-event-page .slide-wrapper .slide-text-style.block-testing h2{
		font-size: 25px !important;
	}

	.activity-event-page ul.activities-ul li span {
    	font-size: 18px;
	}

	html .cms-contact-us #cms-page .slide-text-style div#box-contact .box-left {
	    float: left;
	    width: 45%;
	    margin-right: 20px;
	}

	.box-right {
	    float: right;
	    width: 50%;
	}

	.cms-contact-us #cms-page .slide-text-style .content-wrapper div#box-contact .small-size {
    	width: 30%;
	}
	
}

@media screen and (max-width: @mobileWidth) {

	.ahmad-cms-page {

		&.sustainability-page,
		&.culture-page,
		&.tea-health-page,
		&.quality-page,
		&.laboratory-page,
		&.art-tea-page {

			#cms-page .slide-wrapper .slide-text h1,
			#cms-page .slide-wrapper .slide-text h1 {
				margin: 	15px 0;
				font-size: 	23px;
			}

		}

		&.sustainability-page #cms-page .slide-wrapper .slide-text p,
		&.culture-page #cms-page .slide-wrapper .slide-text p,
		&.tea-health-page #cms-page .slide-wrapper .slide-text p,
		&.quality-page #cms-page .slide-wrapper .slide-text p,
		&.art-tea-page #cms-page .slide-wrapper .slide-text p,
		&.laboratory-page #cms-page .slide-wrapper .slide-text p,
		&.culture-page #cms-page .culture-list.third li  > span .span-table-cell,
		&.tea-health-page #cms-page ul li p {
			font-size: 	13px;
		}

		&.activity-event-page #cms-page .reviews .review-wrap .review-text-wrap {
			p.author, q, p {
				font-size: 	13px;
			}
		}

		&.culture-page #cms-page .culture-block span,
		&.sustainability-page #cms-page .environment-block span,
		&.sustainability-page #cms-page .environment-list span,
		&.tea-health-page #cms-page ul li h4,
		&.laboratory-page #cms-page .laboratory-block span {
			font-size: 15px;
		}

	}

}

@media screen and (max-width: @iphoneWidthMG5) {

	.ahmad-cms-page.sustainability-page main #cms-page .slide-wrapper .slide-text p{
    	line-height: 17px;
	}
	.ahmad-cms-page.sustainability-page #cms-page .environment-block span{
		font-size: 13px;
	}
	html .sustainability-page #cms-page .environment-list li img {
    	height: 155px;
	}

	html .sustainability-page .slide-text-style .content-wrapper h1 {
	    top: 90px;
	    font-size: 35px !important;
	}

	html {
		.quality-page, 
		.cms-people-and-career, 
		.cms-laboratory,
		.cms-activities-events{
			.slide-text-style{
				.content-wrapper{
					padding: 0;
				}
			}
		}
	}


	.culture-page #cms-page ul.child-ul li,
	.cms-activities-events #cms-page ul.activities-ul li{
		width: 100%;
	}

	.culture-page,
	.cms-activities-events{
		#cms-page{
			.line{
				line-height: 25px;
				padding: 10px;
			}
		}
	}
	
	html{
		.quality-page, 
		.cms-people-and-career, 
		.cms-laboratory, 
		.sustainability-page, 
		.cms-culture,
		.cms-activities-events,
		.cms-uae-facility,
		.cms-contact-us{
			.slide-text-style{
				.content-wrapper{
					h1{
						top: 0px !important;
    					font-size: 20px !important;
    					padding-top: 15px !important;
					}
				}
			}	
    	}
	}

	html{
		.cms-contact-us{
			#cms-page{
				.slide-text-style{
					div#box-contact{
						h3{
						    font: normal normal 400 29px/20px 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
						}
						.box-right {
		    				float: right;
		    				width: 45%;
						}
					}
				}
			}
		}
	}

	.cms-uae-facility{
		article#cms-page {
		    padding-bottom: 100px;
		}
		.slide-wrapper{
			.block-art{
				position: relative;
				overflow: hidden;
			}
			.block-arrow{
				margin-top: 25px;
				li{
					padding-left: 60px;
			    	padding-right: 15px;
				}
			}
		}
		.facility-text-style {
			.content-wrapper{
				.layers{
					float: none;
		    		padding-left: 15px;
		    		margin-bottom: 25px;
				}
			}
		}
	}

	html{
		.quality-page, 
		.cms-people-and-career, 
		.cms-laboratory, 
		.sustainability-page, 
		.cms-culture, 
		.activity-event-page, 
		.sustainability-page, 
		.cms-contact-us, 
		.cms-uae-facility {
			#cms-page{
				h1{
			    	padding: 20px !important;
				}
			}
		}
	}

}

@media screen and (max-width: @secondMobileWidth) {

	.ahmad-cms-page {

		&.sustainability-page,
		&.culture-page,
		&.tea-health-page,
		&.quality-page,
		&.laboratory-page,
		&.art-tea-page {

			#cms-page .slide-wrapper .slide-text h1 {
				margin: 		10px 0;
				font-size: 		17px;
			}

		}

		&.sustainability-page main #cms-page .slide-wrapper .slide-text p,
		&.culture-page main #cms-page .slide-wrapper .slide-text p,
		&.tea-health-page main #cms-page .slide-wrapper .slide-text p,
		&.quality-page main #cms-page .slide-wrapper .slide-text p,
		&.art-tea-page main #cms-page .slide-wrapper .slide-text p,
		&.culture-page main #cms-page .culture-list li > span,
		&.laboratory-page main #cms-page .slide-wrapper .slide-text p,
		&.culture-page main #cms-page .culture-list li .subimg-wrapper,
		&.culture-page main #cms-page .culture-list.third li  > span .span-table-cell,
		&.tea-health-page main #cms-page ul li p {
			font-size: 	12px;
			line-height: 12px;
		}

		&.activity-event-page #cms-page .reviews .review-wrap .review-text-wrap {
			p.author, q, p {
				font-size: 	11px;
			}
		}

		&.culture-page #cms-page .culture-block span,
		&.sustainability-page #cms-page .environment-block span,
		&.sustainability-page #cms-page .environment-list span,
		&.tea-health-page #cms-page ul li h4,
		&.laboratory-page #cms-page .laboratory-block span {
			font-size: 13px;
		}
	}
	html {
		.sustainability-page {
			#cms-page {
				.environment-block {
					li {
    					padding: 0 5px;
					}
				}	
				.environment-list li img {
    				height: 155px;
				}
			}
		}
	}

	html .sustainability-page #cms-page .content-wrapper ul li,  
	html .sustainability-page #cms-page .content-wrapper ul li:nth-child(2),
	html .sustainability-page #cms-page .content-wrapper ul li:first-child,
	html .sustainability-page #cms-page .content-wrapper ul li:nth-child(3){
		width: 100%;
	    padding: 0px 0 10px 0;
	}

	.ahmad-cms-page.sustainability-page #cms-page .environment-block span ,
	.ahmad-cms-page.sustainability-page #cms-page .environment-list span{
    	font-size: 15px;
	}

	html .sustainability-page #cms-page .content-wrapper .block-bottom ul li:first-child{
		padding-top: 0;
	}

	html{
		.quality-page ,
		.cms-people-and-career,
		.cms-laboratory,
		.sustainability-page,
		.cms-culture,
		.activity-event-page,
		.cms-contact-us,
		.sustainability-page,
		.cms-uae-facility{
			#cms-page{
				.slide-text-style {
					.content-wrapper{
						h1{
		    			    line-height: 25px;
						}
					}
				}
			}	
		}
	}

	.cms-contact-us {
		.block-form{
			#contactForm{
				input, select, textarea{
					max-width: 100%;
				}
			}
		}
	}
}

@media screen and (max-width: @iphoneWidthM) {

	.ahmad-cms-page.sustainability-page main #cms-page .slide-wrapper .slide-text p{
    	line-height: 17px;
    	font-size: 16px;
	}

	.ahmad-cms-page.sustainability-page #cms-page .environment-block span{
		font-size: 15px;
	}

	.slide-text h3, .slide-text h3 + p{
		display: none;
	}

	html .sustainability-page #cms-page .environment-list li img {
    	height: 260px;
	}

	html .sustainability-page .slide-text-style .content-wrapper h1 {
    	top: 70px;
	}

	.quality-page .block-managemnt .content-wrapper h2 {
    	position: initial;
	}

	html .sustainability-page #cms-page .environment-list span {
    	padding: 0px;
	}

	html .cms-contact-us #cms-page .slide-text-style div#box-contact .box-right,
	html #cms-page .slide-text-style div#box-contact .box-left {
    	float: none !important;
    	width: 100% !important;
	}
	

	html{
		.quality-page,
		.cms-people-and-career,
		.cms-laboratory,
		.sustainability-page,
		.cms-culture,
		.activity-event-page,
		.cms-contact-us,
		.sustainability-page{
			#cms-page{
				.slide-text-style{
					.content-wrapper{
						h1{
							top: 0px;
						}
					}
				}
			}
		}

	}

}

@media screen and (max-width: @phoneWidthP) {

	.ahmad-cms-page.sustainability-page main #cms-page .slide-wrapper .slide-text p {
    	font-size: 14px;
	}

	.ahmad-cms-page.sustainability-page #cms-page .environment-block span {
    	padding: 0 10px;
    	text-align: center;
	}

	.ahmad-cms-page.sustainability-page #cms-page .slide-wrapper .slide-text h1{
		font-size: 15px;
	}

	html{
		.quality-page,
		.cms-people-and-career,
		.cms-laboratory,
		.sustainability-page,
		.cms-culture,
		.activity-event-page,
		.cms-contact-us,
		.sustainability-page{
			#cms-page{
				.slide-text-style{
					.content-wrapper{
						h1{
							font-size: 18px !important;
						}
					}
				}
			}
		}

	}

	.activity-event-page .slide-wrapper .slide-text-style.block-testing h2 {
	    padding: 10px 0px 0 0;
	    text-align: center;
	    left: 0;
	}

	html{
		.quality-page ,
		.cms-people-and-career,
		.cms-laboratory,
		.sustainability-page,
		.cms-culture,
		.activity-event-page,
		.sustainability-page {

			#cms-page{
				h1{
					padding: 0 20px;
					line-height: 25px;
				}
			}

		}
	}

	.cms-uae-facility{
		#cms-page{
			.slide-text-style{
				.content-wrapper{
					h1{
						line-height: 25px;
					}
				}
			} 
		}
		.facility-text-style{
			.content-wrapper{
				.layers{
					ul{
						li{
							font: normal normal 300 23px/25px 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
							display: flex;
    						align-items: center;
						}
					}
				}
			}
		}
	}

}