.cms-uae-facility{
	.content{
		width: 1024px;
		margin: 0 auto;
		&.respons{
			height: 100%;
			display: block;
			position: relative;
			max-width: 1024px;
			width: 100%;
		}
		@media screen and (max-width: @secondTabletWidth) {
			width: 900px;
		}
		@media screen and (max-width: 980px) {
			width: 700px;
		}
		@media screen and (max-width: @tabletContainerWidthG) {
			width: 94%;
		}
		@media screen and (max-width: @iphoneWidthL) {
			width: 94%;
		}
	}
	#cms-page{
		padding-bottom: 180px;
		.content-wrapper {
			width: 1024px;
			position: absolute;
			line-height: 30px;
			top: 0;
			left: 50%;
			transform: translate(-50%, 0);
			@media screen and (max-width: @secondTabletWidth) {
				width: 900px;
			}
			@media screen and (max-width: 980px) {
				width: 700px;
				left: 0;
				transform: translate(0, 0);
			}
			@media screen and (max-width: @tabletContainerWidthG) {
				width: 530px;
			}
			@media screen and (max-width: @iphoneWidthL) {
				width: 300px;
			}
			.h1 {
				width: 270px;
				text-transform: uppercase;
				font-variant: small-caps;
				font: normal normal 400 28px/normal @futuraStack;
				//#font > #futura> .lights(28px);
				padding-left: 0;
				text-shadow: none;
				margin-top: 80px;
				color:#093e2c;
				@media screen and (max-width: @secondTabletWidth) {
					font-size: 26px;
					text-align: left;
					width: 300px;
					margin-top: 65px;
				}
				@media screen and (max-width: 980px) {
					margin: 20px;
				}
				@media screen and (max-width: @tabletContainerWidthG) {
					font-size: 20px;
				}
				span {
					font: normal normal 400 28px/normal @futuraStack;
					//#font > #futura> .lights(28px);
					@media screen and (max-width: @secondTabletWidth) {
						font-size: 26px;
					}
					@media screen and (max-width: @tabletContainerWidthG) {
						font-size: 20px;
					}
				}
			}
		}
		.block-facility-titile{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0px;
			width: 100%;
			text-transform: uppercase;
			background: rgba(215, 222, 223, 0.81);
			color: #000;
			text-align: center;
			letter-spacing: 0.2em;
			padding: 15px 0;
			font-size: 20px;
			line-height: 26px;
			font-weight:300;
			@media screen and (max-width: @secondTabletWidth){
				padding: 6px 0;
				font-size: 21px;
			}
			@media screen and (max-width: 980px) {
				font-size: 18px;
			}
			@media screen and (max-width: @tabletContainerWidthG) {
				font-size: 15px;
				letter-spacing: 0.2em;
				bottom: 5px;
			}
			@media screen and (max-width: @iphoneWidthL) {
				display: none;
			}
		}
	}
	.block-facility-team {
		padding-top: 30px;
		.preview-text {
			margin-bottom: 35px;
		.team-text {
			color: #000000;
			font-size: 17px;
			font-weight: 300;
			line-height: 22px;
			text-align: center;
			margin:0;
			@media screen and (max-width: @secondTabletWidth) {
				font-size: 25px;
				line-height: 35px;
			}
			@media screen and (max-width: 980px) {
				font-size: 22px;
				line-height: 31px;
			}
			@media screen and (max-width: @tabletContainerWidthG) {
				font-size: 20px;
				line-height: 28px;
			}
			@media screen and (max-width: @iphoneWidthL) {
				font-size: 18px;
				line-height: 24px;
			}
		}
		}
		.team-item{
			background: #f5f5f6;
			display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;
			margin-bottom: 40px;
			@media screen and (max-width: @iphoneWidthL) {
				display: block;
			}
			.team-item-img{
				max-width: 195px;
				@media screen and (max-width: @secondTabletWidth){
					width: 250px;
				}
				@media screen and (max-width: 980px) {
					width: 200px;
				}
				@media screen and (max-width: @tabletContainerWidthG) {
					width: 150px;
				}
				@media screen and (max-width: @iphoneWidthL) {
					width: 100%;
					margin: 0 auto;
					text-align: center;
				}
				img{
					width: 100%;
					height: 100%;
					@media screen and (max-width: @tabletContainerWidthG) {
						height: auto;
					}
				}
			}
			.team-item-box{
				width: 100%;
				max-width: 860px;
				box-sizing: border-box;
				padding: 30px 39px 20px;
				@media screen and (max-width: @secondTabletWidth){
					width: 650px;
					padding: 30px;
				}
				@media screen and (max-width: 980px) {
					width: 500px;
					padding: 20px;
				}
				@media screen and (max-width: @tabletContainerWidthG) {
					width: 380px;
					padding: 15px;
				}
				@media screen and (max-width: @iphoneWidthL) {
					width: 100%;
				}
				.team-item-text{
					font-size: 16px;
					font-weight: 300;
					line-height: 22px;
					color: #013e31;
					text-align: left;
					@media screen and (max-width: @secondTabletWidth){
						font-size: 16px;
						line-height: 24px;
					}
					@media screen and (max-width: 980px) {
						font-size: 18px;
						line-height: 25px;
					}
					@media screen and (max-width: @tabletContainerWidthG) {
						font-size: 16px;
						line-height: 20px;
					}
				}
				.team-item-name{
					font-size: 16px;
					color: #013e30;
					font-weight: 500;
					text-align: right;
					margin:0;
					@media screen and (max-width: 980px) {
						font-size: 16px;
						line-height: 20px;
					}
				}
			}
		}
	}
	.block-facility{
		.content{
			position: relative;
			height: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			overflow: hidden;
			min-height: 500px;
			@media screen and (max-width: 980px){
				display: block;
				
			}
			 @media screen and (max-width: 750px){
			  min-height: 0;
			} 
			
		}

		&.block-facility-contact{
			.content{
				padding: 0 3px;
			}
		}
	}
	.block-facility-info{
		background: url(../images/facility/tour-bg.jpg) 50% 50%;
		background-size: cover;
		height: 100vh;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;

		.facility-info-block,
		.facility-info-video{
			background: rgba(12,12,12, 0.5);
			box-sizing: border-box;
			font-size: 16px;
			font-weight: 300;
			line-height: 28px;
			color: #fff;
			@media screen and (max-width: @secondTabletWidth){
				font-size: 18px;
				line-height: 24px;
			}
			p{
				font-size: 16px;
				@media screen and (max-width: @iphoneWidthL) {
					font-size: 18px;
				}
			}
		}
		.facility-info-block{
			width: 54%;
			padding: 45px;
			float: left;
			margin: 2% 0 2%;
			@media screen and (max-width: @secondTabletWidth){
				width: 50%;
				float:left;
				//padding: 25px;
			}
			@media screen and (max-width: 980px) {
				float: none;
				margin: 34px auto 20px;
				width: 100%;
			}
			@media screen and (max-width: @iphoneWidthL) {
				width: 100%;
				padding: 15px;
			}
			li{
				margin-bottom: 10px;
				@media screen and (max-width: @iphoneWidthL) {
					margin-left: 20px;
				}
			}
		}
		.facility-info-video {
		 	//width: 409px;
   			//padding: 33px 12px;
			//text-align: center;
			width: 44%;
			padding: 36px 12px;
			text-align: center;
			float: left;
			margin-left: 3%;
			@media screen and (max-width: @secondTabletWidth){
				width: 50%;
				//padding: 20px;
				//margin-top: 169px;
				min-height: 410px;
				float: left;
			}
			@media screen and (max-width: 980px) {
				float: none;
				margin: 0 auto;
				width: 100%;
				padding: 6px 12px;
			}
			@media screen and (max-width: @iphoneWidthL) {
				width: 100%;
			}
			iframe{
				@media screen and (max-width: @iphoneWidthL) {
					width: 100%;
				}
			}
		}
		@media screen and (max-width: 980px){
				height: 100%;
			}
	}
	.block-facility-quality{
		background: url(../images/facility/promise-bg.jpg) 100% 10%;
		background-size: cover;
		height: 100vh;
		.facility-quality-content{
			background: rgba(0,0,0, 0.40);
			box-shadow: 0 1px 2px #dfdfdf;
			padding: 2%;
			border-radius: 2% 2% 2% 59%;
			width: 62%;
			margin:3% 0;
			
			.quality-content-second{
				background: rgba(23, 13, 13, 0.2);
				border: 1px solid #fff;
				padding: 3%;
				border-radius: 2% 2% 2% 59%;
				min-height: 409px;
				@media screen and (max-width: 980px){
					border-radius:0;
					min-height: 270px;
				}
			}
			@media screen and (max-width: 980px){
				width: 96%;
				border-radius:0;
				margin: 11% 0;
			}
			@media screen and (max-width: 750px){
				width: 100%;
			}
		}
		@media screen and (max-width: 980px){
				background: url(../images/facility/promise-bg.jpg) 2% 10%;
				height: 100%;
			}
		@media screen and (max-width: @secondTabletWidth){
			min-height: auto;
			background: url(../images/facility/promise-bg.jpg) 32% 10%;
		}
		@media screen and (max-width: @iphoneWidthL) {
			background-position: 75% 100%;
			background: url(../images/facility/promise-bg.jpg) 32% 10%;
		}
		
	}
	.facility-title{
		padding: 1px 0 55px;
		color: #ffffff;
		font-size: 34px;
		font-weight: 300;
		line-height: 42px;
		text-transform: uppercase;
		position: relative;
		text-align: right;
		margin-bottom: 0;
		@media screen and (max-width: @secondTabletWidth){
			padding: 0 0 51px 0;
			font-size: 33px;
			line-height: 30px;
		}
		@media screen and (max-width: @tabletContainerWidthG) {
			padding: 0 0 51px 0;
			font-size: 30px;
			line-height: 35px;
		}
		@media screen and (max-width: @iphoneWidthL) {
			padding: 0 0 51px 0;
			font-size: 25px;
			line-height: 31px;
		}
		@media screen and (max-width: 980px) {
			
			text-align: center;
		}
		//&:before{
		//	content: url(../images/facility/dots.png);
		//	position: absolute;
		//  top: 88px;
		//	width: 39px;
		//	height: 11px;
		//	@media screen and (max-width: @secondTabletWidth){
		//		top: 43px;
		//	}
		//	@media screen and (max-width: @tabletContainerWidthG){
		//		top: 29px;
		//	}
		//	@media screen and (max-width: @iphoneWidthL) {
		//		display: none;
		//	}
		//}
		&:after{
			content: '';
			position: absolute;
			bottom: 35px;
			right: 0;
			height: 5px;
			background: url("../images/facility/line.jpg") 50% 50%;
			background-size: cover;
			@media screen and (max-width: @secondTabletWidth){
				bottom: 27px;
			}
			@media screen and (max-width: @iphoneWidthL) {
				bottom: 7px;
			}
			@media screen and (max-width: 980px) {
				margin: 0 auto;
   				 left: 0;
   				 right: 0;
			}
	
		}
	}
	.resp-content{
		position: absolute;
		top: 50%;
		bottom: 0;
		right: 0;
		display: block;
		transform: translateY(-350px);
	}
	.facility-text{
		color: #f5f2f2;
		font-size: 18px;
		font-weight: 400;
		line-height: 25px;
		text-align: right;
		padding: 0 2px;
		float: right;
		@media screen and (max-width: @secondTabletWidth){
			font-size: 16px;
			line-height: 28px;
		}
		@media screen and (max-width: @tabletContainerWidthG) {
			font-size: 16px;
			line-height: 26px;
			color: #f5f2f2;
		}
		@media screen and (max-width: @iphoneWidthL) {
			font-size: 16px;
			line-height: 24px;
		}
		&.resp{
			float: none;
			text-align: right;
			@media screen and (max-width: 980px) {
				text-align: left;
			}
		}
	}
	.quality-title{
		&:before{
			right: 290px;
			@media only screen and (max-width: @secondTabletWidth){
				right: 325px;
			}
			@media screen and (max-width: @tabletContainerWidthG){
				right: 250px;
			}
		}
		&:after{
			width: 260px;
			@media screen and (max-width: @secondTabletWidth){
				width: 310px;
			}
			@media screen and (max-width: @secondTabletWidth){
				width: 230px;
			}
			@media screen and (max-width: @iphoneWidthL) {
				width: 193px;
			}
			@media screen and (max-width: @iphoneWidthL) {			
				right: 0;
				left: 0;
				margin: 0 auto;
			}
		}
	}
	.quality-text{
		width: 100%;
		@media screen and (max-width: @iphoneWidthL) {
			width: 100%;
			//max-width: 180px;
		}
		@media screen and (max-width: 980px) {
			width: 100%;
			text-align: center;
		}
	}
	.block-facility-tasting{
		background: url(../images/facility/tasting-bg.jpeg) 100% 100%;
		background-size: cover;
		height: 100vh;
		@media screen and (max-width: @secondTabletWidth){
			min-height: auto;
		}
	}
	.tasting-title{
		&:before{
			right: 615px;
		}
		&:after{
			width: 355px;
		}
	}
	.tasting-text{
		width: 460px;
	}
	.block-facility-tasting{
		background: url(../images/facility/tasting-bg.jpeg) 100% 10%;
		background-size: cover;
		//min-height: 964px;
		height: 100vh;
		@media screen and (max-width: @secondTabletWidth){
			min-height: 540px;
		}
		@media screen and (max-width: @tabletContainerWidthG) {
			min-height: 330px;
		}
		@media screen and (max-width: 980px) {
			height: 100%;
			min-height: 0;
		}
	}
	.tasting-title{
		&:before{
			right: 615px;
			@media screen and (max-width: @secondTabletWidth){
				right: 440px;
			}
			@media screen and (max-width: @tabletContainerWidthG) {
				right: 330px;
			}
		}
		&:after{
			width: 355px;
			@media screen and (max-width: @secondTabletWidth){
				width: 355px;
			}
			@media screen and (max-width: @tabletContainerWidthG) {
				width: 313px;
			}
			@media screen and (max-width: @iphoneWidthL) {
				width: 260px;
			}
		}
	}
	.tasting-text{
		width: 100%;
		@media screen and (max-width: @iphoneWidthL) {
			width: 100%;
		}
	}
	.block-facility-sustainability{
		background: url(../images/facility/sustainability.jpg) 50% 50%;
		background-size: cover;
		height: 100vh;
		text-align: right;
		.facility-sustainability-content{
			background: rgba(0,0,0, 0.40);
			box-shadow: 0 1px 2px #dfdfdf;
			padding: 2%;
			border-radius: 2% 2% 2% 59%;
			width: 62%;
			margin:3% 0;
			.sustainability-content-second{
				background: rgba(23, 13, 13, 0.2);
				border: 1px solid #fff;
				padding: 3%;
				border-radius: 2% 2% 2% 59%;
				min-height: 409px;
				@media screen and (max-width: 980px){
					border-radius:0;
					min-height: 270px
				}	
				@media screen and (max-width: 750px){
					height: 370px;
				}
				 @media screen and (max-width: 450px){
					height: 445px;
				}  
			}
			@media screen and (max-width: 980px){
				width: 96%;
				border-radius:0;
				margin: 11% 0;
			}
			@media screen and (max-width: 750px){
				width: 100%;
			}
		}
		@media screen and (max-width: @secondTabletWidth){
			min-height: auto;
		}
		@media screen and (max-width: 980px){
			min-height: auto;
			height: 100%;
		}
		@media screen and (max-width: 750px){
			width: 100%;
		}
	}
	.sustainability-title{
		&:before{
			right: 310px;
			bottom: 153px;
			@media screen and (max-width: @secondTabletWidth){
				right: 328px;
			}
			@media screen and
			(max-width: @tabletContainerWidthG) {
				right: 248px;
			}
		}
		&:after{
			width: 260px;
			@media screen and (max-width: @secondTabletWidth){
				width: 300px;
			}
			@media screen and (max-width: @tabletContainerWidthG) {
				width: 220px;
			}
			@media screen and (max-width: @iphoneWidthL) {
				width: 190px;
			}
		}
	}
	.sustainability-text{
		width: 87%;
		@media screen and (max-width: @tabletContainerWidthG) {
			width: 100%;
		}
		@media screen and (max-width: 980px) {
			width: 100%;
			text-align: center;
		}
	}
	.block-facility-wedo{
		background: url(../images/facility/we-do-bg.jpg) 50% 50%;
		background-size: cover;
		height: 100vh;
		overflow: hidden;
		.facility-wedo-content{
			background: rgba(0,0,0, 0.40);
			box-shadow: 0 1px 2px #dfdfdf;
			padding: 2%;
			border-radius: 2% 2% 59% 2%;
			width: 62%;
			margin:3% 0;
			.wedo-content-second{
				background: rgba(23, 13, 13, 0.2);
				border: 1px solid #fff;
				padding: 3%;
				border-radius: 2% 2% 59% 2%;
				min-height: 409px;
				@media screen and (max-width: 980px){
						border-radius:0;
						min-height: 270px;
				}
			}
			@media screen and (max-width: 980px){
				width: 96%;
				border-radius:0;
				margin: 11% 0;
				min-height: 270px
			}
			@media screen and (max-width: 750px){
				width: 100%;
			}
		}
		@media screen and (max-width: @secondTabletWidth){
			min-height: auto;
		}
		@media screen and (max-width: 980px){
			height: 100%;
			
		}
		@media screen and (max-width: 750px){
			width: 100%;
		}
	}
	.facility-text.wedo-text,
	.responsibility-text-dots{
		padding: 0;
		position: relative;
		
	}
	.facility-text.wedo-text{
		&:before {
			top: 88px;
			left: 0;
			@media screen and (max-width: @tabletContainerWidthG) {
				top: 50px;
			}
			@media screen and (max-width: @iphoneWidthL) {
				display: none;
			}
		}
	}
	.wedo-text,
	.wedo-li{
		float: none;
		text-align: left;
	}
	.wedo-text{
		width: 503px;
		padding-left: 8%;
		@media screen and (max-width: @tabletContainerWidthG) {
			padding-left: 50px;
			width: 100%;
		}
		@media screen and (max-width: @iphoneWidthL) {
			padding-left: 25px;
		}
		@media screen and (max-width: 980px) {
			width: 100%;
		}
	}
	.wedo-li{
		position: relative;
		margin-left: 0;
		padding-bottom: 20px;
		position: relative;
		width: 87%;
		@media screen and (max-width: @iphoneWidthL) {
			padding-bottom: 15px;
		}
		&:before{
			content: url(../images/facility/check.png);
			position: absolute;
			top: 1px;
			left: -30px;
			width: 20px;
			height: 19px;
			@media screen and (max-width: @iphoneWidthL) {
				left: -24px;
			}
		}
	}
	.wedo2-li{
		position: relative;
		margin-left: 0;
		padding-bottom: 20px;
		position: relative;
		@media screen and (max-width: @iphoneWidthL) {
			padding-bottom: 15px;
		}
		&:before{
			content: url(../images/facility/check.png);
			position: absolute;
			top: 1px;
			left: -30px;
			width: 20px;
			height: 19px;
			@media screen and (max-width: @iphoneWidthL) {
				left: -24px;
			}
		}
	}
	.block-facility-responsibility{
		background: url(../images/facility/responsibility.jpg) 90% 50%;
		background-size: cover;
		height: 100vh;
		.facility-responsibility-content{
		    background: rgba(0,0,0, 0.40);
			box-shadow: 0 1px 2px #dfdfdf;
			padding: 2%;
			border-radius: 2% 2% 2% 59%;
			width: 62%;
			margin:3% 0;
			.responsibility-content-second{
			    background: rgba(23, 13, 13, 0.2);
				border: 1px solid #fff;
				padding: 3%;
				border-radius: 2% 2% 2% 59%;
				min-height: 440px;
				@media screen and (max-width: 980px){
					border-radius:0;
					min-height: 360px;
				}
			}
			@media screen and (max-width: 980px){
				width: 96%;
				border-radius:0;
				margin: 11% 0;
			}
			@media screen and (max-width: 750px){
				width: 100%;
			}
		}
		@media screen and (max-width: @secondTabletWidth){
			min-height: auto;
		}
		@media screen and (max-width: 980px) {
			height: 100%;
		}
		.responsibility-text{
			max-width: 20rem;
			float: right;
			@media screen and (max-width: @tabletContainerWidthG) {
				width: 100%;
			}
			@media screen and (max-width: @iphoneWidthL) {
				width: 100%;
			}
			@media screen and (max-width: 980px) {
				float: none;
    			padding-left: 8%;
				max-width: 100%;
			}
		}
		div{
			.responsibility-text-dots{
				width: 100%;
				margin-bottom: 2rem;
				@media screen and (max-width: @tabletContainerWidthG) {
					width: 100%
				}
				@media screen and (max-width: @iphoneWidthL) {
					width: 100%;
				}
				//&:before{
				//	top: 86px;
				//	left: -30px;
				//	@media screen and (max-width: @secondTabletWidth){
				//		top: 88px;
				//		left: -48px;
				//	}
				//	@media screen and (max-width: @tabletContainerWidthG) {
				//		top: 49px;
				//		left: -13px;
				//	}
				//	@media screen and (max-width: @iphoneWidthL) {
					//	display: none;
				   //}
				//}
			}
		}
	}

	.block-facility-contact{
		background: url(../images/facility/contact-dg.jpg) 100% 100%;
		background-size: cover;
		padding-bottom: 50px;
		height:100vh;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		div{
			overflow: hidden;
			.contact-title{
				font-size: 35px;
				padding: 25px 0 40px;
				text-align: center;					
				@media screen and (max-width: @iphoneWidthL) {
					font-size: 25px;
					padding: 30px 0 20px;
				}
				&:before{
					right: 217px;
					top: 69px;
				}
				&:after{
					width: 193px;
					margin: 0 auto;
					right: 0;
					left: 0;
					@media screen and (max-width: @iphoneWidthL) {
						width: 142px;
					}
				}
			}
			.contact-text-main {
				width: 100%;
   				text-align: center;
				@media screen and (max-width: @iphoneWidthL) {
					width: 100%;
				}
			}
			.contact-text-italic{
				font-style: italic;
			}
		}
		.contact-text{
			width: 100%;
    		text-align: center;
			@media screen and (max-width: @iphoneWidthL) {
				width: 100%;
			}
		}
		@media screen and (max-width: 980px) {
			height: 100%;
		}
	}
}
article#cms-page{
	padding-bottom: 92px;
}
.cms-uae-facility #cms-page .facility-info-block ul{
	list-style-type: disc;
}
.block-facility-top .h1{
	font-size: 26px;
	line-height: 36px;
}
.facility-sticky-header{
	display:none;
	padding: 1rem;
	position: fixed;
	z-index:1000000000;
	text-align: center;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	top:0;
	display: none;
	.sticky-list{
		margin: 0;
		li{
			display: inline-block;
			text-transform: uppercase;
			a{
				color:#fff!important;
				font-weight: 100!important;
				&.active{
					color:#093e2c!important;
					font-weight: 500!important;
				}
			}
		}
	}
}
.team-text{
	text-align: center;
	font-weight:500;
}
.block-facility{
	&.block-facility-tasting{
		.content{
			overflow: inherit;
			justify-content: left!important;	
			@media screen and (max-width: 980px){
				overflow: auto;
			}
		}
	    .facility-title.tasting-title{
			text-align: left;
			&::after{
				left: 0;
			}
			&::before{
				left:-65px;
			}
			@media screen and (max-width: 980px){
				text-align: center;
			}
		}
		.facility-text.tasting-text{
			text-align: left;
			float: left;
			@media screen and (max-width: 980px){
				text-align: center;
			}
		}
	}
	&.block-facility-wedo{
		.content{
			justify-content: left!important;	
		}
	}
	
}
.facility-tasting-content{
	background: rgba(0,0,0, 0.40);
    box-shadow: 0 1px 2px #dfdfdf;
    padding: 2%;
    border-radius: 2% 2% 59% 2%;
    width: 62%;
	margin: 3% 0;
	.tasting-content-second{
		background: rgba(23, 13, 13, 0.2);
		border: 1px solid #fff;
		padding: 3%;
		border-radius: 2% 2% 59% 2%;
		min-height: 409px;
		@media screen and (max-width: 980px){
			border-radius:0;
			min-height: 270px;
		}
	}
	@media screen and (max-width: 980px){
		width: 96%;
		border-radius:0;
		margin: 11% 0;
	}
	@media screen and (max-width: 750px){
		width: 100%;
	}

}

@media screen and (max-width: @iphoneWidthL) {
	.cms-uae-facility .block-facility-info,.cms-uae-facility .block-facility-quality,
	.cms-uae-facility .block-facility-tasting,.cms-uae-facility .block-facility-sustainability,
	.cms-uae-facility .block-facility-wedo,.cms-uae-facility .block-facility-responsibility,.cms-uae-facility .block-facility-contact{
		height: 100%;
	}
}

.facility-text{
	&.contact-text{
		&.contact-text-main{
			a{
				color:#a1ffb1 !important;
			}
		}
	}
}
.cms-uae-facility .responsibility-text .wedo-li:before {
	left: -13px;
}
.cms-uae-facility .responsibility-text .wedo-li:first-child:before{
	left: -13px;
}
.contact-details {
    box-shadow: 0 1px 2px #dfdfdf;
    background:rgba(0,0,0,.5);
    padding: 2%;
    width: 100%;
	margin-top: 4%;
	.contact-details-cont {
		background: rgba(23, 13, 13, 0);
		border: 1px solid #fff;
		width: 100%;
	}	
	@media screen and (max-width: 980px) {
		width: 96%;
		margin: 11% 0;
	}
	@media screen and (max-width: 750px){
		width: 100%;
	}
}
.block-facility-top > .slide-wrapper {

	@media screen and (max-width: 450px){
		min-height: 212px!important;
	}
}