.cms-sample-request {
	#cms-page {
		padding-top: 0;

		h2 {
			margin-bottom: 8px;
			#font > #trajan > .regular(23px,30px);
		}
	}

	.sample-request-form {
		margin: 62px 0;

		.form-list {
			max-width:	624px;
			width:		100%;
			list-style:	none;

			.clearfix;

			li {
				&.fields {
					width: 40%;
					float: left;

					&:first-child {
						margin-left: 0;
					}

					.field {
						width:		100%;
						margin:		30px 0;
						position:	relative;

						label {
							position:	absolute;
							top:		5px;
							left:		10px;
						}

						.input-text {
							background: #f1f1f1;
						}

						&:hover {
							.input-text {
								background: #f4f4f4;
							}
						}

						&.hovered {
							label {
								top:	-25px;
								left:	0;
							}

							.input-text {
								background: #f4f4f4;
							}
						}

						.input-text {
							width:		100%;
							border:		0;
							padding:	10px;
							cursor:		pointer;
						}
					}
				}

				&.wide {
					width:		86%;
					float:		left;
					margin:		4px 0 0;

					.field {
						position:	relative;

						label {
							position:	absolute;
							top:		5px;
							left:		10px;
						}

						.input-text {
							background: #f4f4f4;
						}

						&.hovered {
							label {
								top:	-25px;
								left:	0;
							}

							.input-text {
								background: #f1f1f1;
							}
						}

						&:hover {
							.input-text {
								background: #f4f4f4;
							}
						}

						.input-text {
							width:		100%;
							border:		0;
							padding:	10px;
							cursor:		pointer;
						}

						textarea {
							width:	100%;
							height:	100%;
						}
					}
				}
			}
		}

		button {
			.ui-btn;
			.ui-btn.green;
		}
	}

	.contact-header {
		padding:		0 0 48px;
		border-bottom:	1px solid grey;

		img {
			width: 100%;
		}

		.text {
			padding: 40px 0 0;
		}
	}

	.contact-office {
		margin:	60px 0 30px;
	}

	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		.sample-request-form {
			margin-top: 48px;

			.form-list li.fields, .form-list li.wide {
				width:		100%;
				float:		none;
				margin-left:0;
			}
		}

	}
}