html,
body {
	height: 100%;
}
body {
	&:after {
		content: '';
		position: fixed;
		top: -100%;
		left: -100%;
		z-index: 9999;
		background-color: rgba(0,0,0,0.8);
		.background-img('loading.gif',no-repeat,center,center);
		.square(100%);
		opacity: 0;
		.backface-visibility(hidden);
		.transition('opacity 0.25s ease, top 0s ease 0.25s, left 0s ease 0.25s');
	}
	&.loading {
		&:after {
			top: 0;
			left: 0;
			opacity: 1;
			.transition('opacity 0.25s ease, top 0s ease 0s, left 0s ease 0s');
		}
	}
	&.scrolling-page {
		.wrapper {
			&:after {
				display: none;
			}
		}
	}
}
.wrapper {
	max-width:  100%;
	min-height: 100%;
	height: auto !important;
	height: 100%;
	margin-bottom: -@footerHeight;
	&:before {
		content: '';
		.square(100%);
		position: fixed;
		top: -100%;
		left: -100%;
		z-index: 9998;
		background-color: rgba(0,0,0,0.6);
		opacity: 0;
		.transition('opacity 0.25s ease, top 0s ease 0.25s, left 0s ease 0.25s');
	}
	&:after {
		content: '';
		display: block;
		.size(100%,@footerHeight);
	}
}
ol.unstyled,
ul.unstyled {
	margin: 0;
	li {
		list-style-type: none;
		margin: 0;
	}
}
.preload {
	position: absolute;
	left: -9999px;
	top: -9999px;
	visibility: hidden;
}
.clear {
	width: 0;
	height: 0;
	line-height: 0;
	font-size: 0;
	display: block;
	visibility: hidden;
	overflow: hidden;
	clear: both;
}
.clearfix {
	.clearfix();
}
.none {
	display: none;
}
.debug {
	position: fixed;
	top: 0;
	left: 0;
	background-color: #fff;
	color: #000;
	.square(100%);
	z-index: 9999;
	overflow: auto;
	font-family: 'Courier New', Courier, monospace;
	font-size: 18px;
	line-height: 15px;
	.box-sizing(border-box);
	padding: 25px 0 25px 25px;
}
.pattern {
	padding-bottom: 140px;
	.background-img('pattern.png',repeat-x,center,bottom);
}
main {
	&.col-left, &.col-right {
		> div {
			width: @maxWidth;
			margin: 0 auto;

			> article {
				display: inline-block;
				width:	 @maxWidth - @sidebarWidth - @gutter;

				.box-sizing(border-box);

				.page-title {
					h1 {
						#font > #playfair > .regular(16px,16px);
						margin-bottom: @gutter;
						padding-bottom: (@gutter / 2);
						border-bottom: solid 1px @grey3F;
					}
				}

				.hello strong, .box-head h2 {
					#font > #futura > .heavy(20px);
					.kerning(10);
					color: @grey3F;
				}

				.box-head h2 {
					margin-bottom: 1em;
				}

				.welcome-msg {
					#font > #futura > .book(16px,24px);
					color: @grey3F;
				}

				.box-reviews, .box-recent {
					display: none;
				}

				.col2-set {
					.clearfix();
					.col-1, .col-2 {
						width: 50%;
						display: inline-block;
						float: left;
						.box-sizing(border-box);
					}

					.col-1 {
						padding-right: (@gutter / 2);
					}

					.col-2 {
						padding-left: (@gutter / 2);
					}
				}

				.box-title {
					margin-bottom: 10px;

					h3 {
						#font > #futura > .medium(18px);
						.kerning(10);
						color: @grey3F;
						display: inline-block;

						&:after {
							content: '\007C';
							margin: 0 3px 0 5px;
							color: @grey3F;
						}

						&.no-after {
							&:after {
								display: none;
							}
						}
					}

					a {
						#font > #futura > .medium(18px);
						color: @greenLt;
						text-decoration: none;
						.transition('color 0.25s ease');

						&:hover {
							color: @green;
						}
					}
				}

				.box-content {
					#font > #futura > .book(16px,24px);
					color: @grey3F;

					a {
						#font > #futura > .medium(16px);
						color: @greenLt;
						text-decoration: none;
						.transition('color 0.25s ease');

						&:hover {
							color: @green;
						}
					}

					h4 {
						#font > #futura > .medium(16px);
						.kerning(10);
						color: @grey3F;
					}

					address {
						#font > #futura > .book(16px,24px);
						padding-top: 5px;
						font-style: normal;
					}
				}

				.my-account {
					> p {
						#font > #futura > .book(16px,24px);
						color: @grey3F;
					}
				}

				.product-review {
					.product-details {
						.box-head {
							h2 {
								margin: 0;
							}
						}
					}
					.product-rating-details {
						.box-title {
							margin: 0;
						}
					}
					.ratings {
						padding-top: 10px;
					}
					p {
						#font > #futura > .book(16px,24px);
						color: @grey3F;
						margin-bottom: @gutter;
					}
				}
			}
			> aside {
				display: inline-block;
				width: @sidebarWidth;
				.box-sizing(border-box);
				.block-title {
					margin-bottom: @gutter;
					padding-bottom: (@gutter / 2);
					border-bottom: solid 1px @grey3F;
					strong {
						#font > #playfair > .regular(16px,16px);
					}
				}
				.block-content {
					ul {
						list-style-type: none;
						li {
							margin: 0 0 (@gutter * 1.5) 0;
							&.last {
								margin-bottom: 0;
							}
							strong {
								#font > #futura > .heavy(16px);
								.kerning(10);
								color: #000;
							}
							a {
								#font > #futura > .book(16px);
								.kerning(10);
								color: #000;
								text-decoration: none;
								.transition('color 0.25s ease');
								&:hover {
									color: @greenLt;
								}
							}
						}
					}
				}
			}
		}
	}
	&.col-right {
		> div {
			> article {
				float: right;
				margin-right: @gutter;
			}
			> aside {
				float: right;
			}
		}
	}
	&.col-left {
		> div {
			> article {
				float: left;
				margin-left: @gutter;
			}
			> aside {
				float: left;
			}
		}
	}

	@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) {
		&.col-left > div, &.col-right > div {
			width: 96%;
			margin:0 2%;
		}
	}

	@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: landscape) {
		&.col-left > div, &.col-right > div {
			width: 100%;
			margin:0;
		}
	}

	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		&.col-left > div, & .col-right > div {
			width: 100%;
			margin:0;

			> aside {
				width: 100%;
				float: none;
			}

			> article {
				float: none;
				width: 100%;
			}
		}
	}
}
.messages {
	width: 100%;
	margin: 0;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	background-color: @greenMsg;
	overflow:	hidden;
	color:		@green;

	.transition('height 0.5s cubic-bezier(0.895, 0.030, 0.685, 0.220)');
	#font > #futura > .medium(16px);

	&.hide {
		height: 0 !important;
	}

	li {
		list-style-type: none;
		margin: 0;
	}

	> li {
		position:	relative;
		z-index:	2;
		padding:	(@gutter / 2) 0;

		&.error-msg {
			background-color:	@redMsg;
			color:				@grey28;
			padding-left: 		26px;
			padding-right:		26px;
		}
	}
	ul,ol {
		margin: 0 auto;
		max-width: @maxWidth;
	}
}

// tablet and mobile versions


// mobile
@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
	html { box-sizing: border-box;  }

	body{
		width: 100%;
		padding-top:53px;
		overflow-x: hidden;
		margin:0;

		&.menu-active{
			.wrapper,#footer,#header{
				margin-left: -139px;
			}
		}
	}

	.messages {
		width:		98%;
		position:	relative;
		margin:		10px 1%;
		background:	none;

		li {
			margin: 0 20px;
		}

		&.hide {
			margin: 0;
		}
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	#home-promo-code{  margin:0; }

	.mobile-hidden {  display: block;  }

	.desktop-hidden {  display: none;  }

	img {
		max-width: 100%;
		height: auto;
	}

	ul {
		list-style: none;
		padding: 0;
	}

	main { position: relative; }

	a,a:visited{
		color: #005e20;
		text-decoration: none;
	}

	a:hover{ color: #409e60; }

	.clearfix:after {
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}

	.ui-btn{
		#font#futura.book(10px);

		padding:		9px 15px;
		text-transform:	uppercase;

		&.green {
			color:		#073727;
			border:		2px solid #679c75;
			background:	none;
		}

		&.big {
			width:			100%;
			display:		block;
			border:			none;
			padding:		20px;
			color:			#fff;
			background:		#093e2c;
			text-align:		center;
			text-transform:	uppercase;

			.border-radius(2px);
			#font#futura.book(13px);

			&.orange {
				background:#ec8a00;
			}
		}
	}

	.ui-btn

	.wrapper,#footer,#header{
		margin-left: 0;
		width: 100%;
		display: block;
		.transition(all .3s);
	}

	.swipe-container {
		overflow: hidden;
		visibility: hidden;
		position: relative;
	}

	.swipe {
		margin:		0;
		overflow:	hidden;
		position:	relative;
	}

	.swipe > li, .swipe > div {
		position:	relative;
		float:		left;
		width:		100%;
	}

	#swipe-elements b {
		display:	inline-block;
		background:	#d1c8b8;
		width:		10px;
		height:		10px;
		margin:		5px;

		.inner-shadow(1px,1px,4px,0,0.5);
		.border-radius(10px);

		&.active{
			background: #ff931f;
		}
	}

	.checkout-onepage-index #pattern{
		background-color: #fef7ed;
	}

	.ratings{
		margin:			5px 0;
		line-height:	18px;
		text-transform:	uppercase;
		color:			#005e20;

		a {
			#font#futura.heavy(12px);
			text-transform: uppercase;
		}

		.rating-box {
			display:		inline-block;
			vertical-align:	top;
			width:			120px;
			height:			17px;
			overflow:		hidden;
			text-align:		left;

			.rating{
				display: inline-block;
				width: 85px;
				margin-right:5px;
				height: 17px;
				.background-size(17px,16px);
				.background-img("global/star-grey.png",repeat-x,0,0);

				span{
					height: 17px;
					display: inline-block;
					.background-size(17px,16px);
					.background-img("global/star-gold.png",repeat-x,0,0);
				}
			}
		}
	}
	#tea-finder{
		z-index: 2;
		position: relative;
		padding: 20px 5%;
		text-align: center;
		.background-img("global/tea-finder-bgd.png",no-repeat,center,8px);
		height: 95px;

		.ssg-navigatedown{
			display: none;
		}

		select {
			background: none;
			border: none;
			margin:5px 0;
			#font#playfair.regular(13px);
			color: #a69782;
		}

		>strong {
			#font#playfair.regular(13px);
			display: block;
			margin:5px 0;
		}

		#tea-finder-list {
			display: none;
		}
	}

	#cart-order-totals {
		margin: 30px 0;

		li{
			#font#futura.book(13px);
			text-transform: uppercase;
			margin: 20px 0;

			.price{
				float: right;
			}

			&.grand-total {
				.price {
					#font#futura.heavy(16px);
				}
			}
		}
	}

	#shopping-cart-footer{
		margin:		20px;
		background:	#989898;
		padding:	20px;
		color:		#fff;
		text-align:	center;

		#font#futura.book(14px);

		a {
			color:			#fff;
			vertical-align:	middle;

			&:before{
				vertical-align: middle;
			}

			span{
				margin-left:5px;
			}
		}

		strong{
			text-transform: uppercase;
			#font#futura.heavy(14px);
		}
	}

	#shopping-cart-items {
		input {
			width:		45px;
			background:	#f2f2f2;
			border:		none;

			#font#futura.heavy(11px);
			padding:	10px;
			text-align:	center;
		}

		.row{
			border-bottom:1px solid #9fa1a0;
			position: relative;
			padding: 15px;

			h4{
				margin:0 0 10px 0;

				a{
					#font#futura.heavy(14px);
					color: #3f4442;
				}
			}

			.ssg-delete{
				position: absolute;
				top:10px;
				right:5px;
			}

			.price,.subtotal{
				#font#futura.heavy(11px);
				margin: 0 4px;
			}

			.subtotal{
				color: #005e20;
			}
		}

		.col{
			display: inline-block;
			vertical-align: top;

			&:nth-child(1){
				width:35%;
			}

			&:nth-child(2){
				width:62%;
			}
		}
	}

	#breadcrumbs {
		width:		100%;
		height:		47px;
		position:	relative;
		background:	#f9f5ee;
		overflow:	hidden;
		padding:	25px 0 30px;

		&.open {
			height: auto;
		}

		&:after{
			position:	absolute;
			content:	"";
			width:		11px;
			height:		7px;
			left:		93%;
			z-index:	5;
			top:		20px;

			.background-size(11px, 7px);
			.background-img("global/navigatedown.png", no-repeat, 95%, center);
		}

		.teanav { display: none; }

		&.unstyled {
			display: block;
			float: none;

			&.teanav {
				display: none;
			}

			&.right {
				float: none;
			}
		}

		li {
				float:	 none;
				display: block;

				a {
					width:				100%;
					padding:			15px 15px 15px 25px;
					color:				#a69782;
					text-align:			left;

					#font#playfair.regular(12px);

					&:after { display: none; }

					&:before { display: none; }

					&.active { display: block; }
				}
			}
	}

	.success-msg {
		padding:	10px;
		background:	#DFF2BF;
		border:		1px solid #1c8301;
	}

	.error-msg {
		padding:	10px;
		background:	#9c5b64;
		color:		#fff;
		margin-bottom: 20px;
		border:1px solid #bd6069;
	}

	.cms-index-index .success-msg{
		margin:20px;
	}

	.product-list{
		max-width:	260px;
		margin:		0 auto;

		.spacer{
			display: none;
		}

		li{
			float:left;
			line-height:50px;
			margin:20px 0;
			width:50%;
			position: relative;
			border-right:1px solid #e9e9e9;
			margin-top:40px;

			&.hide {
				display: none;
			}

			a {
				strong {
					margin:0 15% 0 15%;
					width:70%;
					min-height: 60px;
					display: block;
					#font#playfair.regular(14px);
					color: #282828;
				}

				.type{
					display: none;
				}

				.price{
					#font#futura.heavy(12px);
					display: block;
					margin:0 15% 0 15%;
					width:70%;
					color: #3f4442;
				}

				.iced {
					#font > #futura > .book(10px,11px);
					text-transform: uppercase;
					text-align: center;
					display: block;
					color: #fff;
					.square(45px);
					position: absolute;
					bottom: 0;
					left: 25px;
					&:after {
						content: '';
						background-color: @orangeFF4;
						.square(100%);
						position: absolute;
						top: -13px;
						left: 0;
						z-index: 1;
						.rotate(45deg);
					}
					&:before {
						content: '';
						.size(50%,30%);
						position: absolute;
						bottom: -4px;
						left: 25%;
						z-index: 0;
						opacity: 0.35;
						.gradient(ellipse, center center farthest side, #000 0%, #fff 100%);
						.border-radius(100%);
						.transform('rotate3d(-0.1,0,0,115deg)');
					}
					span {
						position: relative;
						z-index: 2;
					}
				}
				.img{
					margin-top:20px;
					overflow: hidden;
					display: block;
				}
				button{
					display: none;
				}
				img{
					width: 100%;
					position: relative;
				}
			}
		}
	}
}
