
/* new header */
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:400,400italic&subset=latin-ext,latin);
.goog-te-menu2-item div, .goog-te-menu2-item:link div, .goog-te-menu2-item:visited div, .goog-te-menu2-item:active div{
	color:#093E2C !important;
}
body{
	opacity: 1 !important;
}
#catalogSearch {
	padding: 0 30px 0 0;
	.transition('padding-left 0.25s ease');

	&.show {
		padding-left: 25px;

		#search_mini_form {
			width: 130px;

			&:after {
				width: 100%;

				.transition('width 0.25s ease 0.1s');
			}
		}
	}

	i.ssg-search {
		color: @tanLt;
		font-size: 17px;
		position: absolute;
		top: 13px;
		right: 0;
		cursor: pointer;
	}
}

#search_mini_form {
	position: relative;
	width: 0;
	overflow: hidden;

	.transition('width 0.25s ease');

	&:after {
		content: '';
		background-color: @tanLt;
		position: absolute;
		bottom: 0;
		right: 0;

		.size(0, 1px);
		.transition('width 0.25s ease');
	}
}

#search {
	background: none;
	border: none;
	text-transform: uppercase;
	padding: 15px 0 1px 0;
	color: @tanLt;
	display: block;

	.remove-search();
	.border-radius(0);

	#font > #futura > .book(12px, normal);
	.kerning(50);

	&::-webkit-input-placeholder {
		opacity: 0.5;
		color: @tanLt;
	}

	&:-moz-placeholder {
		opacity: 0.8;
		color: @tanLt;
	}

	&::-moz-placeholder {
		opacity: 0.8;
		color: @tanLt;
	}

	&:-ms-input-placeholder {
		opacity: 0.8;
		color: @tanLt;
	}

	&:-o-input-placeholder {
		opacity: 0.8;
		color: @tanLt;
	}
}

#header {
	.size(100%, @headerHeight);
	background-color: @greyF2;
	position: relative;
	z-index: 100;
	#google_translate_element {
		.goog-te-gadget {
			white-space: nowrap;
			background: #093e2c;
			border: 1px solid #d0d0d0;
			font: italic normal 400 12px/23px 'Playfair Display',Times,'Times New Roman',Georgia,serif;
			color: #d0d0d0;
			letter-spacing: .05em;
			.goog-te-gadget-simple {
				font: inherit;
				background: none;
				border: none;
				padding: 0;
				img {
					display: none;
				}
				.goog-te-menu-value {
					color: #d0d0d0;
					> span:nth-child(3) {
						display: none;
					}
					> span:last-child,
					> span:nth-last-child(1) {
						color:#d0d0d0;
						margin:0 0 0 5px;
					}
				}
			}
		}
	}

	.container {
		max-width: @desktopWidth;
		margin: 0 auto;
		.clearfix();
	}

	#topNav {
		z-index: 1;
		background: @green;

		.size(100%, @headerBar);

		#catalogSearch, #topLinks, #mod {
			position: relative;
			float: right;
			height: @headerBar;

			.backface-visibility(hidden);
		}
	}

	.middle-content {
		max-width: @desktopWidth;
		margin: 0 auto;
		position: relative;

		> strong {
			position: absolute;
			top: -5px;
			left: 0;
			z-index: 200;
			background-color: @green;

			.size(@logoWidth, 110px);

			a {
				position: absolute;
				top: -5px;
				left: -2%;
				overflow: hidden;
				text-indent: -9999px;
				display: block;

				.background-img('logo-640.png', no-repeat, center, center);
				.background-size(201px, auto);
				.size(210px, 110px);
			}
		}
	}

	@media only screen and (max-width: @tabletWidthL) and (min-width: 980px) {
		& {
			max-width: 100%;
		}

		.container {
			margin: 0 1%;
		}

		.middle-content {
			margin: 0 1%;
			> strong {
			}
		}
	}

	@media only screen and (min-width: @tabletWidthP) and (max-width: 980px) {
		& {
			max-width: 100%;
		}

		#nav {
			&:after {
				width: 100%;
			}
		}

		.container {
			margin: 0 1%;
		}

		.middle-content {
			margin: 0 1%;

			> strong {
			}

			.cart {
				.text {
					display: none;
				}
			}
		}
	}
	li {
		&:hover {
			.drop {
				max-height:none;
				opacity:1;
				.transition('opacity 0.25s ease');
				padding:0 50px 20px;
			}
		}
	}
	.drop {
		position: absolute;
		left:10px;
		right:10px;
		top:100%;
		padding: 0 50px;
		background: @greyF2;
		overflow: hidden;
		max-height:0;
		opacity:0;
		.transition('opacity 0.25s ease');
		ul {
			overflow: hidden;
			font:13px/18px @futuraStack;
			text-transform: uppercase;
			margin:0;
			li {
				margin:10px 0 0;
				ul {
					margin:0 0 40px;
				}
				&:last-child {
					ul {
						margin:0;
					}
				}
			}
		}
		> .description {
			float: right;
			width:45%;
			font:15px/20px @futuraStack;
			//color: @black;
			img {
				display: block;
				max-width: 100%;
				height: auto;
				margin:auto;
				position: absolute;
				top:50%;
				left:50%;
				-moz-transform: translateY(-50%) translateX(-50%);
				-webkit-transform: translateY(-50%) translateX(-50%);
				-o-transform: translateY(-50%) translateX(-50%);
				-ms-transform: translateY(-50%) translateX(-50%);
				transform: translateY(-50%) translateX(-50%);
				.transition('all 0.25s ease');
			}
			.image-holder {
				overflow: hidden;
				margin:0 0 25px;
				position: relative;
				top: 0 !important;
			}
		}
		> ul {
			> li {
				> a {
					font-size:15px;
					line-height:20px;
				}
			}
			a {
				text-decoration: none;
				//color: @black;
				&:hover {
					color: @green;
				}
			}
		}
	}
}

#nav {
	> ul.unstyled {
		margin: 0 0 0 @logoWidth;
		z-index: 100;
		position: relative;
		.box-sizing(border-box);

		> li {
			float: left;
			z-index: 1;

			&:not(.touch):hover, &.hover {
				div {
					opacity: 1;
					z-index: 100;
					//top: @navHeight;

					.transition('opacity 0.25s ease');
				}

				> a {
					background-color: #fff;
				}
			}

			&.cart {
				float: right;

				a {
					padding: 0;
					#font > #futura > .book(12px, @navHeight);
					.kerning(50);
					.transition('none');

					&.active:hover, &:hover {
						background-color: @greyF2;
						color: @green;
					}

					i {
						font-size: 19px;
						position: relative;
						top: 2px;
						margin-left: 10px;
					}
				}
			}

			&.exposed {
				div {
					left: 0;
				}
			}

			.simple-block {
				position: absolute;
				top: -9999px;
				left: -1px;
				background: #fff;
				padding: 0 @logoWidth @navGutter 0;
				width: 100%;
				opacity: 0;
				border: solid 1px @greyF2;
				border-top: none;
				z-index: 9999;

				//.box-sizing(border-box);
				.transition('opacity 0.25s ease, top 0s ease 0.25s');

				ul {
					width: 100%;
					float: left;

					a {
						display: block;
						padding: 0 (@navGutter / 2) 0 @navGutter;
						color: @grey28;
						text-decoration: none;
						text-transform: uppercase;
						background-color: #fff;

						.transition('color 0.25s ease');
						#font > #futura > .book(12px, (@subNavHeight / 2));
						.kerning(50);

						&:hover {
							color: @greenLt;
						}
					}
				}

				p {
					width: 208px;
					float: left;
					margin: 0 -(@navGutter + @logoWidth) 0 0;
				}

				img {
					width:		@logoWidth;
					height:	auto;
					display:	block;
					margin-bottom: (@navGutter / 2);
				}

				span {
					display: inline-block;
					padding-right: @navGutter;
					color: @grey28;

					#font > #futura > .book(14px, 21px);
					.kerning(15);
				}

				&.gifts-sub {
					ul li {
						min-width: 175px;
					}

					&:not(.touch) {
						min-width: 120px;
					}

					p {
						width:		151px;
						margin:	0 -199px 0 0;
						float:		right;

						img {
							width: 163px;
						}
					}
				}

			}

			.teasbar-sub {
				position: absolute;
				top: -9999px;
				left: 0;
				background: #fff;
				padding: 0 0 13px 0;
				width: 100%;
				min-height: 260px;
				height: auto;
				overflow: hidden;
				opacity: 0;
				border: solid 0 @greyF2;
				border-top: none;
				z-index: 9999;
				&.small-bar {
					width: 309px;
					left: 210px;
					min-height: 245px;
					padding-bottom: 0;
					color: #404040;
					text-transform: uppercase;
					#font > #futura > .book(12px, 22px);
					.kerning(50);
					.tb-item-head {
						display: none;
					}
					.teasbar-item.preview-block {
						width: 115px;
						padding: 16px 13px 0 0;
						float: right;
						margin: 0 0 0 -15px;
						.tb-preview-image {
							padding: 0;
							img {
								width: 100%;
							}
						}
					}
					.tb-item-products {
						float: left;
						padding: 0 0 0 27px;
						width: 160px;
						.featured-products {
							margin: 0 0 20px;
						}
						.categories {
							margin: 0 0 -30px;
						}
						.teaware-item {
							position: absolute;
							right: 29px;
							bottom: 10px;
							font-weight: bold;
						}
					}
					a {
						color: #404040;
						text-decoration: none;
						&:hover {
							color: #005e20;
						}
					}
				}
				.box-shadow('0px 6px 12px rgba(0,0,0,.1)');

				.teasbar-item {
					width: 158px;
					float: left;

					&:last-child {
						width: 162px;

						.tb-item-products ul li {
							width: 162px;
						}
					}

					.tb-item-head {
						height: 53px;
						line-height: 53px;
						text-align: left;
						padding: 0 10px;
						color: #fff;
						background: @green;
						text-transform: uppercase;

						#font > #futura > .book(15px, 53px);

						a {
							text-decoration: none;
							color: #fff;

							&:hover {
								border-bottom: 2px solid #fff;
							}

							.box-sizing(border-box);
						}
					}

					&.preview-block {
						width: 230px;
					}

					.tb-preview-image {
						float: left;
						padding: 15px 0 0 50px;

						img {
							width: 68%;
							height: auto;
						}
					}

					.tb-item-products {
						padding: 10px 0 0 0;

						.clearfix;

						ul {
							width: 100%;
							text-align: left;
							float: left;
							text-transform: uppercase;

							#font > #futura > .book(12px, 22px);

							li {
								width: 158px;
								padding:  0;
								line-height: 34px;

								&:hover {
									background: #f1f1f1;
								}
							}

							a {
								display: block;
								padding: 0 5px 0 7px;
								width: 150px;
								text-decoration: none;
								color: #282828;
							}
						}
					}
				}

				@media screen and (min-width: @phoneWidthP) and (max-width: @tabletWidthL) {
					& {
						left: 0 !important;
					}
				}
			}

			> a {
				display: block;
				text-transform: uppercase;
				text-decoration: none;
				color: @grey43;
				padding: 0 @navGutter;
				height: 60px;
				background-color: @greyF2;

				.transition('background-color 0.25s ease');
				#font > #futura > .book(15px, 60px);
				.kerning(75);

				&:hover {
					background-color: #fff;
				}

				&.active {
					color: @greenLt;
				}
			}

			@media screen and (min-width: @phoneWidthP) and (max-width: @tabletWidthL) {
				&.teasbar {
					position: relative;
				}
			}

			@media screen and (min-width: (@tabletWidthL + 1)) {
				&.teasbar {
					.simple-block {
						display: none;
					}
				}
			}
		}
	}

	@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: portrait) {
		ul.unstyled li {
			div.tea-nav-last {
				left: auto;
				right: 0;
				padding: 0 0 30px 207px;

				a {
					padding: 0 0 0 25px;
				}

				span {
					padding-right: 0;
					padding-left: 11px;
				}

				ul {
					width: 95%;
					float: right;
				}

				p {
					margin: 0 0 0 -208px;
				}
			}
		}
	}
}

// expose nav on homepage
.cms-index-index {
	#header.exposed {
		height: @headerExposedHeight;
	}

	#nav {
		> ul.unstyled {
			> li {
				&.exposed {
					position: static;

					&:hover > a {
						background-color: @greyF2;

						&:hover {
							background-color: #fff;
						}
					}

					div {
						width: auto;
						height: @subNavHeight !important;
						top: @navHeight !important;
						background: @greyF2;
						padding: 0 !important;
						margin: 0 0 0 224px !important;
						opacity: 1;
						z-index: 0;

						.transition('none');

						p, img, span {
							display: none;
						}

						ul {
							li {
								float: left;
							}

							a {
								height: @subNavHeight;
								background: none;
								padding: 0 (@navGutter / 2);
								text-transform: uppercase;
								color: #000;
								text-decoration: none;

								#font > #futura > .book(12px, @subNavHeight);
								.transition('color 0.25s ease');
								.kerning(100);

								&:hover {
									color: @green;
								}

								&.active {
									color: @greenLt;
								}
							}
						}
					}
				}
			}
		}
	}

}

#topLinks {
	li {
		float: left;
		#font > #playfair > .italic(12px, @headerBar);
		.kerning(50);

		&:after {
			content: '\007C';
			color: @greyD0;
			display: inline-block;
			margin: 0 5px;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}

		a {
			color: @greyD0;
			text-decoration: none;
		}
	}
}

#mod {
	padding-right: 25px;
	color: #fff;

	#font > #playfair > .italic(12px, @headerBar);
	.kerning(50);

	&:empty {
		display: none;
	}
}

#breadcrumbs {
	max-width: @maxWidth;
	margin: 0 auto;
	padding: 75px 0 30px;
	text-transform: uppercase;
	color: @grey5C;

	.kerning(100);
	#font > #futura > .book(12px, 12px);

	li {
		display: table-cell;

		&:after {
			content: '\002F';
			display: inline-block;
			margin: 0 5px;
		}

		&:last-child {
			&:after {
				content: '';
			}
		}
	}

	a {
		color: @grey5C;
		text-decoration: none;

		.transition('color 0.25s ease');

		&:hover {
			color: @greenLt;
		}
	}
}

body {
	&.firefox #search {
		margin-left: 0;
	}

	&.safari {
		#search {
			-webkit-appearance: none;
		}
	}
}

@media screen and(min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
	@import "mobile/header";
}

@media screen and(min-width: @phoneWidthP) and (max-width: @phoneWidthL) and (orientation: landscape) {

	#header #mobile-nav .other-links {
		position: relative;
		margin-top: 20px;
	}

	#catalogSearch {
		i.ssg-search {
			//right: 22%;
			left: auto;
		}
	}
}
@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) {
	#breadcrumbs {
		padding: 74px 12px 30px;
	}

	.cms-index-index {
		#nav > ul.unstyled > li.exposed div ul a {
			padding: 0 5px;
		}

		#nav ul.unstyled li div.tea-nav-last ul {
			width: 100%;
		}

		#nav > ul.unstyled > li.exposed div {
			left: 234px;
			margin-left: 0 !important;;
		}
	}

	#nav > ul.unstyled {
		margin: 0 0 0 126px;
		> li > a {
			font-size: 14px;
		}
	}

	#header {
		.middle-content {
			> strong {
				width:		125px;
				height:	65px;

				a {
					width:		124px;
					height:	65px;
					background-size: 128px auto;
				}
			}
		}
	}
}
#nav > ul.unstyled > li > a {
	padding: 0 20px!important;
	font: normal normal 400 14px/60px 'futura-pt', Arial, 'Helvetica Neue', Helvetica, sans-serif!important;
}
#header .drop > ul > li > a,#header .drop > ul a{
	color:#404040;
}

#header .drop > ul > li > a:hover{
	color:#005e20;
}
@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: portrait) {
	#nav > ul.unstyled {
		margin: 0 0 0 135px;
	}
}
@media screen and (max-width: 750px) and (min-width: 320px){
	#header #mobile-nav .other-links {
		position: initial;
		margin: 2rem 0;
	}
}
.blog-post-view .postContent {
	text-align: justify;
}