@media screen and (max-width: @tabletWidthL) {

	.activity-event-page {

		#cms-page {

			.activity-table td.no-image .img-text,
			.activity-table.second .no-image,
			.develop-block p,
			.develop-block ul li span {
				font-size: 		14px;
				line-height: 	normal;
			}

			.activity-table td {

				&.no-image h3 { font-size: 	30px; }

				.img-text {
					font-size: 			19px;
					margin-bottom: 		0;
					line-height: 		normal;
				}

			}

			.develop-block h3 {
				font-size: 	30px;
				padding: 	20px 0;
			}

		}

	}

}

@media screen and (max-width: @tabletWidthP) {

	.activity-event-page {

		#cms-page {

			.activity-table td {

				&.no-image h3 { font-size: 	21px; }

				.img-text { font-size: 	14px; }

			}

			.develop-block h3 { font-size: 	21px; }

		}

	}

}

@media screen and (max-width: @mobileWidth) {

	.activity-event-page {

		#cms-page {

			.activity-table td,
			.activity-table.second td {
				float: 	left;
				width: 	100%;

				&.no-image {
					padding: 	25px 0;

					h3 {
						font-size: 		22px;
						margin-bottom: 	10px;
					}

				}

			}

			.develop-block {

				ul {
					margin: 0;

					li {
						float: 				left;
						width: 				50%;
						padding-bottom: 	15px;
					}
				}

				h3 {
					font-size: 	22px;
					padding: 	15px 0;
				}

			}

		}

		.reviews {
			padding-top: 	20px;
			margin-bottom: 	10px;

			.review-wrap .review-text-wrap {

				&:before {
					content: none;
				}

				q, p { margin-bottom: 	5px; }

			}

		}

	}

}

@media screen and (max-width: @secondMobileWidth) {

	.activity-event-page {

		#cms-page {

			.activity-table td.no-image .img-text,
			.activity-table.second .no-image,
			.develop-block p,
			.develop-block ul li span {
				font-size: 	12px;
			}

		}

	}

}
