#font {
	// shorthand: style variant weight size/line-height stack;
	#base {
		.regular(@size: @baseFontSize, @line: @baseLineHeight) {
			#font > .shorthand(@baseStack,@baseWeight,@size,@line);
		}
		.bold(@size: @baseFontSize, @line: @baseLineHeight) {
			#font > .shorthand(@baseStack,@boldWeight,@size,@line);
		}
	}

	#futura {
		.book(@size: @baseFontSize, @line: @baseLineHeight) {
			#font > .shorthand(@futuraStack,400,@size,@line);
		}
		.medium(@size: @baseFontSize, @line: @baseLineHeight) {
			#font > .shorthand(@futuraStack,500,@size,@line);
		}
		.heavy(@size: @baseFontSize, @line: @baseLineHeight) {
			#font > .shorthand(@futuraStack,700,@size,@line);
		}
		.lights(@size: @baseFontSize, @line: @baseLineHeight) {
			#font > .shorthand(@futuraStack, 100, @size, @line);
		}
		.light(@size: @baseFontSize, @line: @baseLineHeight) {
			#font > .shorthand(@futuraStack,200,@size,@line, italic);
		}
		.bold(@size: @baseFontSize, @line: @baseLineHeight) {
			#font > .shorthand(@futuraStack,800,@size,@line);
		}
	}

	#trajan {
		.regular(@size: @baseFontSize, @line: @baseLineHeight) {
			#font > .shorthand(@trajanStack,400,@size,@line);
		}
		.bold(@size: @baseFontSize, @line: @baseLineHeight) {
			#font > .shorthand(@trajanStack,800,@size,@line);
		}
		.italic(@size: @baseFontSize, @line: @baseLineHeight) {
			#font > .shorthand(@trajanStack,400,@size,@line,italic);
		}
	}

	#playfair {
		.regular(@size: @baseFontSize, @line: @baseLineHeight) {
			#font > .shorthand(@playFairStack,400,@size,@line);
		}
		.italic(@size: @baseFontSize, @line: @baseLineHeight) {
			#font > .shorthand(@playFairStack,400,@size,@line,italic);
		}
		.heavy(@size: @baseFontSize, @line: @baseLineHeight) {
			#font > .shorthand(@playFairStack,800,@size,@line);
		}
	}

	.shorthand(@stack, @weight: normal, @size: @baseFontSize, @line: @baseLineHeight, @style: normal, @variant: normal) {
		font: @style @variant @weight ~"@{size}/@{line}" ~`"@{stack}".replace((/[\[\]]/g),'')`;
	}
	.settings() {
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
	}
}
