.cms-health-benefits {
    .slide-wrapper {
        .content {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            @media screen and (max-width: 980px) {
                position: relative;
                left: 0;
                transform: translate(0, 0);
            }
        }
    }
}
.cms-health-benefits,
.blog-post-view,
.cms-laboratory{
    .content,
    .content-second{
        width: 1024px;
        margin: 0 auto;
        @media screen and (max-width: @secondTabletWidth) {
            width: 900px;
        }
        @media screen and (max-width: 980px) {
            width: 700px;
        }
        @media screen and (max-width: @tabletContainerWidthG) {
            width: 530px;
        }
        @media screen and (max-width: @iphoneWidthL) {
            width: 300px;
        }
    }
}
.cms-health-benefits{
    .health-top{
        padding: 70px 0 0;
        text-align: right;
        overflow: hidden;
        @media screen and (max-width: 980px) {
            text-align: left;
        }
        @media screen and (max-width: @tabletContainerWidthG) {
            padding: 20px 0 0;
        }
        .health-title{
            color: #093e2c;
            font-size: 30px;
            font-weight: 400;
            line-height: 32px;
            text-transform: uppercase;
            position: relative;
            margin-bottom: 0;
            padding-bottom: 40px;
            font: normal normal 400 28px/normal @futuraStack;
			//#font > #futura> .lights(28px)!important;
            @media screen and (max-width: @secondTabletWidth) {
                padding-bottom: 25px;
            }
            &:before{
                content: url(../images/facility/dots.png);
                position: absolute;
                top: -4px;
                right: 241px;
                width: 39px;
                height: 11px;
                //@media screen and (max-width: @secondTabletWidth) {
                //    top: -6px;
                //    right: 257px;
                //}
                //@media screen and (max-width: 980px){
                //    left: 245px;
                //}
                @media screen and (max-width: @iphoneWidthL){
                    display: none;
                }
            }
            &:after{
                content: '';
                position: absolute;
                bottom: 23px;
                right: 0;
                height: 5px;
                background: url(../images/facility/line.jpg) 50% 50%;
                background-size: cover;
                width: 214px;
                @media screen and (max-width: @secondTabletWidth) {
                    //width: 234px;
                    bottom: 12px;
                }
                @media screen and (max-width: 980px) {
                    left: 0;
                }
            }
        }
        .health-text{
            width: 290px;
            color: #434343;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            float: right;
            @media screen and (max-width: @secondTabletWidth) {
                color: #000;
            }
            @media screen and (max-width: 980px) {
                width: 100%;
                float: none;
            }
        }
    }
    .health-blog{
        padding: 50px 0;
        @media screen and (max-width: @tabletContainerWidthG) {
            padding: 20px 0;
        }
        .widget-latest{
            ul{
                display: -webkit-inline-box;
                display: -ms-inline-flexbox;
                display: inline-flex;
                -ms-flex-pack: distribute;
                justify-content: space-around;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                li{
                    width: 280px;
                    margin-left: 0;
                    font-size: 16px;
                    font-weight: 300;
                    margin-bottom: 15px;
                    line-height: 20px;
                    @media screen and (max-width: @secondTabletWidth) {
                        width: 250px;
                    }
                    @media screen and (max-width: @tabletContainerWidthG) {
                        width: 220px;
                    }
                    .img{
                        padding-bottom: 20px;
                        display: block;
                    }
                    .post-date{
                        color: @grey43;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        font-weight: 300;
                       font: normal normal 400 16px/normal @futuraStack;
					   // #font > #futura> .lights(16px);
                        @media screen and (max-width: @secondTabletWidth) {
                            margin-bottom: 5px;
                        }
                        @media screen and (max-width: @tabletContainerWidthG) {
                            font-size: 20px;
                        }
                        @media screen and (max-width: @iphoneWidthL) {
                            font-size: 17px;
                        }
                    }
                    .post-title{
                        font-weight: 300;
                        margin-bottom: 5px;
                        @media screen and (max-width: @tabletContainerWidthG) {
                            font-size: 20px;
                        }
                        @media screen and (max-width: @iphoneWidthL) {
                            font-size: 17px;
                        }
                    }
                    .descr{
                        color: @grey43;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}
.slide-wrapper.health-box{
    background-image:url("../images/health-bg.jpg");
    width: 100%;
    display: block;
    min-height: 640px;
    background-repeat: no-repeat;
    background-size: cover;
}
@media screen and (max-width: 845px) and (min-width: 300px){
    .slide-wrapper {
        min-height: 300px!important;
    }
}