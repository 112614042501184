#charity {

	> img {
		display: block;
		width: 100% !important;
		height: auto !important;
	}

	> section {
		max-width: @maxWidth;
		margin: 0 auto 90px auto;

		&:nth-child(even) {
			> div, > aside {
				float: right;
			}

			> div {
				padding-left: @recipePostGutter;
				padding-right: 0;
			}

			> aside {
				span {
					right: inherit !important;
					left: 1px;
					&.ssg-navigateright {
						left: 32px;
					}
				}
			}
		}

		> div, > aside {
			display: inline-block;
			width: @recipePostColWidth;
			float: left;
		}

		> div {
			position: relative;
			padding-right: @recipePostGutter;
			padding-top: 65px;
			color: @grey3F;

			#font > #futura > .book(16px, 24px);
			.kerning(-25);

			&:before {
				content: '';
				.size(100%, 5px);
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		> aside {
			.size(@recipePostColWidth, 500px);
			overflow: hidden;
			position: relative;

			img {
				display: block;
				width: @recipePostColWidth;
				height: 500px;
			}

			span {
				position: absolute;
				bottom: 1px;
				right: 1px;
				z-index: 101;
				.square(30px);
				#rgba > .bg(#fff, 0.45);
				font-size: 28px;
				color: @grey3F;
				cursor: pointer;
				.transition('color 0.25s ease, background-color 0.25s ease');
				&.ssg-navigateleft {
					right: 32px;
				}
				&:hover {
					color: @greenLt;
					#rgba > .bg(#fff);
				}
			}
		}

		h1 {
			#font > #playfair > .regular(30px, 30px);
			letter-spacing: normal;
			color: @greenLt;
		}

		h2 {
			#font > #playfair > .regular(24px, 18px);
			letter-spacing: normal;
			color: @greenLt;
			margin-bottom: @recipePostGutter;
			position: relative;
			padding-left: 55px;
			&:after {
				content: '';
				.square(35px);
				position: absolute;
				left: 0;
				bottom: -1px;
				background-color: @greenLt;
				z-index: 0;
			}
			&:before {
				color: #fff;
				position: absolute;
				bottom: 7px;
				left: 1px;
				z-index: 1;
				font-size: 33px;
			}
		}

		h3 {
			#font > #futura > .heavy(14px, 24px);
			.kerning(15);
			text-transform: uppercase;
		}

		ul {
			li {
				margin-left: 17px;
			}
			&.unstyled {
				li {
					margin-left: 0;
				}
			}
			&.colorlist {
				padding: @recipePostGutter 0;
				li {
					#font > #futura > .heavy(18px, 30px);
					color: @charityBlueDk;
					&:first-child {
						color: @charityRedDk;
					}
					&:last-child {
						color: @greenLt;
					}
				}
			}
		}
		p {
			&.quote {
				#font > #playfair > .italic(14px, 24px);
				.kerning(15);
				color: @grey80;
				q {
					display: block;
				}
			}
		}
	}

	@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		> section {
			> div {
				width:			100%;
				padding-right:	20px;
				padding-left:	20px;
				float:			none;
				display:		block;
			}

			> aside {
				width:	100%;
				height:	auto;
				float:	none;
				display:block;

				img {
					width:	100%;
					height:	auto;
				}
			}

			&:nth-child(even) {
				> div {
					width:			100%;
					padding-right:	20px;
					padding-left:	20px;
				}
			}
		}
	}

	@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) {
		> section {
			max-width: 100%;

			> div {
				width:			46%;
				padding-right:	2%;
				padding-left:	2%;
				padding-top:	50px;
				float:			left;
			}

			> aside {
				width:	50%;
				float:	right;
				margin: 0;

				img {
					width: 100%;
				}
			}

			&:nth-child(even) {
				> div {
					float:			right;
					width:			46%;
					padding-right:	2%;
					padding-left:	2%;
				}

				> aside {
					float: left;
				}
			}
		}
	}

	@media screen and (min-width: @tabletWidthP)
	and (max-width: @tabletWidthL)
	and (orientation: portrait) {
		> section {
			width: 100%;

			&:nth-child(even) {
				float:	none;

				> div {
					float:			none;
					width:			96%;
					padding-right:	2%;
					padding-left:	15px;
				}

				> aside {
					width:		100%;
					padding:	0;
					float:		none;
				}
			}

			> div {
				width:			96%;
				padding-right:	4%;
			}

			> aside {
				width:		100%;
				padding:	0;

				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}

#charity-subnav {
	.size(@maxWidth, @scrollingSubNavHeight);
	margin: 0 auto;

	li {
		display: inline-block;
		width: ceil(@maxWidth / 5);
		text-align: center;
		float: left;

		&:last-child {
			width: (@maxWidth - (ceil(@maxWidth / 5) * 4));
		}

		a {
			display: block;
			.size(100%,@scrollingSubNavHeight);
			#font > #futura > .book(12px,@scrollingSubNavHeight);
			.kerning(100);
			text-transform: uppercase;
			color: @grey3F;
			text-decoration: none;

			.transition('color 0.25s ease');

			&:hover {
				color: @greenLt;
			}
			&[href="#charity-healthcare"]:hover {
				color: @charityRed;
			}
			&[href="#charity-child"]:hover {
				color: @charityGreen;
			}
			&[href="#charity-education"]:hover {
				color: @charityOrange;
			}
			&[href="#charity-community"]:hover {
				color: @charityYellow;
			}
			&[href="#charity-environment"]:hover {
				color: @charityBlue;
			}
		}
	}

	 @media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
		& {
			width:	100%;
			height:	auto;
		}

		li {
			 width:			90%;
			 text-align:	left;
			 padding:		0 5%;
		}
	 }

	@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) {
		& {
			width:	100%;
			padding:0 0 0;
		}
	}

	@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) and (orientation: portrait) {
		li {
			width:		auto;
			padding:	0 25px;

			&:last-child {
				width: auto;
			}
		}
	}
}
#charity-achievements {
	> div {
		&:before {
			background-color: @greenLt;
		}
	}
}
#charity-healthcare {
	> div {
		&:before {
			background-color: @charityRed;
		}
	}
	h2 {
		color: @charityRed !important;
		&:after {
			background-color: @charityRed !important;
		}
		&:before {
			left: 8px !important;
		}
	}
}
#charity-child {
	> div {
		&:before {
			background-color: @charityGreen;
		}
	}
	h2 {
		color: @charityGreen !important;
		&:after {
			background-color: @charityGreen !important;
		}
		&:before {
			left: 3px !important;
		}
	}
}
#charity-education {
	> div {
		&:before {
			background-color: @charityOrange;
		}
	}
	h2 {
		color: @charityOrange !important;
		&:after {
			background-color: @charityOrange !important;
		}
		&:before {
			left: 5px !important;
			font-size: 26px !important;
		}
	}
}
#charity-community {
	> div {
		&:before {
			background-color: @charityYellow;
		}
	}
	h2 {
		color: @charityYellow !important;
		&:after {
			background-color: @charityYellow !important;
		}
		&:before {
			left: 0 !important;
			font-size: 23px !important;
		}
	}
}
#charity-environment {
	> div {
		&:before {
			background-color: @charityBlue;
		}
	}
	h2 {
		color: @charityBlue !important;
		&:after {
			background-color: @charityBlue !important;
		}
		&:before {
			left: 4px !important;
			font-size: 27px !important;
		}
	}
}
#charity-question {
	text-align: center;
	#font > #futura > .book(14px,24px);
	.kerning(-25);
	color: @grey3F;
	h4 {
		#font > #playfair > .regular(30px,25px);
		letter-spacing: normal;
		margin-bottom: 25px;
	}
	a {
		color: @greenLt;
		text-decoration: none;
		.transition('color 0.25s ease');
		&:hover {
			color: @greenDk;
		}
	}
}