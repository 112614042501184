.activity-event-page {

	.reviews .review-wrap div,
	table img, table div {
		width: 			50%;
		display: 		inline-block;
		vertical-align: middle;
		.box-sizing(border-box);
	}

	.reviews {
		background: 	@block-bg-first;
		padding-top: 	65px;
		margin-bottom: 	15px;

		.review-wrap {

			div {
				line-height: 0;

				* { line-height: normal; }

				q {
					margin-bottom: 		65px;
					display: 			block;
					font: normal normal 500 32px/normal @futuraStack;
					//#font > #futura > .light(32px,38px);
				}

			}

			.review-text-wrap {
				padding: 0 5%;
				position: relative;

				&:before {
					content:		'';
					position: 		absolute;
					background: 	url('../images/cms-pages/triangle1.png') no-repeat 0 0;
					width: 			52px;
					height: 		101px;
					top:			50%;
					left: 			-40px;
					margin-top: 	-60px;
				}

				p {
					font: normal normal 500 25px/normal @futuraStack;
					//#font > #futura > .medium(25px,normal);

					&.author {
						margin-bottom: 5px;
						font: normal normal 500 34px/normal @futuraStack;
						//#font > #futura > .bold(34px,normal);
					}

				}

			}

			img { width: 100%; }

			* { color: @text-color-first; }

		}

		&.review-first .review-wrap {
			background: @block-bg-second;
		}

		&.review-second .review-wrap  {
			background: @block-bg-third;

			.review-text-wrap:before {
				background: 	url('../images/cms-pages/triangle2.png') no-repeat 0 0;
				right: 			-40px;
				left: 			inherit;
			}

		}

	}

	#cms-page {

		> section { max-width: 1170px; }

		.activity-table {
			list-style: 	none;
			margin-bottom: 	30px;

			td {
				width: 			50%;
				margin-left: 	0;
				background: 	@list-bg-first;

				* {
					color: 		@green;
				}

				.img-text {
					font: normal normal 500 28px/30px @futuraStack;
					//#font > #playfair > .heavy(28px,30px);
					padding: 		0 10%;
					margin-bottom: 	27px;
				}

				div {
					text-align: center;
				}

				&.li-bg {
					background: @list-bg-second;
				}

				&.li-purple-bg {
					background: @list-bg-third;
				}

				&.li-green-bg {
					background: @list-bg-fourth;
				}

				&.no-image {
					background: none;

					div {
						width: 	100%;
					}

					.img-text {
						margin-bottom: 	0;
						padding: 		0 5%;
						font: normal normal 500 24px/28px @futuraStack;
						//#font > #futura > .heavy(24px,28px);
					}

					h3 {
						margin: 0 0 25px 0;
						font: normal normal 500 43px/normal @playFairStack;
						//#font > #playfair > .heavy(43px,normal);
					}

				}

			}

			&.second {
				margin-bottom: 35px;

				.no-image {
					padding: 		0 2%;
					text-align: 	center;
					color: 			@green;
					font: normal normal 500 20px/26px @futuraStack;
					//#font > #futura > .heavy(20px,26px);
				}

			}

		}

		.develop-block {
			background: @block-bg-fourth;

			.develop-text-wrapper {
				padding: 0 7%;
			}

			p {
				text-align: 	center;
				margin-bottom: 	28px;
				font: normal normal 500 24px/25px @futuraStack;
				//#font > #futura > .heavy(24px,25px);
			}

			h3 {
				text-align: center;
				padding: 	28px 0 34px;
				margin: 	0;
				font: normal normal 500 42px/normal @playFairStack;
				//#font > #playfair > .heavy(42px,normal);
				
			}

			ul {
				width: 			100%;
				list-style: 	none;
				display: 		inline-block;

				li {
					width: 		25%;
					float: 		left;
					margin: 	0;
					position: 	relative;

					img { width: 	100%; }

					span {
						text-align: 	center;
						display: 		block;
						padding: 		8px 0 0;
						font: italic normal 400 22px/25px 'Playfair Display',Times,'Times New Roman',Georgia,serif;
						//#font > #playfair > .heavy(22px,25px);
					}

				}


			}

			* { color: @green; }

		}

	}

	ul.activities-ul{
		margin-bottom: 0;
		background: #005847;
		li{
			display: inline-block;
			width: 33.33%;
			margin-left: 0;
			vertical-align: middle;
			text-align: center;
			span{
				color: #fff;
			    font-size: 29px;
			    display: inline-block;
			    padding: 0 8px;
			    line-height: 35px;
			}
		}
	}

	.top-activities-second{
		li:first-child{
			width: 30%;
		}
		li:last-child{
			width: 70%;
		}
	}

	.line {
		background: #005847;
		color: #fff;
		text-align: center;
		font-size: 20px;
		min-height: 44px;
		line-height: 44px;
	}

	.slide-wrapper{
		.slide-text-style.block-testing{
			h2 {
			    text-align: left;
			    width: 40%;
			    float: right;
			    font-size: 60px !important;
			    color: #005847;
			}
		}
	}

}