.sustainability-page,
.tea-health-page,
.culture-page,
.quality-page,
.laboratory-page,
.art-tea-page,
.cms-quality-promise,
.cms-people-and-career,
.cms-activities-events,
.cms-contact-us,
.cms-experts,
.cms-uae-facility,
.cms-tea-health,
.cms-laboratory,
.cms-health-benefits{
	#cms-page {
		padding-top: 0;

		ul { list-style: none; }

		> section { max-width: 100%; padding: 0; width: 100%; }

		.content-wrapper {
			max-width: 1170px;
			margin:    0 auto;
		}

		.slide-wrapper {
			position: 		relative;
			line-height: 	0;
			display:		block;
			vertical-align: middle;
			width: 			100%;
			height: 		100%;

			.table-cell {
				position:	absolute;
				left: 		0;
				top: 		0;
				bottom: 	0;
				right: 		0;
				height: 	100%;
				overflow: auto;
			}

			img { width: 		100%; }

			.slide-text {
				width: 				100%;
				text-align: 		center;
				display: 			table;
				vertical-align: 	middle;
				height: 			100%;

				> div {
					display: 			table-cell;
					vertical-align: 	middle;
					height: 			100%;
					padding: 			0 25%;
					@media (max-width:1024px) {
						padding: 0 5%;
					}
				}

				* {
					color: 			@text-color-first;
					line-height: 	normal;
				}
				.box-title,
				h1 {
					font:60px/65px @begumReg;
					text-transform: 	none;
					margin-bottom: 		25px;
					@media (max-width:1024px) {
						font-size: 30px;
						line-height: 35px;
						text-shadow: 0 0 4px #000;
					}
					@media (max-width:767px) {
						font-size: 20px;
						line-height: 25px;
						padding-top: 15px;
					}
				}

				p {
					font:22px/30px @begumBoldReg;
					@media (max-width:1024px) {
						font: 15px/20px @begumReg;
						text-shadow: 0 0 4px #000;
					}
				}

			}

			.spinner {
				background: url(../images/bg-spinner.png) no-repeat 50% 50%;
				font-family:@begumBoldReg;
				position: relative;
				padding:210px 0;
				width:560px;
				@media (max-width:767px) {
					background: none;
					padding: 0;
					margin: 0 -10px;
					width: 100%;
					text-shadow: 0 0 4px #000;
				}
				.top,
				.top-right,
				.right,
				.bottom-right,
				.bottom,
				.bottom-left,
				.left,
				.top-left {
					position: absolute;
					width: 150px;
					text-align: center;
					font-size:20px;
					line-height:30px;
					@media (max-width:767px) {
						font-size: 14px;
						line-height: 19px;
						position: static;
						text-align: left;
						padding: 5px 0 5px 30px;
						background: url(../images/arrow1.png) no-repeat 0 50%;
						background-size: 24px 18px;
						width: auto;
					}
				}
				.top {
					left: 198px;
					top: 8px;
					@media (max-width:767px) {
						
					}
				}
				.top-right {
					right: 67px;
					top: 71px;
					@media (max-width:767px) {

					}
				}
				.right {
					right: 12px;
					top: 191px;
					@media (max-width:767px) {

					}
				}
				.bottom-right {
					right: 49px;
					bottom: 90px;
					@media (max-width:767px) {

					}
				}
				.bottom {
					bottom: 21px;
					left: 206px;
					@media (max-width:767px) {

					}
				}
				.bottom-left {
					bottom: 68px;
					left: 56px;
					@media (max-width:767px) {

					}
				}
				.left {
					left: 3px;
					top: 191px;
					@media (max-width:767px) {

					}
				}
				.top-left {
					left: 52px;
					top: 87px;
					@media (max-width:767px) {

					}
				}
				.center {
					width:125px;
					display: block;
					margin:0 auto;
					text-align: center;
					font-size:30px;
					line-height:31px;
					@media (max-width:767px) {
						font-size: 20px;
						line-height: 25px;
						width: 300px;
					}
				}
			}

		}

	}

}
.sustainability-page,
.culture-page,
.laboratory-page {

	#cms-page {

		.block-middle,
		.environment-block,
		.culture-block,
		.laboratory-block {
			background: @green;

			.content-wrapper { line-height: 0; }

			span {
				color: @text-color-first;
				#font > #futura > .book(23px,29px);
				padding: 0 15px;
				display: inline-block;
			}

			ul {
				display: 	inline-block;
				width: 		100%;
				margin: 	0;

				li {
					text-align: 	center;
					margin: 		0;
					line-height: 	normal;
					display: 		inline-block;
					vertical-align: middle;
					float: 			none;
					.box-sizing(border-box);
				}

			}

		}

	}

}
img{
	max-width: 100%;
	display: block;
	height: auto;
}


#cms-page {
	.about-box {
		padding:55px 0;
		@media (max-width:767px) {
			padding: 0;
		}
		.image-holder {
			float: left;
			margin:0 30px 0 0;
			@media (max-width:1024px) {
				width: 40%;
				margin: 0 20px 20px 0;
			}
			@media (max-width:767px) {
				margin: 0 0 20px;
				float: none;
				width: 100%;
			}
			img {
				display: block;
				width: 100%;
				height: auto;
				margin:0 auto;
			}
		}
		.text-holder {
			overflow: hidden;
			font:18px/30px @begumReg;
			color: @text-color-second;
			@media (max-width:1024px) {
				overflow: visible;
				padding: 0 10px;
				font-size: 15px;
				line-height: 20px;
			}
			h2 {
				font-size:45px;
				line-height:50px;
				font-weight: normal;
				color: @green;
				margin:0 0 5px;
				@media (max-width:1024px) {
					font-size: 30px;
					line-height: 35px;
				}
			}
		}
	}
	.img-transformed {
		float: left;
		margin:0 33px 0 0;
	}
	.achieve-box {
		background: url(../images/bg-achieve.jpg) no-repeat 50% 45px;
		padding:123px 0 80px;
		-moz-column-count: 3;
		-moz-column-gap: 40px;
		-webkit-column-count: 3;
		-webkit-column-gap: 40px;
		column-count: 3;
		column-gap: 40px;
		font:22px/27px @begumReg;
		color: @text-color-second;
		@media (max-width:1024px) {
			background-position: 50% 0;
			padding: 60px 0 10px;
		}
		@media (max-width:767px) {
			-moz-column-count: 1;
			-moz-column-gap: 0px;
			-webkit-column-count: 1;
			-webkit-column-gap: 0px;
			column-count: 1;
			column-gap: 0px;
			font-size: 15px;
			line-height: 20px;
			padding: 60px 10px 10px;
		}
		> * {
			page-break-inside: avoid;
			text-align: center;
			padding:70px 0 0;
			@media (max-width:767px) {
				padding: 0 0 10px;
			}
			&.center {
				font:30px/35px @begumBoldReg;
				padding:20px 0 0;
				@media (max-width:767px) {
					padding: 0 0 10px;
					font-size: 15px;
					line-height: 20px;
				}
				h2 {
					font-size:30px;
					line-height:35px;
					margin:0 0 35px;
					text-transform: none;
					@media (max-width:767px) {
						margin:0 0 10px;
						font-size: 22px;
						line-height: 27px;
					}
				}
			}
		}
		h2 {
			font:22px/27px @begumBoldReg;
			text-transform: uppercase;
			color: @text-color-second;
			margin:0 0 30px;
			@media (max-width:767px) {
				margin:0 0 10px;
			}
		}
	}
}
ul.disc {
	li {
		list-style:outside disc;
		margin-left:20px;
	}
}
.colored-box {
	background: @green;
	text-align: center;
	font:20px/28px @begumBoldReg;
	color: #fff;
	padding:25px 0;
	margin:0 0 46px;
	clear: both;
	@media (max-width:1024px) {
		font-size: 15px;
		line-height: 20px;
		padding: 10px 0;
	}
}