#faq {
	> img {
		width: 100% !important;
		height: auto !important;
		display: block;
	}

	.faq-header {
		margin:			 0 auto;
		color:			 #093e2c;
		width:			 100%;
		height:		 	 415px;
		background-position: center 20%;
		background-repeat:	no-repeat;
		background-size: 	cover;
		text-align:		 	center;

		.content {
			width:		300px;
			margin:		0 auto 0;
			padding:	50px 0 0 102px;
		}

		h2 {
			#font > #trajan > .regular(48px,24px);
			position:		relative;
			font-weight:	lighter;
			text-align:		center;
			padding:		115px 0 50px;

			&.small-text {
				#font > #trajan > .regular(35px,37px);
			}
		}

		p {
			position:		relative;
			font-family:	futura-pt;
			color:			#093e2c;
			font-size:		17px;
			text-align:		center;
			font-weight:	normal;

			&.green {
				color: #093e2c;
			}
		}
	}

	> section {
		max-width: @maxWidth;
		margin: 	60px auto 0 auto;
		color:		@grey3F;

		h2 {
				#font > #futura > .book(24px,24px);
				font-weight: bold;
		}

		&:last-child {
			margin-bottom: 60px;
		}

		a {
			color: @greenLt;
			text-decoration: none;
			.transition('color 0.25s ease');

			&:hover {
				color: @green;
			}
		}
	}

	h1 {
		#font > #futura > .book(24px,24px);
		.kerning(-20);
	}

	dl {
		dt {
			position:			relative;
			padding-left:	20px;
			cursor:				pointer;
			padding-top:	@gutter;

			#font > #futura > .medium(18px,18px);

			&:before {
				content: '▻';
				font-family: "SSGizmo";
				font-style: normal;
				font-weight: normal;
				text-decoration: none;
				text-rendering: optimizeLegibility;
				white-space: nowrap;
				-webkit-font-feature-settings: "liga";
				-moz-font-feature-settings: "liga=1";
				-moz-font-feature-settings: "liga";
				-ms-font-feature-settings: "liga" 1;
				-o-font-feature-settings: "liga";
				font-feature-settings: "liga";
				-webkit-font-smoothing: antialiased;
				font-size: 16px;
				position: absolute;
				top: (@gutter + 2px);
				left: 0;
				color: @greenLt;

				.transition('all 0.5s cubic-bezier(0.190, 1.000, 0.220, 1.000), left 0.25s ease, top 0.25s ease');
				.transform-origin(center,center);
				.transform('rotate(0deg)');
			}

			&.open {
				&:before {
					left:	-2px;
					top:	@gutter;

					.transform('rotate(90deg)');
				}
			}
		}

		dd {
			padding-left:	20px;
			display:		none;
			overflow:		hidden;
			padding-top:	(@gutter / 2);

			#font > #futura > .book(16px,24px);
			.kerning(-20);
		}
	}
}

#faq-subnav {
	width: 100%;
	border-bottom: solid 1px @greyD7;
	padding: 20px 0;

	ul {
		max-width: @maxWidth;
		margin: 0 auto;

		li {
			display: inline-block;
			width: ceil(@maxWidth / 5);
			float: left;
			text-align: center;

			&:last-child {
				width: (@maxWidth - (ceil(@maxWidth / 5) * 4));
			}

			a {
				#font > #futura > .book(12px,12px);
				.kerning(100);
				text-transform: uppercase;
				color: @grey63;
				text-decoration: none;
				.transition('color 0.25s ease');
				&:hover {
					color: @greenLt;
				}
			}
		}
	}
}

#faq-question {
	text-align: 	center;
	color:				@grey3F;
	padding-top:	50px;
	margin-top: 	50px;
	border-top: 	solid 1px @greyD7;

	#font > #futura > .book(16px,24px);
	.kerning(-25);

	h4 {
		#font > #playfair > .regular(30px,25px);
		letter-spacing: normal;
		margin-bottom: 25px;
	}

	a {
		color: @greenLt;
		text-decoration: none;
		.transition('color 0.25s ease');
		&:hover {
			color: @greenDk;
		}
	}
}

@media screen and (min-width: @phoneWidthP) and (max-width: @phoneWidthL) {
	#faq {
		h2 {
			#font > #futura > .medium(18px, 21px);
		}

		> section {
			padding: 10px;
			margin:  30px 0;
		}

		dl {
			dt {
				#font > #futura > .medium(14px, 13px);
			}

			dd {
				#font > #futura > .book(13px, 21px);
			}
		}
	}

	#faq-subnav {
		padding:	0;
		height:		47px;
		overflow:	hidden;
		position:	relative;

		&:after{
			position:	absolute;
			content:	"";
			width:		11px;
			height:		7px;
			left:		93%;
			z-index:	5;
			top:		20px;

			.background-size(11px, 7px);
			.background-img("global/navigatedown.png", no-repeat, 95%, center);
		}

		&.active {
			height: 100%;
		}

		ul li {
			width:		100%;
			float:		none;
			display:	block;
			background:	#f9f5ee;

			a {
				width:				100%;
				padding:			15px 15px 15px 25px;
				color:				#a69782;
				text-align:			left;
				display:			block;
				border-top:			1px solid #e5dac6;
				-moz-box-shadow:	0 0 0 1px #fff;
				-webkit-box-shadow:	0 0 0 1px #fff;
				box-shadow:			0 0 0 1px #fff;

				#font#playfair.regular(12px);

				&:after { display: none; }

				&:before { display: none; }

				&.active { display: block; }
			}

			&:last-child {
				width: 100%;
			}
		}
	}

	#faq-question h4 {
		font-size: 24px;
	}

	p { font-size: 13px; }
}

@media screen and (min-width: @tabletWidthP) and (max-width: @tabletWidthL) {
	#faq {
		h2 {
			#font > #futura > .medium(22px, 21px);
		}

		> section {
			padding: 20px;
			margin:  30px 0;
		}

		dl {
			dt {
				#font > #futura > .medium(17px, 16px);
			}

			dd {
				#font > #futura > .book(15px, 21px);
			}
		}
	}

	#faq-subnav ul li {
		width:		auto;
		padding:	0 20px;

		&:last-child {
			width: auto;
		}
	}
}
