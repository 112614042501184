html{
	.sustainability-page {

		.slide-text-style {

			.content-wrapper{
				position: absolute;
			    left: 0;
			    right: 0;
			    top: 0;
			    bottom: 0;
			    h1{
				    color: #093e2c;
				    width: 50%;
			    }	
			    .description-slide {
				    max-width: 600px;
				    width: 100%;
				    line-height: 1.2;
				    color: #fff;
				    background: #005847;
				    padding: 25px 20px;
				    font-size: 18px;
				    position: absolute;
				    bottom: 30px;
				    right: 0;
				    box-shadow: 4px 2px 10px grey;
				}
			}	
		}

		#cms-page {

			.environment-block {
				background: @green;

				li {
					padding: 	0px;
					width: 		33.33%;
					height: initial;
					img{
						max-width: 100%;
						display: block;
						height: auto;
					}
				}

			}

			.environment-list {
				display: 	inline-block;
				width: 		100%;

				li {
					float: 			left;
					width: 			33.33%;
					text-align: 	center;
					margin-left: 	0;
					height: initial !important;
					.box-sizing(border-box);
					img{
						display: block;
						max-width: 100%;
						height: 285px;
					}
				}

				img {
					width: 100%;
				}

				span {
					padding: 	16px 0 0 0;
					display: 	block;
					width: 		76%;
					margin: 	0 auto;

					&.bigger-text {
						padding: 0;
						margin: 0;
						display: inline-block;
						width: auto;
						font: normal normal 400 31px/normal @playFairStack;
						//#font > #playfair > .heavy(31px,normal);
					}
				}

			}
			
			ul{
				margin-bottom: 0;
			}
			.block-bottom{

				background-color: rgb(0, 88, 71);

				ul{
					li{
						&:last-child{
							width: 66.67%;
						}
					}
				}
			}
		}
	}
}
