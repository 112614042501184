@media screen and (max-width: @tabletWidthL) {
	
	.cms-people-and-career{
		#cms-page > section {
    		width: 100%;
    		padding: 0;
		}
		.block-commom ul.text-img li img {
		    max-height: 315px;
    		height: 100%;
    		width: 100%;
		}

		.slide-text-style .content-wrapper h1 {
    		width: 100%;
		}
	}
}

@media screen and (max-width: @tabletContainerWidthG) {

	.cms-people-and-career .block-commom ul.text-img li img {
	    max-height: 245px;
	}

	ul.text-line, .block-description-text{
    	padding: 0 10px;
	}

}

@media screen and (max-width: @iphoneWidthMG) {

	.cms-people-and-career .block-commom ul.text-img li img {
	    max-height: 205px;
	}

	.cms-people-and-career .slide-text-style .content-wrapper h1 {
    	width: 100%;
	}

	.cms-people-and-career .block-commom ul.text-img li {
	    font-size: 60px;
	    line-height: 60px;
	}

}

@media screen and (max-width: @iphoneWidthMG5) {
	
	.cms-people-and-career .block-commom ul li {
    	width: 100%;
	}

	.cms-people-and-career .block-commom ul.text-img li {
    	font-size: 23px;
	}

	.cms-people-and-career {
		.slide-text-style{
			.content-wrapper{
				position: initial;
    			padding-top: 0;
    			.description-slide{
   					box-shadow: none;
   					position: initial;
    			}
			}	
		}
	}

	.cms-people-and-career .slide-text-style .content-wrapper h1 {
	    font-size: 25px !important;
	    width: 100%;
	    position: absolute;
	    top: 10%;
	    text-align: center;
	    padding-left: 0 !important;
	}

	.cms-people-and-career .block-commom ul li span {
    	padding: 10px 40px;
	}

	.cms-people-and-career .slide-text-style .content-wrapper .description-slide {
    	max-width: 568px;
	}

	.cms-people-and-career .block-commom ul.text-img li img {
    	max-height: 100%;
	}

}

@media screen and (max-width: @phoneWidthP) {

	.cms-people-and-career .block-commom ul li span {
    	padding: 10px 15px;
	}

	.cms-people-and-career .slide-text-style .content-wrapper .description-slide {
    	padding: 10px 20px;
	}

}










